import { Component } from "react";
import { MEDIA_BASE, UserData } from "../helpers/Constants";
import GetPath from "../helpers/GetPath";
import themer, { Themes } from "../helpers/Theme";
import Session from "../helpers/UserSession";

interface UserMenuState {
    user: UserData | null;
    open: boolean
}

export default class UserMenu extends Component<any, UserMenuState> {

    constructor(opts: any) {
        super(opts);
        this.state = {
            user: null,
            open: false
        };
    }

    async componentDidMount() {
        document.body.addEventListener('click', this.close);

        await Session.logged_in()
        this.setState({ user: Session.user });
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.close);
    }

    close = (e: any) => {

        let from_menu = GetPath(e.target).find(e => e.className === "user_menu open");
        if (from_menu) return;

        this.setState({ open: false });

    }

    themes = () => {
        return (
            <div className="theme_switcher_container">
                {
                    Themes.map(theme => {
                        return <button key={theme} className={`theme_switcher ${theme}`} onClick={() => themer.theme = theme}></button>
                    })
                }
            </div>
        )
    }

    menu = () => {

        if (this.state.user === null) return (
            <>
                <a href="/login"><i className="fas fa-sign-in-alt"></i> Login</a>
                {this.themes()}
            </>
        )

        return (
            <>
                <div className="user">
                    <h2>{this.state.user.display_name}</h2>
                    <h3>@{this.state.user.username}</h3>
                </div>
                <a href={`/user/${this.state.user.username}`}><i className="far fa-user"></i> Profile</a>
                <a href="/logout"><i className="fas fa-sign-out-alt"></i> Logout</a>
                {this.themes()}
            </>
        )

    }

    render() {

        return (

            <div className={`user_menu ${this.state.open ? 'open' : ''}`}>

                <img src={MEDIA_BASE + '/' + (this.state.user?.meta.avatar || 'defaults/profile.png')} alt="User Menu" className="icon" onClick={() => this.setState({ open: !this.state.open })} />
                <div className="menu">
                    {this.menu()}
                </div>

            </div>

        )

    }

}
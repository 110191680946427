const emoji_list = [
    {
        "id": "👩‍👩‍👧‍👧",
        "name": ":woman_woman_girl_girl:"
    },
    {
        "id": "👩‍👩‍👧‍👦",
        "name": ":woman_woman_girl_boy:"
    },
    {
        "id": "👩‍👩‍👦‍👦",
        "name": ":woman_woman_boy_boy:"
    },
    {
        "id": "👨‍👩‍👧‍👧",
        "name": ":man_woman_girl_girl:"
    },
    {
        "id": "👨‍👩‍👧‍👦",
        "name": ":man_woman_girl_boy:"
    },
    {
        "id": "👨‍👩‍👦‍👦",
        "name": ":man_woman_boy_boy:"
    },
    {
        "id": "👨‍👨‍👧‍👧",
        "name": ":man_man_girl_girl:"
    },
    {
        "id": "👨‍👨‍👧‍👦",
        "name": ":man_man_girl_boy:"
    },
    {
        "id": "👨‍👨‍👦‍👦",
        "name": ":man_man_boy_boy:"
    },
    {
        "id": "👩‍👩‍👧",
        "name": ":woman_woman_girl:"
    },
    {
        "id": "👩‍👩‍👦",
        "name": ":woman_woman_boy:"
    },
    {
        "id": "👩‍👧‍👧",
        "name": ":woman_girl_girl:"
    },
    {
        "id": "👩‍👧‍👦",
        "name": ":woman_girl_boy:"
    },
    {
        "id": "👩‍👦‍👦",
        "name": ":woman_boy_boy:"
    },
    {
        "id": "👨‍👩‍👧",
        "name": ":man_woman_girl:"
    },
    {
        "id": "👨‍👨‍👧",
        "name": ":man_man_girl:"
    },
    {
        "id": "👨‍👨‍👦",
        "name": ":man_man_boy:"
    },
    {
        "id": "👨‍👧‍👧",
        "name": ":man_girl_girl:"
    },
    {
        "id": "👨‍👧‍👦",
        "name": ":man_girl_boy:"
    },
    {
        "id": "👨‍👦‍👦",
        "name": ":man_boy_boy:"
    },
    {
        "id": "👩‍👧",
        "name": ":woman_girl:"
    },
    {
        "id": "👩‍👦",
        "name": ":woman_boy:"
    },
    {
        "id": "👨‍👧",
        "name": ":man_girl:"
    },
    {
        "id": "👨‍👦",
        "name": ":man_boy:"
    },
    {
        "id": "😂",
        "name": ":joy:"
    },
    {
        "id": "❤️",
        "name": ":heart:"
    },
    {
        "id": "♥️",
        "name": ":heart_suit:"
    },
    {
        "id": "😍",
        "name": ":heart_eyes:"
    },
    {
        "id": "😭",
        "name": ":sob:"
    },
    {
        "id": "😊",
        "name": ":blush:"
    },
    {
        "id": "😒",
        "name": ":unamused:"
    },
    {
        "id": "😘",
        "name": ":kissing_heart:"
    },
    {
        "id": "💕",
        "name": ":two_hearts:"
    },
    {
        "id": "☺️",
        "name": ":smiling:"
    },
    {
        "id": "😩",
        "name": ":weary:"
    },
    {
        "id": "👌🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👌🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👌🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👌🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👌🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👌",
        "name": ":ok_hand:"
    },
    {
        "id": "😔",
        "name": ":pensive:"
    },
    {
        "id": "😏",
        "name": ":smirk:"
    },
    {
        "id": "😁",
        "name": ":grin:"
    },
    {
        "id": "♻",
        "name": ":recycle:"
    },
    {
        "id": "😉",
        "name": ":wink:"
    },
    {
        "id": "👍🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👍🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👍🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👍🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👍🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👍",
        "name": ":thumbsup:"
    },
    {
        "id": "🙏🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙏🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙏🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙏🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙏🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙏",
        "name": ":pray:"
    },
    {
        "id": "😌",
        "name": ":relieved:"
    },
    {
        "id": "🎶",
        "name": ":notes:"
    },
    {
        "id": "😳",
        "name": ":flushed:"
    },
    {
        "id": "🙌🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙌🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙌🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙌🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙌🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙌",
        "name": ":raised_hands:"
    },
    {
        "id": "🙈",
        "name": ":see_no_evil:"
    },
    {
        "id": "😢",
        "name": ":cry:"
    },
    {
        "id": "😎",
        "name": ":sunglasses:"
    },
    {
        "id": "✌🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "✌🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "✌🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "✌🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "✌🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "✌️",
        "name": ":v:"
    },
    {
        "id": "👀",
        "name": ":eyes:"
    },
    {
        "id": "😅",
        "name": ":sweat_smile:"
    },
    {
        "id": "✨",
        "name": ":sparkles:"
    },
    {
        "id": "😴",
        "name": ":sleeping:"
    },
    {
        "id": "😄",
        "name": ":smile:"
    },
    {
        "id": "💜",
        "name": ":purple_heart:"
    },
    {
        "id": "💔",
        "name": ":broken_heart:"
    },
    {
        "id": "💯",
        "name": ":hundred_points:"
    },
    {
        "id": "😑",
        "name": ":expressionless:"
    },
    {
        "id": "💖",
        "name": ":sparkling_heart:"
    },
    {
        "id": "💙",
        "name": ":blue_heart:"
    },
    {
        "id": "😕",
        "name": ":confused:"
    },
    {
        "id": "💁🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💁🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💁🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💁🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💁🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💁‍♂",
        "name": ":man_tipping_hand:"
    },
    {
        "id": "💁🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💁🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💁🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💁🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💁🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💁",
        "name": ":information_desk_person:"
    },
    {
        "id": "😜",
        "name": ":stuck_out_tongue_winking_eye:"
    },
    {
        "id": "😞",
        "name": ":disappointed:"
    },
    {
        "id": "😋",
        "name": ":yum:"
    },
    {
        "id": "😐",
        "name": ":neutral_face:"
    },
    {
        "id": "😪",
        "name": ":sleepy:"
    },
    {
        "id": "👏🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👏🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👏🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👏🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👏🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👏",
        "name": ":clap:"
    },
    {
        "id": "💘",
        "name": ":cupid:"
    },
    {
        "id": "💗",
        "name": ":heartpulse:"
    },
    {
        "id": "💞",
        "name": ":revolving_hearts:"
    },
    {
        "id": "⬅️",
        "name": ":arrow_left:"
    },
    {
        "id": "🙊",
        "name": ":speak_no_evil:"
    },
    {
        "id": "✋🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "✋🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "✋🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "✋🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "✋🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "✋",
        "name": ":raised_hand:"
    },
    {
        "id": "💋",
        "name": ":kiss:"
    },
    {
        "id": "👉🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👉🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👉🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👉🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👉🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👉",
        "name": ":point_right:"
    },
    {
        "id": "🌸",
        "name": ":cherry_blossom:"
    },
    {
        "id": "😱",
        "name": ":scream:"
    },
    {
        "id": "🔥",
        "name": ":fire:"
    },
    {
        "id": "😡",
        "name": ":rage:"
    },
    {
        "id": "😃",
        "name": ":smiley:"
    },
    {
        "id": "🎉",
        "name": ":tada:"
    },
    {
        "id": "👊🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👊🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👊🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👊🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👊🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👊",
        "name": ":oncoming_fist:"
    },
    {
        "id": "😫",
        "name": ":tired_face:"
    },
    {
        "id": "📷",
        "name": ":camera:"
    },
    {
        "id": "🌹",
        "name": ":rose:"
    },
    {
        "id": "😝",
        "name": ":stuck_out_tongue_closed_eyes:"
    },
    {
        "id": "💪🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💪🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💪🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💪🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💪🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💪",
        "name": ":muscle:"
    },
    {
        "id": "💀",
        "name": ":skull:"
    },
    {
        "id": "☀️",
        "name": ":sunny:"
    },
    {
        "id": "💛",
        "name": ":yellow_heart:"
    },
    {
        "id": "😤",
        "name": ":triumph:"
    },
    {
        "id": "🌚",
        "name": ":new_moon_with_face:"
    },
    {
        "id": "😆",
        "name": ":laughing:"
    },
    {
        "id": "😓",
        "name": ":sweat:"
    },
    {
        "id": "👈🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👈🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👈🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👈🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👈🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👈",
        "name": ":point_left:"
    },
    {
        "id": "✔️",
        "name": ":heavy_check_mark:"
    },
    {
        "id": "😻",
        "name": ":heart_eyes_cat:"
    },
    {
        "id": "😀",
        "name": ":grinning:"
    },
    {
        "id": "😷",
        "name": ":mask:"
    },
    {
        "id": "💚",
        "name": ":green_heart:"
    },
    {
        "id": "👋🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👋🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👋🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👋🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👋🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👋",
        "name": ":wave:"
    },
    {
        "id": "😣",
        "name": ":persevere:"
    },
    {
        "id": "💓",
        "name": ":heartbeat:"
    },
    {
        "id": "▶️",
        "name": ":arrow_forward:"
    },
    {
        "id": "◀️",
        "name": ":arrow_backward:"
    },
    {
        "id": "↪️",
        "name": ":arrow_right_hook:"
    },
    {
        "id": "↩️",
        "name": ":leftwards_arrow_with_hook:"
    },
    {
        "id": "👑",
        "name": ":crown:"
    },
    {
        "id": "😚",
        "name": ":kissing_closed_eyes:"
    },
    {
        "id": "😛",
        "name": ":stuck_out_tongue:"
    },
    {
        "id": "😥",
        "name": ":disappointed_relieved:"
    },
    {
        "id": "😇",
        "name": ":innocent:"
    },
    {
        "id": "🎧",
        "name": ":headphones:"
    },
    {
        "id": "✅",
        "name": ":white_check_mark:"
    },
    {
        "id": "😖",
        "name": ":confounded:"
    },
    {
        "id": "➡",
        "name": ":arrow_right:"
    },
    {
        "id": "😠",
        "name": ":angry:"
    },
    {
        "id": "😬",
        "name": ":grimacing:"
    },
    {
        "id": "🌟",
        "name": ":star2:"
    },
    {
        "id": "🔫",
        "name": ":gun:"
    },
    {
        "id": "🙋🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙋🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙋🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙋🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙋🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙋‍♂",
        "name": ":man_raising_hand:"
    },
    {
        "id": "🙋🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙋🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙋🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙋🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙋🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙋",
        "name": ":raising_hand:"
    },
    {
        "id": "👎🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👎🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👎🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👎🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👎🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👎",
        "name": ":thumbsdown:"
    },
    {
        "id": "💃🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💃🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💃🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💃🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💃🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💃",
        "name": ":dancer:"
    },
    {
        "id": "🎵",
        "name": ":musical_note:"
    },
    {
        "id": "😶",
        "name": ":no_mouth:"
    },
    {
        "id": "💫",
        "name": ":dizzy:"
    },
    {
        "id": "✊🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "✊🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "✊🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "✊🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "✊🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "✊",
        "name": ":fist:"
    },
    {
        "id": "👇🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👇🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👇🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👇🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👇🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👇",
        "name": ":point_down:"
    },
    {
        "id": "🔴",
        "name": ":red_circle:"
    },
    {
        "id": "🙅🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙅🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙅🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙅🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙅🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙅‍♂",
        "name": ":man_gesturing_NO:"
    },
    {
        "id": "🙅🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙅🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙅🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙅🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙅🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙅",
        "name": ":no_good:"
    },
    {
        "id": "💥",
        "name": ":boom:"
    },
    {
        "id": "©️",
        "name": ":copyright:"
    },
    {
        "id": "💭",
        "name": ":thought_balloon:"
    },
    {
        "id": "👅",
        "name": ":tongue:"
    },
    {
        "id": "💩",
        "name": ":poop:"
    },
    {
        "id": "😰",
        "name": ":cold_sweat:"
    },
    {
        "id": "💎",
        "name": ":gem:"
    },
    {
        "id": "🙆🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙆🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙆🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙆🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙆🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙆‍♂",
        "name": ":man_gesturing_OK:"
    },
    {
        "id": "🙆🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙆🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙆🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙆🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙆🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙆",
        "name": ":ok_woman:"
    },
    {
        "id": "🍕",
        "name": ":pizza:"
    },
    {
        "id": "😹",
        "name": ":joy_cat:"
    },
    {
        "id": "🌞",
        "name": ":sun_with_face:"
    },
    {
        "id": "🍃",
        "name": ":leaves:"
    },
    {
        "id": "💦",
        "name": ":sweat_drops:"
    },
    {
        "id": "🐧",
        "name": ":penguin:"
    },
    {
        "id": "💤",
        "name": ":zzz:"
    },
    {
        "id": "🚶🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚶🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚶🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚶🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚶🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚶‍♀",
        "name": ":woman_walking:"
    },
    {
        "id": "🚶🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚶🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚶🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚶🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚶🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚶",
        "name": ":walking:"
    },
    {
        "id": "✈️",
        "name": ":airplane:"
    },
    {
        "id": "🎈",
        "name": ":balloon:"
    },
    {
        "id": "⭐",
        "name": ":star:"
    },
    {
        "id": "🎀",
        "name": ":ribbon:"
    },
    {
        "id": "☑️",
        "name": ":ballot_box_with_check:"
    },
    {
        "id": "😟",
        "name": ":worried:"
    },
    {
        "id": "🔞",
        "name": ":underage:"
    },
    {
        "id": "😨",
        "name": ":fearful:"
    },
    {
        "id": "🍀",
        "name": ":four_leaf_clover:"
    },
    {
        "id": "🌺",
        "name": ":hibiscus:"
    },
    {
        "id": "🎤",
        "name": ":microphone:"
    },
    {
        "id": "👐🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👐🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👐🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👐🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👐🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👐",
        "name": ":open_hands:"
    },
    {
        "id": "👻",
        "name": ":ghost:"
    },
    {
        "id": "🌴",
        "name": ":palm_tree:"
    },
    {
        "id": "‼️",
        "name": ":bangbang:"
    },
    {
        "id": "💅🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💅🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💅🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💅🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💅🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💅",
        "name": ":nail_care:"
    },
    {
        "id": "❌",
        "name": ":x:"
    },
    {
        "id": "👽",
        "name": ":alien:"
    },
    {
        "id": "🙇🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙇🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙇🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙇🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙇🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙇‍♀",
        "name": ":woman_bowing:"
    },
    {
        "id": "🙇🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙇🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙇🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙇🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙇🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙇",
        "name": ":bow:"
    },
    {
        "id": "☁",
        "name": ":cloud:"
    },
    {
        "id": "⚽",
        "name": ":soccer:"
    },
    {
        "id": "👼🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👼🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👼🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👼🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👼🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👼",
        "name": ":angel:"
    },
    {
        "id": "👯‍♂",
        "name": ":men_with_bunny_ears:"
    },
    {
        "id": "👯",
        "name": ":dancers:"
    },
    {
        "id": "❗",
        "name": ":exclamation:"
    },
    {
        "id": "❄️",
        "name": ":snowflake:"
    },
    {
        "id": "☝🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "☝🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "☝🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "☝🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "☝🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "☝️",
        "name": ":point_up:"
    },
    {
        "id": "😙",
        "name": ":kissing_smiling_eyes:"
    },
    {
        "id": "🌈",
        "name": ":rainbow:"
    },
    {
        "id": "🌙",
        "name": ":crescent_moon:"
    },
    {
        "id": "💟",
        "name": ":heart_decoration:"
    },
    {
        "id": "💝",
        "name": ":gift_heart:"
    },
    {
        "id": "🎁",
        "name": ":gift:"
    },
    {
        "id": "🍻",
        "name": ":beers:"
    },
    {
        "id": "😧",
        "name": ":anguished:"
    },
    {
        "id": "🌍",
        "name": ":earth_africa:"
    },
    {
        "id": "🎥",
        "name": ":movie_camera:"
    },
    {
        "id": "⚓",
        "name": ":anchor:"
    },
    {
        "id": "⚡",
        "name": ":zap:"
    },
    {
        "id": "♣️",
        "name": ":club_suit:"
    },
    {
        "id": "✖️",
        "name": ":heavy_multiplication_x:"
    },
    {
        "id": "🏃🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏃🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏃🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏃🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏃🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏃‍♀",
        "name": ":woman_running:"
    },
    {
        "id": "🏃🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏃🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏃🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏃🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏃🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏃",
        "name": ":runner:"
    },
    {
        "id": "🌻",
        "name": ":sunflower:"
    },
    {
        "id": "🌎",
        "name": ":earth_americas:"
    },
    {
        "id": "💐",
        "name": ":bouquet:"
    },
    {
        "id": "🐶",
        "name": ":dog:"
    },
    {
        "id": "💰",
        "name": ":moneybag:"
    },
    {
        "id": "🌿",
        "name": ":herb:"
    },
    {
        "id": "👫",
        "name": ":couple:"
    },
    {
        "id": "🍂",
        "name": ":fallen_leaf:"
    },
    {
        "id": "🌷",
        "name": ":tulip:"
    },
    {
        "id": "🎂",
        "name": ":birthday:"
    },
    {
        "id": "🐱",
        "name": ":cat:"
    },
    {
        "id": "☕",
        "name": ":coffee:"
    },
    {
        "id": "😵",
        "name": ":dizzy_face:"
    },
    {
        "id": "👆🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👆🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👆🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👆🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👆🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👆",
        "name": ":point_up_2:"
    },
    {
        "id": "😮",
        "name": ":open_mouth:"
    },
    {
        "id": "😯",
        "name": ":hushed:"
    },
    {
        "id": "🏀",
        "name": ":basketball:"
    },
    {
        "id": "🎄",
        "name": ":christmas_tree:"
    },
    {
        "id": "💍",
        "name": ":ring:"
    },
    {
        "id": "🌝",
        "name": ":full_moon_with_face:"
    },
    {
        "id": "😲",
        "name": ":astonished:"
    },
    {
        "id": "👭",
        "name": ":two_women_holding_hands:"
    },
    {
        "id": "💸",
        "name": ":money_with_wings:"
    },
    {
        "id": "😿",
        "name": ":crying_cat_face:"
    },
    {
        "id": "🙉",
        "name": ":hear_no_evil:"
    },
    {
        "id": "💨",
        "name": ":dash:"
    },
    {
        "id": "🌵",
        "name": ":cactus:"
    },
    {
        "id": "♨️",
        "name": ":hotsprings:"
    },
    {
        "id": "☎️",
        "name": ":telephone:"
    },
    {
        "id": "🍁",
        "name": ":maple_leaf:"
    },
    {
        "id": "👸🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👸🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👸🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👸🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👸🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👸",
        "name": ":princess:"
    },
    {
        "id": "💆🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💆🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💆🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💆🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💆🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💆‍♂",
        "name": ":man_getting_massage:"
    },
    {
        "id": "💆🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💆🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💆🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💆🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💆🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💆",
        "name": ":massage:"
    },
    {
        "id": "💌",
        "name": ":love_letter:"
    },
    {
        "id": "🏆",
        "name": ":trophy:"
    },
    {
        "id": "🙍🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙍🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙍🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙍🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙍🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙍‍♂",
        "name": ":man_frowning:"
    },
    {
        "id": "🙍🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙍🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙍🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙍🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙍🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙍",
        "name": ":person_frowning:"
    },
    {
        "id": "🇺🇸",
        "name": ":us:"
    },
    {
        "id": "🎊",
        "name": ":confetti_ball:"
    },
    {
        "id": "🌼",
        "name": ":blossom:"
    },
    {
        "id": "🔪",
        "name": ":kitchen_knife:"
    },
    {
        "id": "👄",
        "name": ":lips:"
    },
    {
        "id": "🍟",
        "name": ":fries:"
    },
    {
        "id": "🍩",
        "name": ":doughnut:"
    },
    {
        "id": "😦",
        "name": ":frowning:"
    },
    {
        "id": "🌊",
        "name": ":ocean:"
    },
    {
        "id": "💣",
        "name": ":bomb:"
    },
    {
        "id": "🆗",
        "name": ":ok:"
    },
    {
        "id": "🌀",
        "name": ":cyclone:"
    },
    {
        "id": "🚀",
        "name": ":rocket:"
    },
    {
        "id": "☔",
        "name": ":umbrella:"
    },
    {
        "id": "💏",
        "name": ":couplekiss:"
    },
    {
        "id": "👩‍❤️‍💋‍👩",
        "name": ":couple_woman_kiss:"
    },
    {
        "id": "👨‍❤️‍💋‍👨",
        "name": ":couple_man_kiss:"
    },
    {
        "id": "💑",
        "name": ":couple_with_heart:"
    },
    {
        "id": "👩‍❤️‍👩",
        "name": ":woman_woman:"
    },
    {
        "id": "👨‍❤️‍👨",
        "name": ":man_man_love:"
    },
    {
        "id": "🍭",
        "name": ":lollipop:"
    },
    {
        "id": "🎬",
        "name": ":clapper:"
    },
    {
        "id": "🐷",
        "name": ":pig:"
    },
    {
        "id": "😈",
        "name": ":smiling_imp:"
    },
    {
        "id": "👿",
        "name": ":imp:"
    },
    {
        "id": "🐝",
        "name": ":bee:"
    },
    {
        "id": "😽",
        "name": ":kissing_cat:"
    },
    {
        "id": "💢",
        "name": ":anger:"
    },
    {
        "id": "🎼",
        "name": ":musical_score:"
    },
    {
        "id": "🎅🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🎅🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🎅🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🎅🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🎅🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🎅",
        "name": ":santa:"
    },
    {
        "id": "🌏",
        "name": ":earth_asia:"
    },
    {
        "id": "🏈",
        "name": ":football:"
    },
    {
        "id": "🎸",
        "name": ":guitar:"
    },
    {
        "id": "♦️",
        "name": ":diamond_suit:"
    },
    {
        "id": "🐼",
        "name": ":panda_face:"
    },
    {
        "id": "💬",
        "name": ":speech_balloon:"
    },
    {
        "id": "🍓",
        "name": ":strawberry:"
    },
    {
        "id": "😼",
        "name": ":smirk_cat:"
    },
    {
        "id": "🍌",
        "name": ":banana:"
    },
    {
        "id": "🍉",
        "name": ":watermelon:"
    },
    {
        "id": "⛄",
        "name": ":snowman:"
    },
    {
        "id": "😸",
        "name": ":smile_cat:"
    },
    {
        "id": "♠️",
        "name": ":spade_suit:"
    },
    {
        "id": "🔝",
        "name": ":top:"
    },
    {
        "id": "🍆",
        "name": ":eggplant:"
    },
    {
        "id": "🔮",
        "name": ":crystal_ball:"
    },
    {
        "id": "🍴",
        "name": ":fork_and_knife:"
    },
    {
        "id": "📲",
        "name": ":calling:"
    },
    {
        "id": "📱",
        "name": ":iphone:"
    },
    {
        "id": "⛅",
        "name": ":partly_sunny:"
    },
    {
        "id": "⚠️",
        "name": ":warning:"
    },
    {
        "id": "🙀",
        "name": ":scream_cat:"
    },
    {
        "id": "🔸",
        "name": ":small_orange_diamond:"
    },
    {
        "id": "👶🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👶🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👶🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👶🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👶🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👶",
        "name": ":baby:"
    },
    {
        "id": "🐾",
        "name": ":feet:"
    },
    {
        "id": "👣",
        "name": ":footprints:"
    },
    {
        "id": "🍺",
        "name": ":beer:"
    },
    {
        "id": "🍷",
        "name": ":wine_glass:"
    },
    {
        "id": "⭕",
        "name": ":o:"
    },
    {
        "id": "📹",
        "name": ":video_camera:"
    },
    {
        "id": "🐰",
        "name": ":rabbit:"
    },
    {
        "id": "🍹",
        "name": ":tropical_drink:"
    },
    {
        "id": "🚬",
        "name": ":smoking:"
    },
    {
        "id": "👾",
        "name": ":space_invader:"
    },
    {
        "id": "🍑",
        "name": ":peach:"
    },
    {
        "id": "🐍",
        "name": ":snake:"
    },
    {
        "id": "🐢",
        "name": ":turtle:"
    },
    {
        "id": "🍒",
        "name": ":cherries:"
    },
    {
        "id": "😗",
        "name": ":kissing:"
    },
    {
        "id": "🐸",
        "name": ":frog:"
    },
    {
        "id": "🌌",
        "name": ":milky_way:"
    },
    {
        "id": "🚨",
        "name": ":rotating_light:"
    },
    {
        "id": "🐣",
        "name": ":hatching_chick:"
    },
    {
        "id": "📕",
        "name": ":closed_book:"
    },
    {
        "id": "🍬",
        "name": ":candy:"
    },
    {
        "id": "🍔",
        "name": ":hamburger:"
    },
    {
        "id": "🐻",
        "name": ":bear:"
    },
    {
        "id": "🐯",
        "name": ":tiger:"
    },
    {
        "id": "🚗",
        "name": ":automobile:"
    },
    {
        "id": "⏩",
        "name": ":fast_forward:"
    },
    {
        "id": "🍦",
        "name": ":icecream:"
    },
    {
        "id": "🍍",
        "name": ":pineapple:"
    },
    {
        "id": "🌾",
        "name": ":ear_of_rice:"
    },
    {
        "id": "💉",
        "name": ":syringe:"
    },
    {
        "id": "🚮",
        "name": ":put_litter_in_its_place:"
    },
    {
        "id": "🍫",
        "name": ":chocolate_bar:"
    },
    {
        "id": "▪️",
        "name": ":black_small_square:"
    },
    {
        "id": "📺",
        "name": ":tv:"
    },
    {
        "id": "💊",
        "name": ":pill:"
    },
    {
        "id": "🐙",
        "name": ":octopus:"
    },
    {
        "id": "🎃",
        "name": ":jack_o_lantern:"
    },
    {
        "id": "🍇",
        "name": ":grapes:"
    },
    {
        "id": "😺",
        "name": ":smiley_cat:"
    },
    {
        "id": "💿",
        "name": ":cd:"
    },
    {
        "id": "🍸",
        "name": ":cocktail:"
    },
    {
        "id": "🍰",
        "name": ":cake:"
    },
    {
        "id": "🎮",
        "name": ":video_game:"
    },
    {
        "id": "™️",
        "name": ":trade_mark:"
    },
    {
        "id": "⬇️",
        "name": ":arrow_down:"
    },
    {
        "id": "🚫",
        "name": ":no_entry_sign:"
    },
    {
        "id": "💄",
        "name": ":lipstick:"
    },
    {
        "id": "🐳",
        "name": ":whale:"
    },
    {
        "id": "📝",
        "name": ":memo:"
    },
    {
        "id": "®️",
        "name": ":registered:"
    },
    {
        "id": "🍪",
        "name": ":cookie:"
    },
    {
        "id": "🐬",
        "name": ":dolphin:"
    },
    {
        "id": "🔊",
        "name": ":loud_sound:"
    },
    {
        "id": "👨🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨",
        "name": ":man:"
    },
    {
        "id": "🐥",
        "name": ":hatched_chick:"
    },
    {
        "id": "🐒",
        "name": ":monkey:"
    },
    {
        "id": "📚",
        "name": ":books:"
    },
    {
        "id": "👹",
        "name": ":japanese_ogre:"
    },
    {
        "id": "💂🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💂🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💂🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💂🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💂🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💂‍♀",
        "name": ":woman_guard:"
    },
    {
        "id": "💂🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💂🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💂🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💂🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💂🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💂",
        "name": ":guardsman:"
    },
    {
        "id": "📢",
        "name": ":loudspeaker:"
    },
    {
        "id": "✂️",
        "name": ":scissors:"
    },
    {
        "id": "👧🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👧🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👧🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👧🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👧🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👧",
        "name": ":girl:"
    },
    {
        "id": "🎓",
        "name": ":mortar_board:"
    },
    {
        "id": "🇫🇷",
        "name": ":fr:"
    },
    {
        "id": "⚾️",
        "name": ":baseball:"
    },
    {
        "id": "🚦",
        "name": ":vertical_traffic_light:"
    },
    {
        "id": "👩🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩",
        "name": ":woman:"
    },
    {
        "id": "🎆",
        "name": ":fireworks:"
    },
    {
        "id": "🌠",
        "name": ":stars:"
    },
    {
        "id": "🆘",
        "name": ":sos:"
    },
    {
        "id": "🍄",
        "name": ":mushroom:"
    },
    {
        "id": "😾",
        "name": ":pouting_cat:"
    },
    {
        "id": "🛅",
        "name": ":left_luggage:"
    },
    {
        "id": "👠",
        "name": ":high_heel:"
    },
    {
        "id": "🎯",
        "name": ":dart:"
    },
    {
        "id": "🏊🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏊🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏊🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏊🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏊🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏊‍♀",
        "name": ":woman_swimming:"
    },
    {
        "id": "🏊🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏊🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏊🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏊🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏊🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏊",
        "name": ":swimmer:"
    },
    {
        "id": "🔑",
        "name": ":key:"
    },
    {
        "id": "👙",
        "name": ":bikini:"
    },
    {
        "id": "👪",
        "name": ":family:"
    },
    {
        "id": "✏",
        "name": ":pencil2:"
    },
    {
        "id": "🐘",
        "name": ":elephant:"
    },
    {
        "id": "💧",
        "name": ":droplet:"
    },
    {
        "id": "🌱",
        "name": ":seedling:"
    },
    {
        "id": "🍎",
        "name": ":apple:"
    },
    {
        "id": "🆒",
        "name": ":cool:"
    },
    {
        "id": "📞",
        "name": ":telephone_receiver:"
    },
    {
        "id": "💵",
        "name": ":dollar:"
    },
    {
        "id": "🏡",
        "name": ":house_with_garden:"
    },
    {
        "id": "📖",
        "name": ":book:"
    },
    {
        "id": "💇🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💇🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💇🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💇🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💇🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💇‍♂",
        "name": ":man_getting_haircut:"
    },
    {
        "id": "💇🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💇🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💇🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💇🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💇🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💇",
        "name": ":haircut:"
    },
    {
        "id": "💻",
        "name": ":computer:"
    },
    {
        "id": "💡",
        "name": ":bulb:"
    },
    {
        "id": "❓",
        "name": ":question:"
    },
    {
        "id": "🔙",
        "name": ":back:"
    },
    {
        "id": "👦🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👦🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👦🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👦🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👦🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👦",
        "name": ":boy:"
    },
    {
        "id": "🔐",
        "name": ":closed_lock_with_key:"
    },
    {
        "id": "🙎🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙎🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙎🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙎🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙎🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙎‍♂",
        "name": ":man_pouting:"
    },
    {
        "id": "🙎🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙎🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙎🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙎🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙎🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙎",
        "name": ":person_with_pouting_face:"
    },
    {
        "id": "🍊",
        "name": ":tangerine:"
    },
    {
        "id": "↔️",
        "name": ":leftright_arrow:"
    },
    {
        "id": "🌅",
        "name": ":sunrise:"
    },
    {
        "id": "🍗",
        "name": ":poultry_leg:"
    },
    {
        "id": "🔵",
        "name": ":blue_circle:"
    },
    {
        "id": "🚘",
        "name": ":oncoming_automobile:"
    },
    {
        "id": "🍧",
        "name": ":shaved_ice:"
    },
    {
        "id": "🇮🇹",
        "name": ":it:"
    },
    {
        "id": "🐦",
        "name": ":bird:"
    },
    {
        "id": "🇬🇧",
        "name": ":gb:"
    },
    {
        "id": "🌛",
        "name": ":first_quarter_moon_with_face:"
    },
    {
        "id": "👓",
        "name": ":eyeglasses:"
    },
    {
        "id": "🐐",
        "name": ":goat:"
    },
    {
        "id": "🌃",
        "name": ":night_with_stars:"
    },
    {
        "id": "👵🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👵🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👵🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👵🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👵🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👵",
        "name": ":older_woman:"
    },
    {
        "id": "⚫",
        "name": ":black_circle:"
    },
    {
        "id": "🌑",
        "name": ":new_moon:"
    },
    {
        "id": "👬",
        "name": ":two_men_holding_hands:"
    },
    {
        "id": "⚪",
        "name": ":white_circle:"
    },
    {
        "id": "🛃",
        "name": ":customs:"
    },
    {
        "id": "🐠",
        "name": ":tropical_fish:"
    },
    {
        "id": "🏠",
        "name": ":house:"
    },
    {
        "id": "🔃",
        "name": ":arrows_clockwise:"
    },
    {
        "id": "🌜",
        "name": ":last_quarter_moon_with_face:"
    },
    {
        "id": "📍",
        "name": ":round_pushpin:"
    },
    {
        "id": "🌕",
        "name": ":full_moon:"
    },
    {
        "id": "👟",
        "name": ":athletic_shoe:"
    },
    {
        "id": "🍋",
        "name": ":lemon:"
    },
    {
        "id": "🍼",
        "name": ":baby_bottle:"
    },
    {
        "id": "🎨",
        "name": ":artist_palette:"
    },
    {
        "id": "✉️",
        "name": ":envelope:"
    },
    {
        "id": "🍝",
        "name": ":spaghetti:"
    },
    {
        "id": "🎐",
        "name": ":wind_chime:"
    },
    {
        "id": "🍥",
        "name": ":fish_cake:"
    },
    {
        "id": "🌲",
        "name": ":evergreen_tree:"
    },
    {
        "id": "🆙",
        "name": ":up:"
    },
    {
        "id": "⬆️",
        "name": ":arrow_up:"
    },
    {
        "id": "↗️",
        "name": ":arrow_upper_right:"
    },
    {
        "id": "↘️",
        "name": ":arrow_lower_right:"
    },
    {
        "id": "↙️",
        "name": ":arrow_lower_left:"
    },
    {
        "id": "🎭",
        "name": ":performing_arts:"
    },
    {
        "id": "👃🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👃🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👃🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👃🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👃🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👃",
        "name": ":nose:"
    },
    {
        "id": "🐽",
        "name": ":pig_nose:"
    },
    {
        "id": "🐟",
        "name": ":fish:"
    },
    {
        "id": "👳🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👳🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👳🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👳🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👳🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👳‍♀",
        "name": ":woman_wearing_turban:"
    },
    {
        "id": "👳🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👳🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👳🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👳🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👳🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👳",
        "name": ":man_with_turban:"
    },
    {
        "id": "🐨",
        "name": ":koala:"
    },
    {
        "id": "👂🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👂🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👂🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👂🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👂🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👂",
        "name": ":ear:"
    },
    {
        "id": "✳️",
        "name": ":eight_spoked_asterisk:"
    },
    {
        "id": "🔹",
        "name": ":small_blue_diamond:"
    },
    {
        "id": "🚿",
        "name": ":shower:"
    },
    {
        "id": "🐛",
        "name": ":bug:"
    },
    {
        "id": "🍜",
        "name": ":ramen:"
    },
    {
        "id": "🎩",
        "name": ":tophat:"
    },
    {
        "id": "👰🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👰🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👰🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👰🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👰🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👰",
        "name": ":bride_with_veil:"
    },
    {
        "id": "⛽",
        "name": ":fuelpump:"
    },
    {
        "id": "🏁",
        "name": ":checkered_flag:"
    },
    {
        "id": "🐴",
        "name": ":horse:"
    },
    {
        "id": "⌚",
        "name": ":watch:"
    },
    {
        "id": "🐵",
        "name": ":monkey_face:"
    },
    {
        "id": "🚼",
        "name": ":baby_symbol:"
    },
    {
        "id": "🆕",
        "name": ":new:"
    },
    {
        "id": "🆓",
        "name": ":free:"
    },
    {
        "id": "🎇",
        "name": ":sparkler:"
    },
    {
        "id": "🌽",
        "name": ":corn:"
    },
    {
        "id": "🎾",
        "name": ":tennis:"
    },
    {
        "id": "⏰",
        "name": ":alarm_clock:"
    },
    {
        "id": "🔋",
        "name": ":battery:"
    },
    {
        "id": "❕",
        "name": ":grey_exclamation:"
    },
    {
        "id": "🐺",
        "name": ":wolf:"
    },
    {
        "id": "🗿",
        "name": ":moyai:"
    },
    {
        "id": "🐮",
        "name": ":cow:"
    },
    {
        "id": "📣",
        "name": ":mega:"
    },
    {
        "id": "👴🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👴🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👴🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👴🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👴🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👴",
        "name": ":older_man:"
    },
    {
        "id": "👗",
        "name": ":dress:"
    },
    {
        "id": "🔗",
        "name": ":link:"
    },
    {
        "id": "🐔",
        "name": ":chicken:"
    },
    {
        "id": "🍳",
        "name": ":cooking:"
    },
    {
        "id": "🐋",
        "name": ":whale2:"
    },
    {
        "id": "↖",
        "name": ":arrow_upper_left:"
    },
    {
        "id": "🌳",
        "name": ":deciduous_tree:"
    },
    {
        "id": "🍱",
        "name": ":bento:"
    },
    {
        "id": "📌",
        "name": ":pushpin:"
    },
    {
        "id": "🔜",
        "name": ":soon:"
    },
    {
        "id": "🔁",
        "name": ":repeat:"
    },
    {
        "id": "🐉",
        "name": ":dragon:"
    },
    {
        "id": "🐹",
        "name": ":hamster:"
    },
    {
        "id": "⛳",
        "name": ":golf:"
    },
    {
        "id": "🏄🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏄🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏄🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏄🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏄🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏄‍♀",
        "name": ":woman_surfing:"
    },
    {
        "id": "🏄🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏄🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏄🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏄🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏄🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏄",
        "name": ":surfer:"
    },
    {
        "id": "🐭",
        "name": ":mouse:"
    },
    {
        "id": "🌒",
        "name": ":waxing_crescent_moon:"
    },
    {
        "id": "🚙",
        "name": ":blue_car:"
    },
    {
        "id": "🅰️",
        "name": ":a:"
    },
    {
        "id": "⁉️",
        "name": ":interrobang:"
    },
    {
        "id": "🈹",
        "name": ":u5272:"
    },
    {
        "id": "🔌",
        "name": ":electric_plug:"
    },
    {
        "id": "🌓",
        "name": ":first_quarter_moon:"
    },
    {
        "id": "♋",
        "name": ":cancer:"
    },
    {
        "id": "🔱",
        "name": ":trident:"
    },
    {
        "id": "🍞",
        "name": ":bread:"
    },
    {
        "id": "👮🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👮🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👮🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👮🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👮🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👮‍♀",
        "name": ":woman_police_officer:"
    },
    {
        "id": "👮🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👮🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👮🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👮🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👮🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👮",
        "name": ":cop:"
    },
    {
        "id": "🍵",
        "name": ":tea:"
    },
    {
        "id": "🎣",
        "name": ":fishing_pole_and_fish:"
    },
    {
        "id": "🌔",
        "name": ":waxing_gibbous_moon:"
    },
    {
        "id": "🚲",
        "name": ":bike:"
    },
    {
        "id": "👤",
        "name": ":bust_in_silhouette:"
    },
    {
        "id": "🍚",
        "name": ":rice:"
    },
    {
        "id": "📻",
        "name": ":radio:"
    },
    {
        "id": "🐤",
        "name": ":baby_chick:"
    },
    {
        "id": "⤵️",
        "name": ":arrow_heading_down:"
    },
    {
        "id": "🌘",
        "name": ":waning_crescent_moon:"
    },
    {
        "id": "↕",
        "name": ":arrow_up_down:"
    },
    {
        "id": "🇪",
        "name": ""
    },
    {
        "id": "🌗",
        "name": ":last_quarter_moon:"
    },
    {
        "id": "🔘",
        "name": ":radio_button:"
    },
    {
        "id": "🐑",
        "name": ":sheep:"
    },
    {
        "id": "👱🏿‍♀",
        "name": ":dark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏾‍♀",
        "name": ":mediumdark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏽‍♀",
        "name": ":medium_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏼‍♀",
        "name": ":mediumlight_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏻‍♀",
        "name": ":light_skin_tone_blond_hair:"
    },
    {
        "id": "👱‍♀",
        "name": ":blond_hair:"
    },
    {
        "id": "👱🏿",
        "name": ":dark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏾",
        "name": ":mediumdark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏽",
        "name": ":medium_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏼",
        "name": ":mediumlight_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏻",
        "name": ":light_skin_tone_blond_hair:"
    },
    {
        "id": "👱",
        "name": ":person_with_blond_hair:"
    },
    {
        "id": "🌖",
        "name": ":waning_gibbous_moon:"
    },
    {
        "id": "🔒",
        "name": ":lock:"
    },
    {
        "id": "🍏",
        "name": ":green_apple:"
    },
    {
        "id": "👺",
        "name": ":japanese_goblin:"
    },
    {
        "id": "➰",
        "name": ":curly_loop:"
    },
    {
        "id": "🚩",
        "name": ":triangular_flag_on_post:"
    },
    {
        "id": "🔄",
        "name": ":arrows_counterclockwise:"
    },
    {
        "id": "🐎",
        "name": ":racehorse:"
    },
    {
        "id": "🍤",
        "name": ":fried_shrimp:"
    },
    {
        "id": "🌄",
        "name": ":sunrise_over_mountains:"
    },
    {
        "id": "🌋",
        "name": ":volcano:"
    },
    {
        "id": "🐓",
        "name": ":rooster:"
    },
    {
        "id": "📥",
        "name": ":inbox_tray:"
    },
    {
        "id": "💒",
        "name": ":wedding:"
    },
    {
        "id": "🍣",
        "name": ":sushi:"
    },
    {
        "id": "〰",
        "name": ":wavy_dash:"
    },
    {
        "id": "🍨",
        "name": ":ice_cream:"
    },
    {
        "id": "⏪",
        "name": ":rewind:"
    },
    {
        "id": "🍅",
        "name": ":tomato:"
    },
    {
        "id": "🐇",
        "name": ":rabbit2:"
    },
    {
        "id": "✴️",
        "name": ":eight_pointed_black_star:"
    },
    {
        "id": "🔺",
        "name": ":small_red_triangle:"
    },
    {
        "id": "🔆",
        "name": ":high_brightness:"
    },
    {
        "id": "➕",
        "name": ":heavy_plus_sign:"
    },
    {
        "id": "👲🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👲🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👲🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👲🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👲🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👲",
        "name": ":man_with_gua_pi_mao:"
    },
    {
        "id": "🏪",
        "name": ":convenience_store:"
    },
    {
        "id": "👥",
        "name": ":busts_in_silhouette:"
    },
    {
        "id": "🐞",
        "name": ":beetle:"
    },
    {
        "id": "🔻",
        "name": ":small_red_triangle_down:"
    },
    {
        "id": "🇩🇪",
        "name": ":ger:"
    },
    {
        "id": "⤴️",
        "name": ":arrow_heading_up:"
    },
    {
        "id": "📛",
        "name": ":name_badge:"
    },
    {
        "id": "🛀🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🛀🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🛀🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🛀🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🛀🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🛀",
        "name": ":bath:"
    },
    {
        "id": "⛔",
        "name": ":no_entry:"
    },
    {
        "id": "🐊",
        "name": ":crocodile:"
    },
    {
        "id": "🌰",
        "name": ":chestnut:"
    },
    {
        "id": "🐕",
        "name": ":dog2:"
    },
    {
        "id": "🐈",
        "name": ":cat2:"
    },
    {
        "id": "🔨",
        "name": ":hammer:"
    },
    {
        "id": "🍖",
        "name": ":meat_on_bone:"
    },
    {
        "id": "🐚",
        "name": ":shell:"
    },
    {
        "id": "❇️",
        "name": ":sparkle:"
    },
    {
        "id": "⛵",
        "name": ":sailboat:"
    },
    {
        "id": "🅱️",
        "name": ":b:"
    },
    {
        "id": "Ⓜ️",
        "name": ":m:"
    },
    {
        "id": "🐩",
        "name": ":poodle:"
    },
    {
        "id": "♒",
        "name": ":aquarius:"
    },
    {
        "id": "🍲",
        "name": ":stew:"
    },
    {
        "id": "👖",
        "name": ":jeans:"
    },
    {
        "id": "🍯",
        "name": ":honey_pot:"
    },
    {
        "id": "🎹",
        "name": ":musical_keyboard:"
    },
    {
        "id": "🔓",
        "name": ":unlock:"
    },
    {
        "id": "✒",
        "name": ":black_nib:"
    },
    {
        "id": "🗽",
        "name": ":statue_of_liberty:"
    },
    {
        "id": "💲",
        "name": ":heavy_dollar_sign:"
    },
    {
        "id": "🏂",
        "name": ":snowboarder:"
    },
    {
        "id": "💮",
        "name": ":white_flower:"
    },
    {
        "id": "👔",
        "name": ":necktie:"
    },
    {
        "id": "💠",
        "name": ":diamond_shape_with_a_dot_inside:"
    },
    {
        "id": "♈",
        "name": ":aries:"
    },
    {
        "id": "🚺",
        "name": ":womens:"
    },
    {
        "id": "🐜",
        "name": ":ant:"
    },
    {
        "id": "♏",
        "name": ":scorpius:"
    },
    {
        "id": "🌇",
        "name": ":city_sunset:"
    },
    {
        "id": "⏳",
        "name": ":hourglass_flowing_sand:"
    },
    {
        "id": "🅾️",
        "name": ":o2:"
    },
    {
        "id": "🐲",
        "name": ":dragon_face:"
    },
    {
        "id": "🐌",
        "name": ":snail:"
    },
    {
        "id": "📀",
        "name": ":dvd:"
    },
    {
        "id": "👕",
        "name": ":shirt:"
    },
    {
        "id": "🎲",
        "name": ":game_die:"
    },
    {
        "id": "➖",
        "name": ":heavy_minus_sign:"
    },
    {
        "id": "🎎",
        "name": ":dolls:"
    },
    {
        "id": "♐",
        "name": ":sagittarius:"
    },
    {
        "id": "🎱",
        "name": ":8ball:"
    },
    {
        "id": "🚌",
        "name": ":bus:"
    },
    {
        "id": "🍮",
        "name": ":custard:"
    },
    {
        "id": "🎌",
        "name": ":crossed_flags:"
    },
    {
        "id": "〽️",
        "name": ":part_alternation_mark:"
    },
    {
        "id": "🐫",
        "name": ":camel:"
    },
    {
        "id": "🍛",
        "name": ":curry:"
    },
    {
        "id": "🚂",
        "name": ":steam_locomotive:"
    },
    {
        "id": "🏥",
        "name": ":hospital:"
    },
    {
        "id": "🇯🇵",
        "name": ":jp:"
    },
    {
        "id": "🔷",
        "name": ":large_blue_diamond:"
    },
    {
        "id": "🎋",
        "name": ":tanabata_tree:"
    },
    {
        "id": "🔔",
        "name": ":bell:"
    },
    {
        "id": "♌",
        "name": ":leo:"
    },
    {
        "id": "♊",
        "name": ":gemini:"
    },
    {
        "id": "🍐",
        "name": ":pear:"
    },
    {
        "id": "🔶",
        "name": ":large_orange_diamond:"
    },
    {
        "id": "♉",
        "name": ":taurus:"
    },
    {
        "id": "🌐",
        "name": ":globe_with_meridians:"
    },
    {
        "id": "🚪",
        "name": ":door:"
    },
    {
        "id": "🕕",
        "name": ":clock6:"
    },
    {
        "id": "🚔",
        "name": ":oncoming_police_car:"
    },
    {
        "id": "📩",
        "name": ":envelope_with_arrow:"
    },
    {
        "id": "🌂",
        "name": ":closed_umbrella:"
    },
    {
        "id": "🎷",
        "name": ":saxophone:"
    },
    {
        "id": "⛪",
        "name": ":church:"
    },
    {
        "id": "🚴🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚴🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚴🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚴🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚴🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚴‍♀",
        "name": ":woman_biking:"
    },
    {
        "id": "🚴🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚴🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚴🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚴🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚴🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚴",
        "name": ":bicyclist:"
    },
    {
        "id": "♓",
        "name": ":pisces:"
    },
    {
        "id": "🍡",
        "name": ":dango:"
    },
    {
        "id": "♑",
        "name": ":capricorn:"
    },
    {
        "id": "🏢",
        "name": ":office:"
    },
    {
        "id": "🚣🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚣🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚣🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚣🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚣🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚣‍♀",
        "name": ":woman_rowing_boat:"
    },
    {
        "id": "🚣🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚣🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚣🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚣🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚣🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚣",
        "name": ":rowboat:"
    },
    {
        "id": "👒",
        "name": ":womans_hat:"
    },
    {
        "id": "👞",
        "name": ":mans_shoe:"
    },
    {
        "id": "🏩",
        "name": ":love_hotel:"
    },
    {
        "id": "🗻",
        "name": ":mount_fuji:"
    },
    {
        "id": "🐪",
        "name": ":dromedary_camel:"
    },
    {
        "id": "👜",
        "name": ":handbag:"
    },
    {
        "id": "⌛",
        "name": ":hourglass:"
    },
    {
        "id": "❎",
        "name": ":negative_squared_cross_mark:"
    },
    {
        "id": "🎺",
        "name": ":trumpet:"
    },
    {
        "id": "🏫",
        "name": ":school:"
    },
    {
        "id": "🐄",
        "name": ":cow2:"
    },
    {
        "id": "🌆",
        "name": ":cityscape_at_dusk:"
    },
    {
        "id": "👷🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👷🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👷🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👷🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👷🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👷‍♀",
        "name": ":woman_construction_worker:"
    },
    {
        "id": "👷🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👷🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👷🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👷🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👷🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👷",
        "name": ":construction_worker:"
    },
    {
        "id": "🚽",
        "name": ":toilet:"
    },
    {
        "id": "🐖",
        "name": ":pig2:"
    },
    {
        "id": "❔",
        "name": ":grey_question:"
    },
    {
        "id": "🔰",
        "name": ":beginner:"
    },
    {
        "id": "🎻",
        "name": ":violin:"
    },
    {
        "id": "🔛",
        "name": ":on:"
    },
    {
        "id": "💳",
        "name": ":credit_card:"
    },
    {
        "id": "🆔",
        "name": ":id:"
    },
    {
        "id": "㊙",
        "name": ":secret:"
    },
    {
        "id": "🎡",
        "name": ":ferris_wheel:"
    },
    {
        "id": "🎳",
        "name": ":bowling:"
    },
    {
        "id": "♎",
        "name": ":libra:"
    },
    {
        "id": "♍",
        "name": ":virgo:"
    },
    {
        "id": "💈",
        "name": ":barber:"
    },
    {
        "id": "👛",
        "name": ":purse:"
    },
    {
        "id": "🎢",
        "name": ":roller_coaster:"
    },
    {
        "id": "🐀",
        "name": ":rat:"
    },
    {
        "id": "📅",
        "name": ":date:"
    },
    {
        "id": "🏉",
        "name": ":rugby_football:"
    },
    {
        "id": "🐏",
        "name": ":ram:"
    },
    {
        "id": "🔼",
        "name": ":arrow_up_small:"
    },
    {
        "id": "🔲",
        "name": ":black_square_button:"
    },
    {
        "id": "📴",
        "name": ":mobile_phone_off:"
    },
    {
        "id": "🗼",
        "name": ":tokyo_tower:"
    },
    {
        "id": "㊗",
        "name": ":congratulations:"
    },
    {
        "id": "👘",
        "name": ":kimono:"
    },
    {
        "id": "🇷🇺",
        "name": ":ru:"
    },
    {
        "id": "🚢",
        "name": ":ship:"
    },
    {
        "id": "🔎",
        "name": ":mag_right:"
    },
    {
        "id": "🔍",
        "name": ":mag:"
    },
    {
        "id": "🚒",
        "name": ":fire_engine:"
    },
    {
        "id": "🕦",
        "name": ":clock1130:"
    },
    {
        "id": "🚓",
        "name": ":police_car:"
    },
    {
        "id": "🃏",
        "name": ":black_joker:"
    },
    {
        "id": "🌉",
        "name": ":bridge_at_night:"
    },
    {
        "id": "📦",
        "name": ":package:"
    },
    {
        "id": "🚖",
        "name": ":oncoming_taxi:"
    },
    {
        "id": "📆",
        "name": ":calendar:"
    },
    {
        "id": "🏇",
        "name": ":horse_racing:"
    },
    {
        "id": "🐅",
        "name": ":tiger2:"
    },
    {
        "id": "👢",
        "name": ":boot:"
    },
    {
        "id": "🚑",
        "name": ":ambulance:"
    },
    {
        "id": "🔳",
        "name": ":white_square_button:"
    },
    {
        "id": "🐗",
        "name": ":boar:"
    },
    {
        "id": "🎒",
        "name": ":school_satchel:"
    },
    {
        "id": "➿",
        "name": ":loop:"
    },
    {
        "id": "💷",
        "name": ":pound:"
    },
    {
        "id": "ℹ",
        "name": ":information_source:"
    },
    {
        "id": "🐂",
        "name": ":ox:"
    },
    {
        "id": "🍙",
        "name": ":rice_ball:"
    },
    {
        "id": "🆚",
        "name": ":vs:"
    },
    {
        "id": "🔚",
        "name": ":end:"
    },
    {
        "id": "🅿️",
        "name": ":parking:"
    },
    {
        "id": "👡",
        "name": ":sandal:"
    },
    {
        "id": "⛺",
        "name": ":tent:"
    },
    {
        "id": "💺",
        "name": ":seat:"
    },
    {
        "id": "🚕",
        "name": ":taxi:"
    },
    {
        "id": "◾",
        "name": ":black_medium_small_square:"
    },
    {
        "id": "💼",
        "name": ":briefcase:"
    },
    {
        "id": "📰",
        "name": ":newspaper:"
    },
    {
        "id": "🎪",
        "name": ":circus_tent:"
    },
    {
        "id": "🔯",
        "name": ":six_pointed_star:"
    },
    {
        "id": "🚹",
        "name": ":mens:"
    },
    {
        "id": "🏰",
        "name": ":european_castle:"
    },
    {
        "id": "🔦",
        "name": ":flashlight:"
    },
    {
        "id": "🌁",
        "name": ":foggy:"
    },
    {
        "id": "⏫",
        "name": ":arrow_double_up:"
    },
    {
        "id": "🎍",
        "name": ":bamboo:"
    },
    {
        "id": "🎫",
        "name": ":ticket:"
    },
    {
        "id": "🚁",
        "name": ":helicopter:"
    },
    {
        "id": "💽",
        "name": ":minidisc:"
    },
    {
        "id": "🚍",
        "name": ":oncoming_bus:"
    },
    {
        "id": "🍈",
        "name": ":melon:"
    },
    {
        "id": "▫",
        "name": ":white_small_square:"
    },
    {
        "id": "🏤",
        "name": ":european_post_office:"
    },
    {
        "id": "🔟",
        "name": ":keycap_ten:"
    },
    {
        "id": "📓",
        "name": ":notebook:"
    },
    {
        "id": "🔕",
        "name": ":no_bell:"
    },
    {
        "id": "🍢",
        "name": ":oden:"
    },
    {
        "id": "🎏",
        "name": ":flags:"
    },
    {
        "id": "🎠",
        "name": ":carousel_horse:"
    },
    {
        "id": "🐡",
        "name": ":blowfish:"
    },
    {
        "id": "📈",
        "name": ":chart_with_upwards_trend:"
    },
    {
        "id": "🍠",
        "name": ":sweet_potato:"
    },
    {
        "id": "🎿",
        "name": ":ski:"
    },
    {
        "id": "🕛",
        "name": ":clock12:"
    },
    {
        "id": "📶",
        "name": ":signal_strength:"
    },
    {
        "id": "🚧",
        "name": ":construction:"
    },
    {
        "id": "#",
        "name": ""
    },
    {
        "id": "◼",
        "name": ":black_medium_square:"
    },
    {
        "id": "📡",
        "name": ":satellite:"
    },
    {
        "id": "💶",
        "name": ":euro:"
    },
    {
        "id": "👚",
        "name": ":womans_clothes:"
    },
    {
        "id": "📒",
        "name": ":ledger:"
    },
    {
        "id": "🐆",
        "name": ":leopard:"
    },
    {
        "id": "🔅",
        "name": ":low_brightness:"
    },
    {
        "id": "🕒",
        "name": ":clock3:"
    },
    {
        "id": "🏬",
        "name": ":department_store:"
    },
    {
        "id": "🚚",
        "name": ":truck:"
    },
    {
        "id": "🍶",
        "name": ":sake:"
    },
    {
        "id": "🚃",
        "name": ":railway_car:"
    },
    {
        "id": "🚤",
        "name": ":speedboat:"
    },
    {
        "id": "🇰🇷",
        "name": ":ko:"
    },
    {
        "id": "📼",
        "name": ":vhs:"
    },
    {
        "id": "🕐",
        "name": ":clock1:"
    },
    {
        "id": "⏬",
        "name": ":arrow_double_down:"
    },
    {
        "id": "🐃",
        "name": ":water_buffalo:"
    },
    {
        "id": "🔽",
        "name": ":arrow_down_small:"
    },
    {
        "id": "💴",
        "name": ":yen:"
    },
    {
        "id": "🔇",
        "name": ":mute:"
    },
    {
        "id": "🎽",
        "name": ":running_shirt_with_sash:"
    },
    {
        "id": "⬜",
        "name": ":white_large_square:"
    },
    {
        "id": "♿",
        "name": ":wheelchair:"
    },
    {
        "id": "🕑",
        "name": ":clock2:"
    },
    {
        "id": "📎",
        "name": ":paperclip:"
    },
    {
        "id": "🏧",
        "name": ":atm:"
    },
    {
        "id": "🎦",
        "name": ":cinema:"
    },
    {
        "id": "🔭",
        "name": ":telescope:"
    },
    {
        "id": "🎑",
        "name": ":rice_scene:"
    },
    {
        "id": "📘",
        "name": ":blue_book:"
    },
    {
        "id": "◻️",
        "name": ":white_medium_square:"
    },
    {
        "id": "📮",
        "name": ":postbox:"
    },
    {
        "id": "📧",
        "name": ":e-mail:"
    },
    {
        "id": "🐁",
        "name": ":mouse2:"
    },
    {
        "id": "🚄",
        "name": ":bullettrain_side:"
    },
    {
        "id": "🉐",
        "name": ":ideograph_advantage:"
    },
    {
        "id": "🔩",
        "name": ":nut_and_bolt:"
    },
    {
        "id": "🆖",
        "name": ":ng:"
    },
    {
        "id": "🏨",
        "name": ":hotel:"
    },
    {
        "id": "🚾",
        "name": ":wc:"
    },
    {
        "id": "🏮",
        "name": ":izakaya_lantern:"
    },
    {
        "id": "🔂",
        "name": ":repeat_one:"
    },
    {
        "id": "📬",
        "name": ":mailbox_with_mail:"
    },
    {
        "id": "📉",
        "name": ":chart_with_downwards_trend:"
    },
    {
        "id": "📗",
        "name": ":green_book:"
    },
    {
        "id": "🚜",
        "name": ":tractor:"
    },
    {
        "id": "⛲",
        "name": ":fountain:"
    },
    {
        "id": "🚇",
        "name": ":metro:"
    },
    {
        "id": "📋",
        "name": ":clipboard:"
    },
    {
        "id": "📵",
        "name": ":no_mobile_phones:"
    },
    {
        "id": "🕓",
        "name": ":clock4:"
    },
    {
        "id": "🚭",
        "name": ":no_smoking:"
    },
    {
        "id": "⬛",
        "name": ":black_large_square:"
    },
    {
        "id": "🎰",
        "name": ":slot_machine:"
    },
    {
        "id": "🕔",
        "name": ":clock5:"
    },
    {
        "id": "🛁",
        "name": ":bathtub:"
    },
    {
        "id": "📜",
        "name": ":scroll:"
    },
    {
        "id": "🚉",
        "name": ":station:"
    },
    {
        "id": "🍘",
        "name": ":rice_cracker:"
    },
    {
        "id": "🏦",
        "name": ":bank:"
    },
    {
        "id": "🔧",
        "name": ":wrench:"
    },
    {
        "id": "🈯️",
        "name": ":u6307:"
    },
    {
        "id": "🚛",
        "name": ":articulated_lorry:"
    },
    {
        "id": "📄",
        "name": ":page_facing_up:"
    },
    {
        "id": "⛎",
        "name": ":ophiuchus:"
    },
    {
        "id": "📊",
        "name": ":bar_chart:"
    },
    {
        "id": "🚷",
        "name": ":no_pedestrians:"
    },
    {
        "id": "🇨🇳",
        "name": ":cn:"
    },
    {
        "id": "📳",
        "name": ":vibration_mode:"
    },
    {
        "id": "🕙",
        "name": ":clock10:"
    },
    {
        "id": "🕘",
        "name": ":clock9:"
    },
    {
        "id": "🚅",
        "name": ":bullettrain_front:"
    },
    {
        "id": "🚐",
        "name": ":minibus:"
    },
    {
        "id": "🚊",
        "name": ":tram:"
    },
    {
        "id": "🕗",
        "name": ":clock8:"
    },
    {
        "id": "🈳",
        "name": ":u7a7a:"
    },
    {
        "id": "🚥",
        "name": ":traffic_light:"
    },
    {
        "id": "🚵🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚵🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚵🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚵🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚵🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚵‍♀",
        "name": ":woman_mountain_biking:"
    },
    {
        "id": "🚵🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚵🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚵🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚵🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚵🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚵",
        "name": ":mountain_bicyclist:"
    },
    {
        "id": "🔬",
        "name": ":microscope:"
    },
    {
        "id": "🏯",
        "name": ":japanese_castle:"
    },
    {
        "id": "🔖",
        "name": ":bookmark:"
    },
    {
        "id": "📑",
        "name": ":bookmark_tabs:"
    },
    {
        "id": "👝",
        "name": ":pouch:"
    },
    {
        "id": "🆎",
        "name": ":ab:"
    },
    {
        "id": "📃",
        "name": ":page_with_curl:"
    },
    {
        "id": "🎴",
        "name": ":flower_playing_cards:"
    },
    {
        "id": "🕚",
        "name": ":clock11:"
    },
    {
        "id": "📠",
        "name": ":fax:"
    },
    {
        "id": "🕖",
        "name": ":clock7:"
    },
    {
        "id": "◽",
        "name": ":white_medium_small_square:"
    },
    {
        "id": "💱",
        "name": ":currency_exchange:"
    },
    {
        "id": "🔉",
        "name": ":sound:"
    },
    {
        "id": "💹",
        "name": ":chart:"
    },
    {
        "id": "🆑",
        "name": ":cl:"
    },
    {
        "id": "💾",
        "name": ":floppy_disk:"
    },
    {
        "id": "🏣",
        "name": ":post_office:"
    },
    {
        "id": "🔈",
        "name": ":speaker:"
    },
    {
        "id": "🗾",
        "name": ":japan:"
    },
    {
        "id": "🈺",
        "name": ":u55b6:"
    },
    {
        "id": "🀄",
        "name": ":mahjong:"
    },
    {
        "id": "📨",
        "name": ":incoming_envelope:"
    },
    {
        "id": "📙",
        "name": ":orange_book:"
    },
    {
        "id": "🚻",
        "name": ":restroom:"
    },
    {
        "id": "🈚️",
        "name": ":u7121:"
    },
    {
        "id": "🈶",
        "name": ":u6709:"
    },
    {
        "id": "📐",
        "name": ":triangular_ruler:"
    },
    {
        "id": "🚋",
        "name": ":train:"
    },
    {
        "id": "🈸",
        "name": ":u7533:"
    },
    {
        "id": "🚎",
        "name": ":trolleybus:"
    },
    {
        "id": "🈷",
        "name": ":u6708:"
    },
    {
        "id": "🔢",
        "name": ":input_numbers:"
    },
    {
        "id": "📔",
        "name": ":notebook_with_decorative_cover:"
    },
    {
        "id": "🈲",
        "name": ":u7981:"
    },
    {
        "id": "🈵",
        "name": ":u6e80:"
    },
    {
        "id": "📯",
        "name": ":postal_horn:"
    },
    {
        "id": "🏭",
        "name": ":factory:"
    },
    {
        "id": "🚸",
        "name": ":children_crossing:"
    },
    {
        "id": "🚆",
        "name": ":train2:"
    },
    {
        "id": "📏",
        "name": ":straight_ruler:"
    },
    {
        "id": "📟",
        "name": ":pager:"
    },
    {
        "id": "🉑",
        "name": ":accept:"
    },
    {
        "id": "🈴",
        "name": ":u5408:"
    },
    {
        "id": "🔏",
        "name": ":lock_with_ink_pen:"
    },
    {
        "id": "🕜",
        "name": ":clock130:"
    },
    {
        "id": "🈂️",
        "name": ":sa:"
    },
    {
        "id": "📤",
        "name": ":outbox_tray:"
    },
    {
        "id": "🔀",
        "name": ":twisted_rightwards_arrows:"
    },
    {
        "id": "📫",
        "name": ":mailbox:"
    },
    {
        "id": "🚈",
        "name": ":light_rail:"
    },
    {
        "id": "🕤",
        "name": ":clock930:"
    },
    {
        "id": "🚏",
        "name": ":busstop:"
    },
    {
        "id": "📂",
        "name": ":open_file_folder:"
    },
    {
        "id": "📁",
        "name": ":file_folder:"
    },
    {
        "id": "🚰",
        "name": ":potable_water:"
    },
    {
        "id": "📇",
        "name": ":card_index:"
    },
    {
        "id": "🕝",
        "name": ":clock230:"
    },
    {
        "id": "🚝",
        "name": ":monorail:"
    },
    {
        "id": "🕧",
        "name": ":clock1230:"
    },
    {
        "id": "🕥",
        "name": ":clock1030:"
    },
    {
        "id": "🔤",
        "name": ":abc:"
    },
    {
        "id": "📪",
        "name": ":mailbox_closed:"
    },
    {
        "id": "🕟",
        "name": ":clock430:"
    },
    {
        "id": "🚞",
        "name": ":mountain_railway:"
    },
    {
        "id": "🚯",
        "name": ":do_not_litter:"
    },
    {
        "id": "🕞",
        "name": ":clock330:"
    },
    {
        "id": "➗",
        "name": ":heavy_division_sign:"
    },
    {
        "id": "🕢",
        "name": ":clock730:"
    },
    {
        "id": "🕠",
        "name": ":clock530:"
    },
    {
        "id": "🔠",
        "name": ":capital_abcd:"
    },
    {
        "id": "📭",
        "name": ":mailbox_with_no_mail:"
    },
    {
        "id": "🔣",
        "name": ":symbols:"
    },
    {
        "id": "🚡",
        "name": ":aerial_tramway:"
    },
    {
        "id": "🕣",
        "name": ":clock830:"
    },
    {
        "id": "🕡",
        "name": ":clock630:"
    },
    {
        "id": "🔡",
        "name": ":abcd:"
    },
    {
        "id": "🚠",
        "name": ":mountain_cableway:"
    },
    {
        "id": "🈁",
        "name": ":koko:"
    },
    {
        "id": "🛂",
        "name": ":passport_control:"
    },
    {
        "id": "🚱",
        "name": ":non-potable_water:"
    },
    {
        "id": "🚟",
        "name": ":suspension_railway:"
    },
    {
        "id": "🛄",
        "name": ":baggage_claim:"
    },
    {
        "id": "🚳",
        "name": ":no_bicycles:"
    },
    {
        "id": "🏳‍🌈",
        "name": ":rainbow_flag:"
    },
    {
        "id": "🕵🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🕵🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🕵🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🕵🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🕵🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🕵‍♀",
        "name": ":woman_detective:"
    },
    {
        "id": "🕵🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🕵🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🕵🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🕵🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🕵🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🕵",
        "name": ":detective:"
    },
    {
        "id": "☹",
        "name": ":frowning_face:"
    },
    {
        "id": "☠",
        "name": ":skull_crossbones:"
    },
    {
        "id": "🤗",
        "name": ":hugging:"
    },
    {
        "id": "🤖",
        "name": ":robot:"
    },
    {
        "id": "🤕",
        "name": ":face_with_headbandage:"
    },
    {
        "id": "🤔",
        "name": ":thinking:"
    },
    {
        "id": "🤓",
        "name": ":nerd:"
    },
    {
        "id": "🤒",
        "name": ":face_with_thermometer:"
    },
    {
        "id": "🤑",
        "name": ":moneymouth_face:"
    },
    {
        "id": "🤐",
        "name": ":zipper_mouth:"
    },
    {
        "id": "🙄",
        "name": ":rolling_eyes:"
    },
    {
        "id": "🙃",
        "name": ":upside_down:"
    },
    {
        "id": "🙂",
        "name": ":slight_smile:"
    },
    {
        "id": "🙁",
        "name": ":slightly_frowning_face:"
    },
    {
        "id": "🤘🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤘🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤘🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤘🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤘🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤘",
        "name": ":sign_of_the_horns:"
    },
    {
        "id": "🖖🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🖖🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🖖🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🖖🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🖖🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🖖",
        "name": ":vulcan_salute:"
    },
    {
        "id": "🖕🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🖕🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🖕🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🖕🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🖕🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🖕",
        "name": ":middle_finger:"
    },
    {
        "id": "🖐🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🖐🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🖐🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🖐🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🖐🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🖐",
        "name": ":hand_with_fingers_splayed:"
    },
    {
        "id": "✍🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "✍🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "✍🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "✍🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "✍🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "✍",
        "name": ":writing_hand:"
    },
    {
        "id": "🕶",
        "name": ":dark_sunglasses:"
    },
    {
        "id": "👁‍🗨",
        "name": ":eye_speachbubble:"
    },
    {
        "id": "👁",
        "name": ":eye:"
    },
    {
        "id": "🏋🏿‍♀",
        "name": ":weightlifter_woman_dt:"
    },
    {
        "id": "🏋🏾‍♀",
        "name": ":weightlifter_woman_mdt:"
    },
    {
        "id": "🏋🏽‍♀",
        "name": ":weightlifter_woman_mt:"
    },
    {
        "id": "🏋🏼‍♀",
        "name": ":weightlifter_woman_mlt:"
    },
    {
        "id": "🏋🏻‍♀",
        "name": ":weightlifter_woman_lt:"
    },
    {
        "id": "🏋‍♀",
        "name": ":weightlifter_woman:"
    },
    {
        "id": "🏋🏿",
        "name": ":weightlifter_dt:"
    },
    {
        "id": "🏋🏾",
        "name": ":weightlifter_mdt:"
    },
    {
        "id": "🏋🏽",
        "name": ":weightlifter_mt:"
    },
    {
        "id": "🏋🏼",
        "name": ":weightlifter_mlt:"
    },
    {
        "id": "🏋🏻",
        "name": ":weightlifter_lt:"
    },
    {
        "id": "🏋",
        "name": ":weightlifter:"
    },
    {
        "id": "⛹🏿‍♀",
        "name": ":basketballer_woman_dt:"
    },
    {
        "id": "⛹🏾‍♀",
        "name": ":basketballer_woman_mdt:"
    },
    {
        "id": "⛹🏽‍♀",
        "name": ":basketballer_woman_mt:"
    },
    {
        "id": "⛹🏼‍♀",
        "name": ":basketballer_woman_mlt:"
    },
    {
        "id": "⛹🏻‍♀",
        "name": ":basketballer_woman_lt:"
    },
    {
        "id": "⛹‍♀",
        "name": ":woman_bouncing_ball:"
    },
    {
        "id": "⛹🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "⛹🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "⛹🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "⛹🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "⛹🏻",
        "name": ":basketballer_lt:"
    },
    {
        "id": "⛹",
        "name": ":basketballer:"
    },
    {
        "id": "🕴",
        "name": ":man_in_suit:"
    },
    {
        "id": "🏌",
        "name": ":golfer:"
    },
    {
        "id": "🏌‍♀",
        "name": ":golfer_woman:"
    },
    {
        "id": "*️⃣",
        "name": "*"
    },
    {
        "id": "❣️",
        "name": ":heart_exclamation:"
    },
    {
        "id": "✡️",
        "name": ":star_of_david:"
    },
    {
        "id": "✝️",
        "name": ":cross:"
    },
    {
        "id": "⚜",
        "name": ":fleur-de-lis:"
    },
    {
        "id": "⚛",
        "name": ":atom:"
    },
    {
        "id": "☸",
        "name": ":wheel_of_dharma:"
    },
    {
        "id": "☯",
        "name": ":yin_yang:"
    },
    {
        "id": "☮",
        "name": ":peace:"
    },
    {
        "id": "☪",
        "name": ":star_and_crescent:"
    },
    {
        "id": "☦",
        "name": ":orthodox_cross:"
    },
    {
        "id": "☣",
        "name": ":biohazard:"
    },
    {
        "id": "☢",
        "name": ":radioactive:"
    },
    {
        "id": "🛐",
        "name": ":place_of_worship:"
    },
    {
        "id": "🗯",
        "name": ":anger_right:"
    },
    {
        "id": "🕎",
        "name": ":menorah:"
    },
    {
        "id": "🕉",
        "name": ":om_symbol:"
    },
    {
        "id": "⚱",
        "name": ":funeral_urn:"
    },
    {
        "id": "⚰",
        "name": ":coffin:"
    },
    {
        "id": "⚙",
        "name": ":gear:"
    },
    {
        "id": "⚗",
        "name": ":alembic:"
    },
    {
        "id": "⚖",
        "name": ":scales:"
    },
    {
        "id": "⚔",
        "name": ":crossed_swords:"
    },
    {
        "id": "⌨",
        "name": ":keyboard:"
    },
    {
        "id": "🛢",
        "name": ":oil_drum:"
    },
    {
        "id": "🛡",
        "name": ":shield:"
    },
    {
        "id": "🛠",
        "name": ":hammer_and_wrench:"
    },
    {
        "id": "🛏",
        "name": ":bed:"
    },
    {
        "id": "🛎",
        "name": ":bellhop_bell:"
    },
    {
        "id": "🛍",
        "name": ":shopping_bags:"
    },
    {
        "id": "🛌",
        "name": ":sleeping_accommodation:"
    },
    {
        "id": "🛋",
        "name": ":couch_and_lamp:"
    },
    {
        "id": "🗳",
        "name": ":ballot_box:"
    },
    {
        "id": "🗡",
        "name": ":dagger:"
    },
    {
        "id": "🗞",
        "name": ":rolledup_newspaper:"
    },
    {
        "id": "🗝",
        "name": ":old_key:"
    },
    {
        "id": "🗜",
        "name": ":compression:"
    },
    {
        "id": "🗓",
        "name": ":spiral_calendar:"
    },
    {
        "id": "🗒",
        "name": ":spiral_notepad:"
    },
    {
        "id": "🗑",
        "name": ":wastebasket:"
    },
    {
        "id": "🗄",
        "name": ":file_cabinet:"
    },
    {
        "id": "🗃",
        "name": ":card_file_box:"
    },
    {
        "id": "🗂",
        "name": ":card_index_dividers:"
    },
    {
        "id": "🖼",
        "name": ":framed_picture:"
    },
    {
        "id": "🖲",
        "name": ":trackball:"
    },
    {
        "id": "🖱",
        "name": ":computer_mouse:"
    },
    {
        "id": "🖨",
        "name": ":printer:"
    },
    {
        "id": "🖥",
        "name": ":desktop_computer:"
    },
    {
        "id": "🖍",
        "name": ":crayon:"
    },
    {
        "id": "🖌",
        "name": ":paintbrush:"
    },
    {
        "id": "🖋",
        "name": ":fountain_pen:"
    },
    {
        "id": "🖊",
        "name": ":pen:"
    },
    {
        "id": "🖇",
        "name": ":linked_paperclips:"
    },
    {
        "id": "🕹",
        "name": ":joystick:"
    },
    {
        "id": "🕳",
        "name": ":hole:"
    },
    {
        "id": "🕰",
        "name": ":mantelpiece_clock:"
    },
    {
        "id": "🕯",
        "name": ":candle:"
    },
    {
        "id": "📿",
        "name": ":prayer_beads:"
    },
    {
        "id": "📽",
        "name": ":film_projector:"
    },
    {
        "id": "📸",
        "name": ":camera_with_flash:"
    },
    {
        "id": "🏺",
        "name": ":amphora:"
    },
    {
        "id": "🏷",
        "name": ":label:"
    },
    {
        "id": "🏴",
        "name": ":flag_black:"
    },
    {
        "id": "🏳",
        "name": ":flag_white:"
    },
    {
        "id": "🎞",
        "name": ":film_frames:"
    },
    {
        "id": "🎛",
        "name": ":control_knobs:"
    },
    {
        "id": "🎚",
        "name": ":level_slider:"
    },
    {
        "id": "🎙",
        "name": ":studio_microphone:"
    },
    {
        "id": "🌡",
        "name": ":thermometer:"
    },
    {
        "id": "🛳",
        "name": ":passenger_ship:"
    },
    {
        "id": "🛰",
        "name": ":satellite:"
    },
    {
        "id": "🛬",
        "name": ":airplane_arriving:"
    },
    {
        "id": "🛫",
        "name": ":airplane_departure:"
    },
    {
        "id": "🛩",
        "name": ":small_airplane:"
    },
    {
        "id": "🛥",
        "name": ":motor_boat:"
    },
    {
        "id": "🛤",
        "name": ":railway_track:"
    },
    {
        "id": "🛣",
        "name": ":motorway:"
    },
    {
        "id": "🗺",
        "name": ":world_map:"
    },
    {
        "id": "🕍",
        "name": ":synagogue:"
    },
    {
        "id": "🕌",
        "name": ":mosque:"
    },
    {
        "id": "🕋",
        "name": ":kaaba:"
    },
    {
        "id": "🏟",
        "name": ":stadium:"
    },
    {
        "id": "🏞",
        "name": ":national_park:"
    },
    {
        "id": "🏝",
        "name": ":desert_island:"
    },
    {
        "id": "🏜",
        "name": ":desert:"
    },
    {
        "id": "🏛",
        "name": ":classical_building:"
    },
    {
        "id": "🏚",
        "name": ":derelict_house:"
    },
    {
        "id": "🏙",
        "name": ":cityscape:"
    },
    {
        "id": "🏘",
        "name": ":houses:"
    },
    {
        "id": "🏗",
        "name": ":building_construction:"
    },
    {
        "id": "🏖",
        "name": ":beach_with_umbrella:"
    },
    {
        "id": "🏕",
        "name": ":camping:"
    },
    {
        "id": "🏔",
        "name": ":snowcapped_mountain:"
    },
    {
        "id": "🏎",
        "name": ":racing_car:"
    },
    {
        "id": "🏍",
        "name": ":motorcycle:"
    },
    {
        "id": "🏹",
        "name": ":bow_and_arrow:"
    },
    {
        "id": "🏸",
        "name": ":badminton:"
    },
    {
        "id": "🏵",
        "name": ":rosette:"
    },
    {
        "id": "🏓",
        "name": ":ping_pong:"
    },
    {
        "id": "🏒",
        "name": ":ice_hockey:"
    },
    {
        "id": "🏑",
        "name": ":field_hockey:"
    },
    {
        "id": "🏐",
        "name": ":volleyball:"
    },
    {
        "id": "🏏",
        "name": ":cricket_game:"
    },
    {
        "id": "🏅",
        "name": ":medal:"
    },
    {
        "id": "🎟",
        "name": ":admission_tickets:"
    },
    {
        "id": "🎗",
        "name": ":reminder_ribbon:"
    },
    {
        "id": "🎖",
        "name": ":military_medal:"
    },
    {
        "id": "🧀",
        "name": ":cheese_wedge:"
    },
    {
        "id": "🍿",
        "name": ":popcorn:"
    },
    {
        "id": "🍾",
        "name": ":champagne:"
    },
    {
        "id": "🍽",
        "name": ":fork_and_knife_with_plate:"
    },
    {
        "id": "🌶",
        "name": ":hot_pepper:"
    },
    {
        "id": "🌯",
        "name": ":burrito:"
    },
    {
        "id": "🌮",
        "name": ":taco:"
    },
    {
        "id": "🌭",
        "name": ":hotdog:"
    },
    {
        "id": "☘",
        "name": ":shamrock:"
    },
    {
        "id": "☄",
        "name": ":comet:"
    },
    {
        "id": "☃️",
        "name": ":snowman:"
    },
    {
        "id": "☂️",
        "name": ":umbrella:"
    },
    {
        "id": "🦄",
        "name": ":unicorn:"
    },
    {
        "id": "🦃",
        "name": ":turkey:"
    },
    {
        "id": "🦂",
        "name": ":scorpion:"
    },
    {
        "id": "🦁",
        "name": ":lion_face:"
    },
    {
        "id": "🦀",
        "name": ":crab:"
    },
    {
        "id": "🕸",
        "name": ":spider_web:"
    },
    {
        "id": "🕷",
        "name": ":spider:"
    },
    {
        "id": "🕊",
        "name": ":dove:"
    },
    {
        "id": "🐿",
        "name": ":chipmunk:"
    },
    {
        "id": "🌬",
        "name": ":wind_blowing_face:"
    },
    {
        "id": "🌫",
        "name": ":fog:"
    },
    {
        "id": "🌪",
        "name": ":tornado:"
    },
    {
        "id": "🌩",
        "name": ":cloud_with_lightning:"
    },
    {
        "id": "🌨",
        "name": ":cloud_with_snow:"
    },
    {
        "id": "🌧",
        "name": ":cloud_with_rain:"
    },
    {
        "id": "🌦",
        "name": ":sun_behind_rain_cloud:"
    },
    {
        "id": "🌥",
        "name": ":sun_behind_large_cloud:"
    },
    {
        "id": "🌤",
        "name": ":sun_behind_small_cloud:"
    },
    {
        "id": "🗣",
        "name": ":speaking_head:"
    },
    {
        "id": "⏺",
        "name": ":record_button:"
    },
    {
        "id": "⏹",
        "name": ":stop_button:"
    },
    {
        "id": "⏸",
        "name": ":pause_button:"
    },
    {
        "id": "⏯",
        "name": ":play_pause:"
    },
    {
        "id": "⏮",
        "name": ":track_previous:"
    },
    {
        "id": "⏭",
        "name": ":track_next:"
    },
    {
        "id": "⛱",
        "name": ":beach_umbrella:"
    },
    {
        "id": "⛓",
        "name": ":chains:"
    },
    {
        "id": "⛏",
        "name": ":pick:"
    },
    {
        "id": "⚒",
        "name": ":hammer_and_pick:"
    },
    {
        "id": "⏲",
        "name": ":timer_clock:"
    },
    {
        "id": "⏱",
        "name": ":stopwatch:"
    },
    {
        "id": "⛴",
        "name": ":ferry:"
    },
    {
        "id": "⛰",
        "name": ":mountain:"
    },
    {
        "id": "⛩",
        "name": ":shinto_shrine:"
    },
    {
        "id": "⛸",
        "name": ":ice_skate:"
    },
    {
        "id": "⛷",
        "name": ":skier:"
    },
    {
        "id": "⛈",
        "name": ":cloud_with_lightning_and_rain:"
    },
    {
        "id": "⛑",
        "name": ":rescue_worker’s_helmet:"
    },
    {
        "id": "🇦🇨",
        "name": ":flag_ac:"
    },
    {
        "id": "🇦🇩",
        "name": ":flag_ad:"
    },
    {
        "id": "🇦🇪",
        "name": ":flag_ae:"
    },
    {
        "id": "🇦🇫",
        "name": ":flag_af:"
    },
    {
        "id": "🇦🇬",
        "name": ":flag_ag:"
    },
    {
        "id": "🇦🇮",
        "name": ":flag_ai:"
    },
    {
        "id": "🇦🇱",
        "name": ":flag_al:"
    },
    {
        "id": "🇦🇲",
        "name": ":flag_am:"
    },
    {
        "id": "🇦🇴",
        "name": ":flag-ao:"
    },
    {
        "id": "🇦🇶",
        "name": ":flag-aq:"
    },
    {
        "id": "🇦🇷",
        "name": ":flag-ar:"
    },
    {
        "id": "🇦🇸",
        "name": ":flag-as:"
    },
    {
        "id": "🇦🇹",
        "name": ":flag-at:"
    },
    {
        "id": "🇦🇺",
        "name": ":flag-au:"
    },
    {
        "id": "🇦🇼",
        "name": ":flag-aw:"
    },
    {
        "id": "🇦🇽",
        "name": ":flag-ax:"
    },
    {
        "id": "🇦🇿",
        "name": ":flag-az:"
    },
    {
        "id": "🇧🇦",
        "name": ":flag-ba:"
    },
    {
        "id": "🇧🇧",
        "name": ":flag-bb:"
    },
    {
        "id": "🇧🇩",
        "name": ":flag-bd:"
    },
    {
        "id": "🇧🇪",
        "name": ":flag-be:"
    },
    {
        "id": "🇧🇫",
        "name": ":flag-bf:"
    },
    {
        "id": "🇧🇬",
        "name": ":flag-bg:"
    },
    {
        "id": "🇧🇭",
        "name": ":flag-bh:"
    },
    {
        "id": "🇧🇮",
        "name": ":flag-bi:"
    },
    {
        "id": "🇧🇯",
        "name": ":flag-bj:"
    },
    {
        "id": "🇧🇱",
        "name": ":flag-bl:"
    },
    {
        "id": "🇧🇲",
        "name": ":flag-bm:"
    },
    {
        "id": "🇧🇳",
        "name": ":flag-bn:"
    },
    {
        "id": "🇧🇴",
        "name": ":flag-bo:"
    },
    {
        "id": "🇧🇶",
        "name": ":flag-bq:"
    },
    {
        "id": "🇧🇷",
        "name": ":flag-br:"
    },
    {
        "id": "🇧🇸",
        "name": ":flag-bs:"
    },
    {
        "id": "🇧🇹",
        "name": ":flag-bt:"
    },
    {
        "id": "🇧🇻",
        "name": ":flag-bv:"
    },
    {
        "id": "🇧🇼",
        "name": ":flag-bw:"
    },
    {
        "id": "🇧🇾",
        "name": ":flag-by:"
    },
    {
        "id": "🇧🇿",
        "name": ":flag-bz:"
    },
    {
        "id": "🇨🇦",
        "name": ":flag-ca:"
    },
    {
        "id": "🇨🇨",
        "name": ":flag-cc:"
    },
    {
        "id": "🇨🇩",
        "name": ":flag-cd:"
    },
    {
        "id": "🇨🇫",
        "name": ":flag-cf:"
    },
    {
        "id": "🇨🇬",
        "name": ":flag-cg:"
    },
    {
        "id": "🇨🇭",
        "name": ":flag-ch:"
    },
    {
        "id": "🇨🇮",
        "name": ":flag-ci:"
    },
    {
        "id": "🇨🇰",
        "name": ":flag-ck:"
    },
    {
        "id": "🇨🇱",
        "name": ":flag-cl:"
    },
    {
        "id": "🇨🇲",
        "name": ":flag-cm:"
    },
    {
        "id": "🇨🇳",
        "name": ":flag-cn:"
    },
    {
        "id": "🇨🇴",
        "name": ":flag-co:"
    },
    {
        "id": "🇨🇵",
        "name": ":flag-cp:"
    },
    {
        "id": "🇨🇷",
        "name": ":flag-cr:"
    },
    {
        "id": "🇨🇺",
        "name": ":flag-cu:"
    },
    {
        "id": "🇨🇻",
        "name": ":flag-cv:"
    },
    {
        "id": "🇨🇼",
        "name": ":flag-cw:"
    },
    {
        "id": "🇨🇽",
        "name": ":flag-cx:"
    },
    {
        "id": "🇨🇾",
        "name": ":flag-cy:"
    },
    {
        "id": "🇨🇿",
        "name": ":flag-cz:"
    },
    {
        "id": "🇩🇪",
        "name": ":flag-de:"
    },
    {
        "id": "🇩🇬",
        "name": ":flag-dg:"
    },
    {
        "id": "🇩🇯",
        "name": ":flag-dj:"
    },
    {
        "id": "🇩🇰",
        "name": ":flag-dk:"
    },
    {
        "id": "🇩🇲",
        "name": ":flag-dm:"
    },
    {
        "id": "🇩🇴",
        "name": ":flag-do:"
    },
    {
        "id": "🇩🇿",
        "name": ":flag-dz:"
    },
    {
        "id": "🇪🇦",
        "name": ":flag-ea:"
    },
    {
        "id": "🇪🇨",
        "name": ":flag-ec:"
    },
    {
        "id": "🇪🇪",
        "name": ":flag-ee:"
    },
    {
        "id": "🇪🇬",
        "name": ":flag-eg:"
    },
    {
        "id": "🇪🇭",
        "name": ":flag-eh:"
    },
    {
        "id": "🇪🇷",
        "name": ":flag-er:"
    },
    {
        "id": "🇪🇸",
        "name": ":flag-es:"
    },
    {
        "id": "🇪🇹",
        "name": ":flag-et:"
    },
    {
        "id": "🇪🇺",
        "name": ":flag-eu:"
    },
    {
        "id": "🇫🇮",
        "name": ":flag-fi:"
    },
    {
        "id": "🇫🇯",
        "name": ":flag-fj:"
    },
    {
        "id": "🇫🇰",
        "name": ":flag-fk:"
    },
    {
        "id": "🇫🇲",
        "name": ":flag-fm:"
    },
    {
        "id": "🇫🇴",
        "name": ":flag-fo:"
    },
    {
        "id": "🇫🇷",
        "name": ":flag-fr:"
    },
    {
        "id": "🇬🇦",
        "name": ":flag-ga:"
    },
    {
        "id": "🇬🇧",
        "name": ":flag-gb:"
    },
    {
        "id": "🇬🇩",
        "name": ":flag-gd:"
    },
    {
        "id": "🇬🇪",
        "name": ":flag-ge:"
    },
    {
        "id": "🇬🇫",
        "name": ":flag-gf:"
    },
    {
        "id": "🇬🇬",
        "name": ":flag-gg:"
    },
    {
        "id": "🇬🇭",
        "name": ":flag-gh:"
    },
    {
        "id": "🇬🇮",
        "name": ":flag-gi:"
    },
    {
        "id": "🇬🇱",
        "name": ":flag-gl:"
    },
    {
        "id": "🇬🇲",
        "name": ":flag-gm:"
    },
    {
        "id": "🇬🇳",
        "name": ":flag-gn:"
    },
    {
        "id": "🇬🇵",
        "name": ":flag-gp:"
    },
    {
        "id": "🇬🇶",
        "name": ":flag-gq:"
    },
    {
        "id": "🇬🇷",
        "name": ":flag-gr:"
    },
    {
        "id": "🇬🇸",
        "name": ":flag-gs:"
    },
    {
        "id": "🇬🇹",
        "name": ":flag-gt:"
    },
    {
        "id": "🇬🇺",
        "name": ":flag-gu:"
    },
    {
        "id": "🇬🇼",
        "name": ":flag-gw:"
    },
    {
        "id": "🇬🇾",
        "name": ":flag-gy:"
    },
    {
        "id": "🇭🇰",
        "name": ":flag-hk:"
    },
    {
        "id": "🇭🇲",
        "name": ":flag-hm:"
    },
    {
        "id": "🇭🇳",
        "name": ":flag-hn:"
    },
    {
        "id": "🇭🇷",
        "name": ":flag-hr:"
    },
    {
        "id": "🇭🇹",
        "name": ":flag-ht:"
    },
    {
        "id": "🇭🇺",
        "name": ":flag-hu:"
    },
    {
        "id": "🇮🇨",
        "name": ":flag-ic:"
    },
    {
        "id": "🇮🇩",
        "name": ":flag-id:"
    },
    {
        "id": "🇮🇪",
        "name": ":flag-ie:"
    },
    {
        "id": "🇮🇱",
        "name": ":flag-il:"
    },
    {
        "id": "🇮🇲",
        "name": ":flag-im:"
    },
    {
        "id": "🇮🇳",
        "name": ":flag-in:"
    },
    {
        "id": "🇮🇴",
        "name": ":flag-io:"
    },
    {
        "id": "🇮🇶",
        "name": ":flag-iq:"
    },
    {
        "id": "🇮🇷",
        "name": ":flag-ir:"
    },
    {
        "id": "🇮🇸",
        "name": ":flag-is:"
    },
    {
        "id": "🇮🇹",
        "name": ":flag-it:"
    },
    {
        "id": "🇯🇪",
        "name": ":flag-je:"
    },
    {
        "id": "🇯🇲",
        "name": ":flag-jm:"
    },
    {
        "id": "🇯🇴",
        "name": ":flag-jo:"
    },
    {
        "id": "🇯🇵",
        "name": ":flag-jp:"
    },
    {
        "id": "🇰🇪",
        "name": ":flag-ke:"
    },
    {
        "id": "🇰🇬",
        "name": ":flag-kg:"
    },
    {
        "id": "🇰🇭",
        "name": ":flag-kh:"
    },
    {
        "id": "🇰🇮",
        "name": ":flag-ki:"
    },
    {
        "id": "🇰🇲",
        "name": ":flag-km:"
    },
    {
        "id": "🇰🇳",
        "name": ":flag-kn:"
    },
    {
        "id": "🇰🇵",
        "name": ":flag-kp:"
    },
    {
        "id": "🇰🇷",
        "name": ":flag-kr:"
    },
    {
        "id": "🇰🇼",
        "name": ":flag-kw:"
    },
    {
        "id": "🇰🇾",
        "name": ":flag-ky:"
    },
    {
        "id": "🇰🇿",
        "name": ":flag-kz:"
    },
    {
        "id": "🇱🇦",
        "name": ":flag-la:"
    },
    {
        "id": "🇱🇧",
        "name": ":flag-lb:"
    },
    {
        "id": "🇱🇨",
        "name": ":flag-lc:"
    },
    {
        "id": "🇱🇮",
        "name": ":flag-li:"
    },
    {
        "id": "🇱🇰",
        "name": ":flag-lk:"
    },
    {
        "id": "🇱🇷",
        "name": ":flag-lr:"
    },
    {
        "id": "🇱🇸",
        "name": ":flag-ls:"
    },
    {
        "id": "🇱🇹",
        "name": ":flag-lt:"
    },
    {
        "id": "🇱🇺",
        "name": ":flag-lu:"
    },
    {
        "id": "🇱🇻",
        "name": ":flag-lv:"
    },
    {
        "id": "🇱🇾",
        "name": ":flag-ly:"
    },
    {
        "id": "🇲🇦",
        "name": ":flag-ma:"
    },
    {
        "id": "🇲🇨",
        "name": ":flag-mc:"
    },
    {
        "id": "🇲🇩",
        "name": ":flag-md:"
    },
    {
        "id": "🇲🇪",
        "name": ":flag-me:"
    },
    {
        "id": "🇲🇫",
        "name": ":flag-mf:"
    },
    {
        "id": "🇲🇬",
        "name": ":flag-mg:"
    },
    {
        "id": "🇲🇭",
        "name": ":flag-mh:"
    },
    {
        "id": "🇲🇰",
        "name": ":flag-mk:"
    },
    {
        "id": "🇲🇱",
        "name": ":flag-ml:"
    },
    {
        "id": "🇲🇲",
        "name": ":flag-mm:"
    },
    {
        "id": "🇲🇳",
        "name": ":flag-mn:"
    },
    {
        "id": "🇲🇴",
        "name": ":flag-mo:"
    },
    {
        "id": "🇲🇵",
        "name": ":flag-mp:"
    },
    {
        "id": "🇲🇶",
        "name": ":flag-mq:"
    },
    {
        "id": "🇲🇷",
        "name": ":flag-mr:"
    },
    {
        "id": "🇲🇸",
        "name": ":flag-ms:"
    },
    {
        "id": "🇲🇹",
        "name": ":flag-mt:"
    },
    {
        "id": "🇲🇺",
        "name": ":flag-mu:"
    },
    {
        "id": "🇲🇻",
        "name": ":flag-mv:"
    },
    {
        "id": "🇲🇼",
        "name": ":flag-mw:"
    },
    {
        "id": "🇲🇽",
        "name": ":flag-mx:"
    },
    {
        "id": "🇲🇾",
        "name": ":flag-my:"
    },
    {
        "id": "🇲🇿",
        "name": ":flag-mz:"
    },
    {
        "id": "🇳🇦",
        "name": ":flag-na:"
    },
    {
        "id": "🇳🇨",
        "name": ":flag-nc:"
    },
    {
        "id": "🇳🇪",
        "name": ":flag-ne:"
    },
    {
        "id": "🇳🇫",
        "name": ":flag-nf:"
    },
    {
        "id": "🇳🇬",
        "name": ":flag-ng:"
    },
    {
        "id": "🇳🇮",
        "name": ":flag-ni:"
    },
    {
        "id": "🇳🇱",
        "name": ":flag-nl:"
    },
    {
        "id": "🇳🇴",
        "name": ":flag-no:"
    },
    {
        "id": "🇳🇵",
        "name": ":flag-np:"
    },
    {
        "id": "🇳🇷",
        "name": ":flag-nr:"
    },
    {
        "id": "🇳🇺",
        "name": ":flag-nu:"
    },
    {
        "id": "🇳🇿",
        "name": ":flag-nz:"
    },
    {
        "id": "🇴🇲",
        "name": ":flag-om:"
    },
    {
        "id": "🇵🇦",
        "name": ":flag-pa:"
    },
    {
        "id": "🇵🇪",
        "name": ":flag-pe:"
    },
    {
        "id": "🇵🇫",
        "name": ":flag-pf:"
    },
    {
        "id": "🇵🇬",
        "name": ":flag-pg:"
    },
    {
        "id": "🇵🇭",
        "name": ":flag-ph:"
    },
    {
        "id": "🇵🇰",
        "name": ":flag-pk:"
    },
    {
        "id": "🇵🇱",
        "name": ":flag-pl:"
    },
    {
        "id": "🇵🇲",
        "name": ":flag-pm:"
    },
    {
        "id": "🇵🇳",
        "name": ":flag-pn:"
    },
    {
        "id": "🇵🇷",
        "name": ":flag-pr:"
    },
    {
        "id": "🇵🇸",
        "name": ":flag-ps:"
    },
    {
        "id": "🇵🇹",
        "name": ":flag-pt:"
    },
    {
        "id": "🇵🇼",
        "name": ":flag-pw:"
    },
    {
        "id": "🇵🇾",
        "name": ":flag-py:"
    },
    {
        "id": "🇶🇦",
        "name": ":flag-qa:"
    },
    {
        "id": "🇷🇪",
        "name": ":flag-re:"
    },
    {
        "id": "🇷🇴",
        "name": ":flag-ro:"
    },
    {
        "id": "🇷🇸",
        "name": ":flag-rs:"
    },
    {
        "id": "🇷🇺",
        "name": ":flag-ru:"
    },
    {
        "id": "🇷🇼",
        "name": ":flag-rw:"
    },
    {
        "id": "🇸🇦",
        "name": ":flag-sa:"
    },
    {
        "id": "🇸🇧",
        "name": ":flag-sb:"
    },
    {
        "id": "🇸🇨",
        "name": ":flag-sc:"
    },
    {
        "id": "🇸🇩",
        "name": ":flag-sd:"
    },
    {
        "id": "🇸🇪",
        "name": ":flag-se:"
    },
    {
        "id": "🇸🇬",
        "name": ":flag-sg:"
    },
    {
        "id": "🇸🇭",
        "name": ":flag-sh:"
    },
    {
        "id": "🇸🇮",
        "name": ":flag-si:"
    },
    {
        "id": "🇸🇯",
        "name": ":flag-sj:"
    },
    {
        "id": "🇸🇰",
        "name": ":flag-sk:"
    },
    {
        "id": "🇸🇱",
        "name": ":flag-sl:"
    },
    {
        "id": "🇸🇲",
        "name": ":flag-sm:"
    },
    {
        "id": "🇸🇳",
        "name": ":flag-sn:"
    },
    {
        "id": "🇸🇴",
        "name": ":flag-so:"
    },
    {
        "id": "🇸🇷",
        "name": ":flag-sr:"
    },
    {
        "id": "🇸🇸",
        "name": ":flag-ss:"
    },
    {
        "id": "🇸🇹",
        "name": ":flag-st:"
    },
    {
        "id": "🇸🇻",
        "name": ":flag-sv:"
    },
    {
        "id": "🇸🇽",
        "name": ":flag-sx:"
    },
    {
        "id": "🇸🇾",
        "name": ":flag-sy:"
    },
    {
        "id": "🇸🇿",
        "name": ":flag-sz:"
    },
    {
        "id": "🇹🇦",
        "name": ":flag-ta:"
    },
    {
        "id": "🇹🇨",
        "name": ":flag-tc:"
    },
    {
        "id": "🇹🇩",
        "name": ":flag-td:"
    },
    {
        "id": "🇹🇫",
        "name": ":flag-tf:"
    },
    {
        "id": "🇹🇬",
        "name": ":flag-tg:"
    },
    {
        "id": "🇹🇭",
        "name": ":flag-th:"
    },
    {
        "id": "🇹🇯",
        "name": ":flag-tj:"
    },
    {
        "id": "🇹🇰",
        "name": ":flag-tk:"
    },
    {
        "id": "🇹🇱",
        "name": ":flag-tl:"
    },
    {
        "id": "🇹🇲",
        "name": ":flag-tm:"
    },
    {
        "id": "🇹🇳",
        "name": ":flag-tn:"
    },
    {
        "id": "🇹🇴",
        "name": ":flag-to:"
    },
    {
        "id": "🇹🇷",
        "name": ":flag-tr:"
    },
    {
        "id": "🇹🇹",
        "name": ":flag-tt:"
    },
    {
        "id": "🇹🇻",
        "name": ":flag-tv:"
    },
    {
        "id": "🇹🇼",
        "name": ":flag-tw:"
    },
    {
        "id": "🇹🇿",
        "name": ":flag-tz:"
    },
    {
        "id": "🇺🇦",
        "name": ":flag-ua:"
    },
    {
        "id": "🇺🇬",
        "name": ":flag-ug:"
    },
    {
        "id": "🇺🇲",
        "name": ":flag-um:"
    },
    {
        "id": "🇺🇸",
        "name": ":flag-us:"
    },
    {
        "id": "🇺🇾",
        "name": ":flag-uy:"
    },
    {
        "id": "🇺🇿",
        "name": ":flag-uz:"
    },
    {
        "id": "🇻🇦",
        "name": ":flag-va:"
    },
    {
        "id": "🇻🇨",
        "name": ":flag-vc:"
    },
    {
        "id": "🇻🇪",
        "name": ":flag-ve:"
    },
    {
        "id": "🇻🇬",
        "name": ":flag-vg:"
    },
    {
        "id": "🇻🇮",
        "name": ":flag-vi:"
    },
    {
        "id": "🇻🇳",
        "name": ":flag-vn:"
    },
    {
        "id": "🇻🇺",
        "name": ":flag_vu:"
    },
    {
        "id": "🇼🇫",
        "name": ":flag_wf:"
    },
    {
        "id": "🇼🇸",
        "name": ":flag_ws:"
    },
    {
        "id": "🇽🇰",
        "name": ":flag_xk:"
    },
    {
        "id": "🇾🇪",
        "name": ":flag_ye:"
    },
    {
        "id": "🇾🇹",
        "name": ":flag_yt:"
    },
    {
        "id": "🇿🇦",
        "name": ":flag_za:"
    },
    {
        "id": "🇿🇲",
        "name": ":flag_zm:"
    },
    {
        "id": "🇿🇼",
        "name": ":flag_zw:"
    },
    {
        "id": "🖤",
        "name": ":black_heart:"
    },
    {
        "id": "🗨",
        "name": ":speech_left:"
    },
    {
        "id": "🥚",
        "name": ":egg:"
    },
    {
        "id": "🛑",
        "name": ":octagonal_sign:"
    },
    {
        "id": "♠",
        "name": ":spades:"
    },
    {
        "id": "♥",
        "name": ":hearts:"
    },
    {
        "id": "♦",
        "name": ":diamonds:"
    },
    {
        "id": "♣",
        "name": ":clubs:"
    },
    {
        "id": "🥁",
        "name": ":drum:"
    },
    {
        "id": "↔",
        "name": ":left_right_arrow:"
    },
    {
        "id": "©",
        "name": ":copyright:"
    },
    {
        "id": "®",
        "name": ":registered:"
    },
    {
        "id": "™",
        "name": ":tm:"
    },
    {
        "id": "0️⃣",
        "name": ":0:"
    },
    {
        "id": "1️⃣",
        "name": ":1:"
    },
    {
        "id": "2️⃣",
        "name": ":2:"
    },
    {
        "id": "3️⃣",
        "name": ":3:"
    },
    {
        "id": "4️⃣",
        "name": ":4:"
    },
    {
        "id": "5️⃣",
        "name": ":5:"
    },
    {
        "id": "6️⃣",
        "name": ":6:"
    },
    {
        "id": "7️⃣",
        "name": ":7:"
    },
    {
        "id": "8️⃣",
        "name": ":8:"
    },
    {
        "id": "9️⃣",
        "name": ":9:"
    },
    {
        "id": "#⃣",
        "name": ":hash:"
    },
    {
        "id": "*⃣",
        "name": ":asterisk:"
    },
    {
        "id": "0⃣",
        "name": ":zero:"
    },
    {
        "id": "1⃣",
        "name": ":one:"
    },
    {
        "id": "2⃣",
        "name": ":two:"
    },
    {
        "id": "3⃣",
        "name": ":three:"
    },
    {
        "id": "4⃣",
        "name": ":four:"
    },
    {
        "id": "5⃣",
        "name": ":five:"
    },
    {
        "id": "6⃣",
        "name": ":six:"
    },
    {
        "id": "7⃣",
        "name": ":seven:"
    },
    {
        "id": "8⃣",
        "name": ":eight:"
    },
    {
        "id": "9⃣",
        "name": ":nine:"
    },
    {
        "id": "🤣",
        "name": ":rolling_on_the_floor_laughing:"
    },
    {
        "id": "🥰",
        "name": ":smiling_face_with_hearts:"
    },
    {
        "id": "🤩",
        "name": ":starstruck:"
    },
    {
        "id": "☺",
        "name": ":smiling_face:"
    },
    {
        "id": "🤪",
        "name": ":zany_face:"
    },
    {
        "id": "🤭",
        "name": ":face_with_hand_over_mouth:"
    },
    {
        "id": "🤫",
        "name": ":shushing_face:"
    },
    {
        "id": "🤨",
        "name": ":face_with_raised_eyebrow:"
    },
    {
        "id": "🤥",
        "name": ":lying_face:"
    },
    {
        "id": "🤤",
        "name": ":drooling_face:"
    },
    {
        "id": "🤢",
        "name": ":nauseated_face:"
    },
    {
        "id": "🤮",
        "name": ":face_vomiting:"
    },
    {
        "id": "🤧",
        "name": ":sneezing_face:"
    },
    {
        "id": "🥵",
        "name": ":hot_face:"
    },
    {
        "id": "🥶",
        "name": ":cold_face:"
    },
    {
        "id": "🥴",
        "name": ":woozy_face:"
    },
    {
        "id": "🤯",
        "name": ":exploding_head:"
    },
    {
        "id": "🤠",
        "name": ":cowboy_hat_face:"
    },
    {
        "id": "🥳",
        "name": ":partying_face:"
    },
    {
        "id": "🧐",
        "name": ":face_with_monocle:"
    },
    {
        "id": "☹️",
        "name": ":frowning_face:"
    },
    {
        "id": "🥺",
        "name": ":pleading_face:"
    },
    {
        "id": "🥱",
        "name": ":yawning_face:"
    },
    {
        "id": "🤬",
        "name": ":face_with_symbols_on_mouth:"
    },
    {
        "id": "☠️",
        "name": ":skull_and_crossbones:"
    },
    {
        "id": "🤡",
        "name": ":clown_face:"
    },
    {
        "id": "❣",
        "name": ":heart_exclamation:"
    },
    {
        "id": "❤",
        "name": ":red_heart:"
    },
    {
        "id": "🧡",
        "name": ":orange_heart:"
    },
    {
        "id": "🤎",
        "name": ":brown_heart:"
    },
    {
        "id": "🤍",
        "name": ":white_heart:"
    },
    {
        "id": "🕳️",
        "name": ":hole:"
    },
    {
        "id": "👁️‍🗨️",
        "name": ":eye_in_speech_bubble:"
    },
    {
        "id": "👁‍🗨️",
        "name": ":eye_in_speech_bubble:"
    },
    {
        "id": "👁️‍🗨",
        "name": ":eye_in_speech_bubble:"
    },
    {
        "id": "🗨️",
        "name": ":left_speech_bubble:"
    },
    {
        "id": "🗯️",
        "name": ":right_anger_bubble:"
    },
    {
        "id": "🤚",
        "name": ":raised_back_of_hand:"
    },
    {
        "id": "🤚🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤚🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤚🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤚🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤚🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🖐️",
        "name": ":hand_with_fingers_splayed:"
    },
    {
        "id": "🤏",
        "name": ":pinching_hand:"
    },
    {
        "id": "🤏🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤏🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤏🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤏🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤏🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "✌",
        "name": ":victory_hand:"
    },
    {
        "id": "🤞",
        "name": ":crossed_fingers:"
    },
    {
        "id": "🤞🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤞🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤞🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤞🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤞🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤟",
        "name": ":loveyou_gesture:"
    },
    {
        "id": "🤟🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤟🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤟🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤟🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤟🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤙",
        "name": ":call_me_hand:"
    },
    {
        "id": "🤙🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤙🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤙🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤙🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤙🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "☝",
        "name": ":index_pointing_up:"
    },
    {
        "id": "🤛",
        "name": ":leftfacing_fist:"
    },
    {
        "id": "🤛🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤛🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤛🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤛🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤛🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤜",
        "name": ":rightfacing_fist:"
    },
    {
        "id": "🤜🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤜🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤜🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤜🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤜🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤲",
        "name": ":palms_up_together:"
    },
    {
        "id": "🤲🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤲🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤲🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤲🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤲🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤝",
        "name": ":handshake:"
    },
    {
        "id": "✍️",
        "name": ":writing_hand:"
    },
    {
        "id": "🤳",
        "name": ":selfie:"
    },
    {
        "id": "🤳🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤳🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤳🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤳🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤳🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦾",
        "name": ":mechanical_arm:"
    },
    {
        "id": "🦿",
        "name": ":mechanical_leg:"
    },
    {
        "id": "🦵",
        "name": ":leg:"
    },
    {
        "id": "🦵🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦵🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦵🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦵🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦵🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦶",
        "name": ":foot:"
    },
    {
        "id": "🦶🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦶🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦶🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦶🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦶🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦻",
        "name": ":ear_with_hearing_aid:"
    },
    {
        "id": "🦻🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦻🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦻🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦻🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦻🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧠",
        "name": ":brain:"
    },
    {
        "id": "🦷",
        "name": ":tooth:"
    },
    {
        "id": "🦴",
        "name": ":bone:"
    },
    {
        "id": "👁️",
        "name": ":eye:"
    },
    {
        "id": "🧒",
        "name": ":child:"
    },
    {
        "id": "🧒🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧒🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧒🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧒🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧒🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑",
        "name": ":person:"
    },
    {
        "id": "🧑🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧔",
        "name": ":beard:"
    },
    {
        "id": "🧔🏻",
        "name": ":light_skin_tone_beard:"
    },
    {
        "id": "🧔🏼",
        "name": ":mediumlight_skin_tone_beard:"
    },
    {
        "id": "🧔🏽",
        "name": ":medium_skin_tone_beard:"
    },
    {
        "id": "🧔🏾",
        "name": ":mediumdark_skin_tone_beard:"
    },
    {
        "id": "🧔🏿",
        "name": ":dark_skin_tone_beard:"
    },
    {
        "id": "👨‍🦰",
        "name": ":red_hair:"
    },
    {
        "id": "👨🏻‍🦰",
        "name": ":light_skin_tone_red_hair:"
    },
    {
        "id": "👨🏼‍🦰",
        "name": ":mediumlight_skin_tone_red_hair:"
    },
    {
        "id": "👨🏽‍🦰",
        "name": ":medium_skin_tone_red_hair:"
    },
    {
        "id": "👨🏾‍🦰",
        "name": ":mediumdark_skin_tone_red_hair:"
    },
    {
        "id": "👨🏿‍🦰",
        "name": ":dark_skin_tone_red_hair:"
    },
    {
        "id": "👨‍🦱",
        "name": ":curly_hair:"
    },
    {
        "id": "👨🏻‍🦱",
        "name": ":light_skin_tone_curly_hair:"
    },
    {
        "id": "👨🏼‍🦱",
        "name": ":mediumlight_skin_tone_curly_hair:"
    },
    {
        "id": "👨🏽‍🦱",
        "name": ":medium_skin_tone_curly_hair:"
    },
    {
        "id": "👨🏾‍🦱",
        "name": ":mediumdark_skin_tone_curly_hair:"
    },
    {
        "id": "👨🏿‍🦱",
        "name": ":dark_skin_tone_curly_hair:"
    },
    {
        "id": "👨‍🦳",
        "name": ":white_hair:"
    },
    {
        "id": "👨🏻‍🦳",
        "name": ":light_skin_tone_white_hair:"
    },
    {
        "id": "👨🏼‍🦳",
        "name": ":mediumlight_skin_tone_white_hair:"
    },
    {
        "id": "👨🏽‍🦳",
        "name": ":medium_skin_tone_white_hair:"
    },
    {
        "id": "👨🏾‍🦳",
        "name": ":mediumdark_skin_tone_white_hair:"
    },
    {
        "id": "👨🏿‍🦳",
        "name": ":dark_skin_tone_white_hair:"
    },
    {
        "id": "👨‍🦲",
        "name": ":bald:"
    },
    {
        "id": "👨🏻‍🦲",
        "name": ":light_skin_tone_bald:"
    },
    {
        "id": "👨🏼‍🦲",
        "name": ":mediumlight_skin_tone_bald:"
    },
    {
        "id": "👨🏽‍🦲",
        "name": ":medium_skin_tone_bald:"
    },
    {
        "id": "👨🏾‍🦲",
        "name": ":mediumdark_skin_tone_bald:"
    },
    {
        "id": "👨🏿‍🦲",
        "name": ":dark_skin_tone_bald:"
    },
    {
        "id": "👩‍🦰",
        "name": ":red_hair:"
    },
    {
        "id": "👩🏻‍🦰",
        "name": ":light_skin_tone_red_hair:"
    },
    {
        "id": "👩🏼‍🦰",
        "name": ":mediumlight_skin_tone_red_hair:"
    },
    {
        "id": "👩🏽‍🦰",
        "name": ":medium_skin_tone_red_hair:"
    },
    {
        "id": "👩🏾‍🦰",
        "name": ":mediumdark_skin_tone_red_hair:"
    },
    {
        "id": "👩🏿‍🦰",
        "name": ":dark_skin_tone_red_hair:"
    },
    {
        "id": "🧑‍🦰",
        "name": ":red_hair:"
    },
    {
        "id": "🧑🏻‍🦰",
        "name": ":light_skin_tone_red_hair:"
    },
    {
        "id": "🧑🏼‍🦰",
        "name": ":mediumlight_skin_tone_red_hair:"
    },
    {
        "id": "🧑🏽‍🦰",
        "name": ":medium_skin_tone_red_hair:"
    },
    {
        "id": "🧑🏾‍🦰",
        "name": ":mediumdark_skin_tone_red_hair:"
    },
    {
        "id": "🧑🏿‍🦰",
        "name": ":dark_skin_tone_red_hair:"
    },
    {
        "id": "👩‍🦱",
        "name": ":curly_hair:"
    },
    {
        "id": "👩🏻‍🦱",
        "name": ":light_skin_tone_curly_hair:"
    },
    {
        "id": "👩🏼‍🦱",
        "name": ":mediumlight_skin_tone_curly_hair:"
    },
    {
        "id": "👩🏽‍🦱",
        "name": ":medium_skin_tone_curly_hair:"
    },
    {
        "id": "👩🏾‍🦱",
        "name": ":mediumdark_skin_tone_curly_hair:"
    },
    {
        "id": "👩🏿‍🦱",
        "name": ":dark_skin_tone_curly_hair:"
    },
    {
        "id": "🧑‍🦱",
        "name": ":curly_hair:"
    },
    {
        "id": "🧑🏻‍🦱",
        "name": ":light_skin_tone_curly_hair:"
    },
    {
        "id": "🧑🏼‍🦱",
        "name": ":mediumlight_skin_tone_curly_hair:"
    },
    {
        "id": "🧑🏽‍🦱",
        "name": ":medium_skin_tone_curly_hair:"
    },
    {
        "id": "🧑🏾‍🦱",
        "name": ":mediumdark_skin_tone_curly_hair:"
    },
    {
        "id": "🧑🏿‍🦱",
        "name": ":dark_skin_tone_curly_hair:"
    },
    {
        "id": "👩‍🦳",
        "name": ":white_hair:"
    },
    {
        "id": "👩🏻‍🦳",
        "name": ":light_skin_tone_white_hair:"
    },
    {
        "id": "👩🏼‍🦳",
        "name": ":mediumlight_skin_tone_white_hair:"
    },
    {
        "id": "👩🏽‍🦳",
        "name": ":medium_skin_tone_white_hair:"
    },
    {
        "id": "👩🏾‍🦳",
        "name": ":mediumdark_skin_tone_white_hair:"
    },
    {
        "id": "👩🏿‍🦳",
        "name": ":dark_skin_tone_white_hair:"
    },
    {
        "id": "🧑‍🦳",
        "name": ":white_hair:"
    },
    {
        "id": "🧑🏻‍🦳",
        "name": ":light_skin_tone_white_hair:"
    },
    {
        "id": "🧑🏼‍🦳",
        "name": ":mediumlight_skin_tone_white_hair:"
    },
    {
        "id": "🧑🏽‍🦳",
        "name": ":medium_skin_tone_white_hair:"
    },
    {
        "id": "🧑🏾‍🦳",
        "name": ":mediumdark_skin_tone_white_hair:"
    },
    {
        "id": "🧑🏿‍🦳",
        "name": ":dark_skin_tone_white_hair:"
    },
    {
        "id": "👩‍🦲",
        "name": ":bald:"
    },
    {
        "id": "👩🏻‍🦲",
        "name": ":light_skin_tone_bald:"
    },
    {
        "id": "👩🏼‍🦲",
        "name": ":mediumlight_skin_tone_bald:"
    },
    {
        "id": "👩🏽‍🦲",
        "name": ":medium_skin_tone_bald:"
    },
    {
        "id": "👩🏾‍🦲",
        "name": ":mediumdark_skin_tone_bald:"
    },
    {
        "id": "👩🏿‍🦲",
        "name": ":dark_skin_tone_bald:"
    },
    {
        "id": "🧑‍🦲",
        "name": ":bald:"
    },
    {
        "id": "🧑🏻‍🦲",
        "name": ":light_skin_tone_bald:"
    },
    {
        "id": "🧑🏼‍🦲",
        "name": ":mediumlight_skin_tone_bald:"
    },
    {
        "id": "🧑🏽‍🦲",
        "name": ":medium_skin_tone_bald:"
    },
    {
        "id": "🧑🏾‍🦲",
        "name": ":mediumdark_skin_tone_bald:"
    },
    {
        "id": "🧑🏿‍🦲",
        "name": ":dark_skin_tone_bald:"
    },
    {
        "id": "👱‍♀️",
        "name": ":blond_hair:"
    },
    {
        "id": "👱🏻‍♀️",
        "name": ":light_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏼‍♀️",
        "name": ":mediumlight_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏽‍♀️",
        "name": ":medium_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏾‍♀️",
        "name": ":mediumdark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏿‍♀️",
        "name": ":dark_skin_tone_blond_hair:"
    },
    {
        "id": "👱‍♂️",
        "name": ":blond_hair:"
    },
    {
        "id": "👱‍♂",
        "name": ":blond_hair:"
    },
    {
        "id": "👱🏻‍♂️",
        "name": ":light_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏻‍♂",
        "name": ":light_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏼‍♂️",
        "name": ":mediumlight_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏼‍♂",
        "name": ":mediumlight_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏽‍♂️",
        "name": ":medium_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏽‍♂",
        "name": ":medium_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏾‍♂️",
        "name": ":mediumdark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏾‍♂",
        "name": ":mediumdark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏿‍♂️",
        "name": ":dark_skin_tone_blond_hair:"
    },
    {
        "id": "👱🏿‍♂",
        "name": ":dark_skin_tone_blond_hair:"
    },
    {
        "id": "🧓",
        "name": ":older_person:"
    },
    {
        "id": "🧓🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧓🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧓🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧓🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧓🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙍‍♂️",
        "name": ":man_frowning:"
    },
    {
        "id": "🙍🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙍🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙍🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙍🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙍🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙍‍♀️",
        "name": ":woman_frowning:"
    },
    {
        "id": "🙍‍♀",
        "name": ":woman_frowning:"
    },
    {
        "id": "🙍🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙍🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙍🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙍🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙍🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙍🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙍🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙍🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙍🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙍🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙎‍♂️",
        "name": ":man_pouting:"
    },
    {
        "id": "🙎🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙎🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙎🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙎🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙎🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙎‍♀️",
        "name": ":woman_pouting:"
    },
    {
        "id": "🙎‍♀",
        "name": ":woman_pouting:"
    },
    {
        "id": "🙎🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙎🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙎🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙎🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙎🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙎🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙎🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙎🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙎🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙎🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙅‍♂️",
        "name": ":man_gesturing_NO:"
    },
    {
        "id": "🙅🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙅🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙅🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙅🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙅🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙅‍♀️",
        "name": ":woman_gesturing_NO:"
    },
    {
        "id": "🙅‍♀",
        "name": ":woman_gesturing_NO:"
    },
    {
        "id": "🙅🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙅🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙅🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙅🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙅🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙅🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙅🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙅🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙅🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙅🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙆‍♂️",
        "name": ":man_gesturing_OK:"
    },
    {
        "id": "🙆🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙆🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙆🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙆🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙆🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙆‍♀️",
        "name": ":woman_gesturing_OK:"
    },
    {
        "id": "🙆‍♀",
        "name": ":woman_gesturing_OK:"
    },
    {
        "id": "🙆🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙆🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙆🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙆🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙆🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙆🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙆🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙆🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙆🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙆🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💁‍♂️",
        "name": ":man_tipping_hand:"
    },
    {
        "id": "💁🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💁🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💁🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💁🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💁🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💁‍♀️",
        "name": ":woman_tipping_hand:"
    },
    {
        "id": "💁‍♀",
        "name": ":woman_tipping_hand:"
    },
    {
        "id": "💁🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💁🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💁🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💁🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💁🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💁🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💁🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💁🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💁🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💁🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙋‍♂️",
        "name": ":man_raising_hand:"
    },
    {
        "id": "🙋🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙋🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙋🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙋🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙋🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙋‍♀️",
        "name": ":woman_raising_hand:"
    },
    {
        "id": "🙋‍♀",
        "name": ":woman_raising_hand:"
    },
    {
        "id": "🙋🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙋🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙋🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙋🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙋🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙋🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙋🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙋🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙋🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙋🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧏",
        "name": ":deaf_person:"
    },
    {
        "id": "🧏🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧏🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧏🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧏🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧏🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧏‍♂️",
        "name": ":deaf_man:"
    },
    {
        "id": "🧏‍♂",
        "name": ":deaf_man:"
    },
    {
        "id": "🧏🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧏🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧏🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧏🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧏🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧏🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧏🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧏🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧏🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧏🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧏‍♀️",
        "name": ":deaf_woman:"
    },
    {
        "id": "🧏‍♀",
        "name": ":deaf_woman:"
    },
    {
        "id": "🧏🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧏🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧏🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧏🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧏🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧏🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧏🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧏🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧏🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧏🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙇‍♂️",
        "name": ":man_bowing:"
    },
    {
        "id": "🙇‍♂",
        "name": ":man_bowing:"
    },
    {
        "id": "🙇🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙇🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙇🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙇🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙇🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙇🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙇🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙇🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙇🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙇🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🙇‍♀️",
        "name": ":woman_bowing:"
    },
    {
        "id": "🙇🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🙇🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🙇🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🙇🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🙇🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤦",
        "name": ":person_facepalming:"
    },
    {
        "id": "🤦🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤦🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤦🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤦🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤦🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤦‍♂️",
        "name": ":man_facepalming:"
    },
    {
        "id": "🤦‍♂",
        "name": ":man_facepalming:"
    },
    {
        "id": "🤦🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤦🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤦🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤦🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤦🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤦🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤦🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤦🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤦🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤦🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤦‍♀️",
        "name": ":woman_facepalming:"
    },
    {
        "id": "🤦‍♀",
        "name": ":woman_facepalming:"
    },
    {
        "id": "🤦🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤦🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤦🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤦🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤦🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤦🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤦🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤦🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤦🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤦🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤷",
        "name": ":person_shrugging:"
    },
    {
        "id": "🤷🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤷🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤷🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤷🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤷🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤷‍♂️",
        "name": ":man_shrugging:"
    },
    {
        "id": "🤷‍♂",
        "name": ":man_shrugging:"
    },
    {
        "id": "🤷🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤷🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤷🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤷🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤷🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤷🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤷🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤷🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤷🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤷🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤷‍♀️",
        "name": ":woman_shrugging:"
    },
    {
        "id": "🤷‍♀",
        "name": ":woman_shrugging:"
    },
    {
        "id": "🤷🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤷🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤷🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤷🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤷🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤷🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤷🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤷🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤷🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤷🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍⚕️",
        "name": ":health_worker:"
    },
    {
        "id": "🧑‍⚕",
        "name": ":health_worker:"
    },
    {
        "id": "🧑🏻‍⚕️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏻‍⚕",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍⚕️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏼‍⚕",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍⚕️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏽‍⚕",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍⚕️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏾‍⚕",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍⚕️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑🏿‍⚕",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍⚕️",
        "name": ":man_health_worker:"
    },
    {
        "id": "👨‍⚕",
        "name": ":man_health_worker:"
    },
    {
        "id": "👨🏻‍⚕️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏻‍⚕",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍⚕️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏼‍⚕",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍⚕️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏽‍⚕",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍⚕️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏾‍⚕",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍⚕️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨🏿‍⚕",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍⚕️",
        "name": ":woman_health_worker:"
    },
    {
        "id": "👩‍⚕",
        "name": ":woman_health_worker:"
    },
    {
        "id": "👩🏻‍⚕️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏻‍⚕",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍⚕️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏼‍⚕",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍⚕️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏽‍⚕",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍⚕️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏾‍⚕",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍⚕️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩🏿‍⚕",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🎓",
        "name": ":student:"
    },
    {
        "id": "🧑🏻‍🎓",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🎓",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🎓",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🎓",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🎓",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🎓",
        "name": ":man_student:"
    },
    {
        "id": "👨🏻‍🎓",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🎓",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🎓",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🎓",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🎓",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🎓",
        "name": ":woman_student:"
    },
    {
        "id": "👩🏻‍🎓",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🎓",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🎓",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🎓",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🎓",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🏫",
        "name": ":teacher:"
    },
    {
        "id": "🧑🏻‍🏫",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🏫",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🏫",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🏫",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🏫",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🏫",
        "name": ":man_teacher:"
    },
    {
        "id": "👨🏻‍🏫",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🏫",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🏫",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🏫",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🏫",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🏫",
        "name": ":woman_teacher:"
    },
    {
        "id": "👩🏻‍🏫",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🏫",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🏫",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🏫",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🏫",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍⚖️",
        "name": ":judge:"
    },
    {
        "id": "🧑‍⚖",
        "name": ":judge:"
    },
    {
        "id": "🧑🏻‍⚖️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏻‍⚖",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍⚖️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏼‍⚖",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍⚖️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏽‍⚖",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍⚖️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏾‍⚖",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍⚖️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑🏿‍⚖",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍⚖️",
        "name": ":man_judge:"
    },
    {
        "id": "👨‍⚖",
        "name": ":man_judge:"
    },
    {
        "id": "👨🏻‍⚖️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏻‍⚖",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍⚖️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏼‍⚖",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍⚖️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏽‍⚖",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍⚖️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏾‍⚖",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍⚖️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨🏿‍⚖",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍⚖️",
        "name": ":woman_judge:"
    },
    {
        "id": "👩‍⚖",
        "name": ":woman_judge:"
    },
    {
        "id": "👩🏻‍⚖️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏻‍⚖",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍⚖️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏼‍⚖",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍⚖️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏽‍⚖",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍⚖️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏾‍⚖",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍⚖️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩🏿‍⚖",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🌾",
        "name": ":farmer:"
    },
    {
        "id": "🧑🏻‍🌾",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🌾",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🌾",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🌾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🌾",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🌾",
        "name": ":man_farmer:"
    },
    {
        "id": "👨🏻‍🌾",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🌾",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🌾",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🌾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🌾",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🌾",
        "name": ":woman_farmer:"
    },
    {
        "id": "👩🏻‍🌾",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🌾",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🌾",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🌾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🌾",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🍳",
        "name": ":cook:"
    },
    {
        "id": "🧑🏻‍🍳",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🍳",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🍳",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🍳",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🍳",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🍳",
        "name": ":man_cook:"
    },
    {
        "id": "👨🏻‍🍳",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🍳",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🍳",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🍳",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🍳",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🍳",
        "name": ":woman_cook:"
    },
    {
        "id": "👩🏻‍🍳",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🍳",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🍳",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🍳",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🍳",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🔧",
        "name": ":mechanic:"
    },
    {
        "id": "🧑🏻‍🔧",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🔧",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🔧",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🔧",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🔧",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🔧",
        "name": ":man_mechanic:"
    },
    {
        "id": "👨🏻‍🔧",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🔧",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🔧",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🔧",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🔧",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🔧",
        "name": ":woman_mechanic:"
    },
    {
        "id": "👩🏻‍🔧",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🔧",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🔧",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🔧",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🔧",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🏭",
        "name": ":factory_worker:"
    },
    {
        "id": "🧑🏻‍🏭",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🏭",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🏭",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🏭",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🏭",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🏭",
        "name": ":man_factory_worker:"
    },
    {
        "id": "👨🏻‍🏭",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🏭",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🏭",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🏭",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🏭",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🏭",
        "name": ":woman_factory_worker:"
    },
    {
        "id": "👩🏻‍🏭",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🏭",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🏭",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🏭",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🏭",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍💼",
        "name": ":office_worker:"
    },
    {
        "id": "🧑🏻‍💼",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍💼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍💼",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍💼",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍💼",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍💼",
        "name": ":man_office_worker:"
    },
    {
        "id": "👨🏻‍💼",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍💼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍💼",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍💼",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍💼",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍💼",
        "name": ":woman_office_worker:"
    },
    {
        "id": "👩🏻‍💼",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍💼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍💼",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍💼",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍💼",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🔬",
        "name": ":scientist:"
    },
    {
        "id": "🧑🏻‍🔬",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🔬",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🔬",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🔬",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🔬",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🔬",
        "name": ":man_scientist:"
    },
    {
        "id": "👨🏻‍🔬",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🔬",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🔬",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🔬",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🔬",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🔬",
        "name": ":woman_scientist:"
    },
    {
        "id": "👩🏻‍🔬",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🔬",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🔬",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🔬",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🔬",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍💻",
        "name": ":technologist:"
    },
    {
        "id": "🧑🏻‍💻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍💻",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍💻",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍💻",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍💻",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍💻",
        "name": ":man_technologist:"
    },
    {
        "id": "👨🏻‍💻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍💻",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍💻",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍💻",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍💻",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍💻",
        "name": ":woman_technologist:"
    },
    {
        "id": "👩🏻‍💻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍💻",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍💻",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍💻",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍💻",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🎤",
        "name": ":singer:"
    },
    {
        "id": "🧑🏻‍🎤",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🎤",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🎤",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🎤",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🎤",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🎤",
        "name": ":man_singer:"
    },
    {
        "id": "👨🏻‍🎤",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🎤",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🎤",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🎤",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🎤",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🎤",
        "name": ":woman_singer:"
    },
    {
        "id": "👩🏻‍🎤",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🎤",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🎤",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🎤",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🎤",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🎨",
        "name": ":artist:"
    },
    {
        "id": "🧑🏻‍🎨",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🎨",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🎨",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🎨",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🎨",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🎨",
        "name": ":man_artist:"
    },
    {
        "id": "👨🏻‍🎨",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🎨",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🎨",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🎨",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🎨",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🎨",
        "name": ":woman_artist:"
    },
    {
        "id": "👩🏻‍🎨",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🎨",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🎨",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🎨",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🎨",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍✈️",
        "name": ":pilot:"
    },
    {
        "id": "🧑‍✈",
        "name": ":pilot:"
    },
    {
        "id": "🧑🏻‍✈️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏻‍✈",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍✈️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏼‍✈",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍✈️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏽‍✈",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍✈️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏾‍✈",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍✈️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑🏿‍✈",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍✈️",
        "name": ":man_pilot:"
    },
    {
        "id": "👨‍✈",
        "name": ":man_pilot:"
    },
    {
        "id": "👨🏻‍✈️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏻‍✈",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍✈️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏼‍✈",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍✈️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏽‍✈",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍✈️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏾‍✈",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍✈️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨🏿‍✈",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍✈️",
        "name": ":woman_pilot:"
    },
    {
        "id": "👩‍✈",
        "name": ":woman_pilot:"
    },
    {
        "id": "👩🏻‍✈️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏻‍✈",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍✈️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏼‍✈",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍✈️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏽‍✈",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍✈️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏾‍✈",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍✈️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩🏿‍✈",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🚀",
        "name": ":astronaut:"
    },
    {
        "id": "🧑🏻‍🚀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🚀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🚀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🚀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🚀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🚀",
        "name": ":man_astronaut:"
    },
    {
        "id": "👨🏻‍🚀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🚀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🚀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🚀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🚀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🚀",
        "name": ":woman_astronaut:"
    },
    {
        "id": "👩🏻‍🚀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🚀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🚀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🚀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🚀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🚒",
        "name": ":firefighter:"
    },
    {
        "id": "🧑🏻‍🚒",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🚒",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🚒",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🚒",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🚒",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🚒",
        "name": ":man_firefighter:"
    },
    {
        "id": "👨🏻‍🚒",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🚒",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🚒",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🚒",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🚒",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🚒",
        "name": ":woman_firefighter:"
    },
    {
        "id": "👩🏻‍🚒",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🚒",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🚒",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🚒",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🚒",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👮‍♂️",
        "name": ":man_police_officer:"
    },
    {
        "id": "👮‍♂",
        "name": ":man_police_officer:"
    },
    {
        "id": "👮🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👮🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👮🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👮🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👮🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👮🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👮🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👮🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👮🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👮🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👮‍♀️",
        "name": ":woman_police_officer:"
    },
    {
        "id": "👮🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👮🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👮🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👮🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👮🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🕵️",
        "name": ":detective:"
    },
    {
        "id": "🕵️‍♂️",
        "name": ":man_detective:"
    },
    {
        "id": "🕵‍♂️",
        "name": ":man_detective:"
    },
    {
        "id": "🕵️‍♂",
        "name": ":man_detective:"
    },
    {
        "id": "🕵‍♂",
        "name": ":man_detective:"
    },
    {
        "id": "🕵🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🕵🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🕵🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🕵🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🕵🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🕵🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🕵🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🕵🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🕵🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🕵🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🕵️‍♀️",
        "name": ":woman_detective:"
    },
    {
        "id": "🕵‍♀️",
        "name": ":woman_detective:"
    },
    {
        "id": "🕵️‍♀",
        "name": ":woman_detective:"
    },
    {
        "id": "🕵🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🕵🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🕵🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🕵🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🕵🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💂‍♂️",
        "name": ":man_guard:"
    },
    {
        "id": "💂‍♂",
        "name": ":man_guard:"
    },
    {
        "id": "💂🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💂🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💂🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💂🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💂🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💂🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💂🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💂🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💂🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💂🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💂‍♀️",
        "name": ":woman_guard:"
    },
    {
        "id": "💂🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💂🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💂🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💂🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💂🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👷‍♂️",
        "name": ":man_construction_worker:"
    },
    {
        "id": "👷‍♂",
        "name": ":man_construction_worker:"
    },
    {
        "id": "👷🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👷🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👷🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👷🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👷🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👷🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👷🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👷🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👷🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👷🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👷‍♀️",
        "name": ":woman_construction_worker:"
    },
    {
        "id": "👷🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👷🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👷🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👷🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👷🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤴",
        "name": ":prince:"
    },
    {
        "id": "🤴🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤴🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤴🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤴🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤴🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👳‍♂️",
        "name": ":man_wearing_turban:"
    },
    {
        "id": "👳‍♂",
        "name": ":man_wearing_turban:"
    },
    {
        "id": "👳🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👳🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👳🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👳🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👳🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👳🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👳🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👳🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👳🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👳🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👳‍♀️",
        "name": ":woman_wearing_turban:"
    },
    {
        "id": "👳🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👳🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👳🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👳🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👳🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧕",
        "name": ":woman_with_headscarf:"
    },
    {
        "id": "🧕🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧕🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧕🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧕🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧕🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤵",
        "name": ":man_in_tuxedo:"
    },
    {
        "id": "🤵🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤵🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤵🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤵🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤵🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤰",
        "name": ":pregnant_woman:"
    },
    {
        "id": "🤰🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤰🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤰🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤰🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤰🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤱",
        "name": ":breastfeeding:"
    },
    {
        "id": "🤱🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤱🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤱🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤱🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤱🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤶",
        "name": ":Mrs_Claus:"
    },
    {
        "id": "🤶🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤶🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤶🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤶🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤶🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦸",
        "name": ":superhero:"
    },
    {
        "id": "🦸🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦸🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦸🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦸🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦸🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦸‍♂️",
        "name": ":man_superhero:"
    },
    {
        "id": "🦸‍♂",
        "name": ":man_superhero:"
    },
    {
        "id": "🦸🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦸🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦸🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦸🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦸🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦸🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦸🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦸🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦸🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦸🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦸‍♀️",
        "name": ":woman_superhero:"
    },
    {
        "id": "🦸‍♀",
        "name": ":woman_superhero:"
    },
    {
        "id": "🦸🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦸🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦸🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦸🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦸🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦸🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦸🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦸🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦸🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦸🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦹",
        "name": ":supervillain:"
    },
    {
        "id": "🦹🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦹🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦹🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦹🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦹🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦹‍♂️",
        "name": ":man_supervillain:"
    },
    {
        "id": "🦹‍♂",
        "name": ":man_supervillain:"
    },
    {
        "id": "🦹🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦹🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦹🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦹🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦹🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦹🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦹🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦹🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦹🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦹🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦹‍♀️",
        "name": ":woman_supervillain:"
    },
    {
        "id": "🦹‍♀",
        "name": ":woman_supervillain:"
    },
    {
        "id": "🦹🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦹🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🦹🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦹🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🦹🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦹🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🦹🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦹🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🦹🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦹🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧙",
        "name": ":mage:"
    },
    {
        "id": "🧙🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧙🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧙🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧙🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧙🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧙‍♂️",
        "name": ":man_mage:"
    },
    {
        "id": "🧙‍♂",
        "name": ":man_mage:"
    },
    {
        "id": "🧙🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧙🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧙🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧙🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧙🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧙🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧙🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧙🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧙🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧙🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧙‍♀️",
        "name": ":woman_mage:"
    },
    {
        "id": "🧙‍♀",
        "name": ":woman_mage:"
    },
    {
        "id": "🧙🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧙🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧙🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧙🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧙🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧙🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧙🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧙🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧙🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧙🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧚",
        "name": ":fairy:"
    },
    {
        "id": "🧚🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧚🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧚🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧚🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧚🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧚‍♂️",
        "name": ":man_fairy:"
    },
    {
        "id": "🧚‍♂",
        "name": ":man_fairy:"
    },
    {
        "id": "🧚🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧚🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧚🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧚🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧚🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧚🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧚🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧚🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧚🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧚🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧚‍♀️",
        "name": ":woman_fairy:"
    },
    {
        "id": "🧚‍♀",
        "name": ":woman_fairy:"
    },
    {
        "id": "🧚🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧚🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧚🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧚🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧚🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧚🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧚🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧚🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧚🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧚🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧛",
        "name": ":vampire:"
    },
    {
        "id": "🧛🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧛🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧛🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧛🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧛🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧛‍♂️",
        "name": ":man_vampire:"
    },
    {
        "id": "🧛‍♂",
        "name": ":man_vampire:"
    },
    {
        "id": "🧛🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧛🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧛🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧛🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧛🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧛🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧛🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧛🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧛🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧛🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧛‍♀️",
        "name": ":woman_vampire:"
    },
    {
        "id": "🧛‍♀",
        "name": ":woman_vampire:"
    },
    {
        "id": "🧛🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧛🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧛🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧛🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧛🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧛🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧛🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧛🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧛🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧛🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧜",
        "name": ":merperson:"
    },
    {
        "id": "🧜🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧜🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧜🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧜🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧜🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧜‍♂️",
        "name": ":merman:"
    },
    {
        "id": "🧜‍♂",
        "name": ":merman:"
    },
    {
        "id": "🧜🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧜🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧜🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧜🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧜🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧜🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧜🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧜🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧜🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧜🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧜‍♀️",
        "name": ":mermaid:"
    },
    {
        "id": "🧜‍♀",
        "name": ":mermaid:"
    },
    {
        "id": "🧜🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧜🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧜🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧜🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧜🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧜🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧜🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧜🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧜🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧜🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧝",
        "name": ":elf:"
    },
    {
        "id": "🧝🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧝🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧝🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧝🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧝🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧝‍♂️",
        "name": ":man_elf:"
    },
    {
        "id": "🧝‍♂",
        "name": ":man_elf:"
    },
    {
        "id": "🧝🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧝🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧝🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧝🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧝🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧝🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧝🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧝🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧝🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧝🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧝‍♀️",
        "name": ":woman_elf:"
    },
    {
        "id": "🧝‍♀",
        "name": ":woman_elf:"
    },
    {
        "id": "🧝🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧝🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧝🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧝🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧝🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧝🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧝🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧝🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧝🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧝🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧞",
        "name": ":genie:"
    },
    {
        "id": "🧞‍♂️",
        "name": ":man_genie:"
    },
    {
        "id": "🧞‍♂",
        "name": ":man_genie:"
    },
    {
        "id": "🧞‍♀️",
        "name": ":woman_genie:"
    },
    {
        "id": "🧞‍♀",
        "name": ":woman_genie:"
    },
    {
        "id": "🧟",
        "name": ":zombie:"
    },
    {
        "id": "🧟‍♂️",
        "name": ":man_zombie:"
    },
    {
        "id": "🧟‍♂",
        "name": ":man_zombie:"
    },
    {
        "id": "🧟‍♀️",
        "name": ":woman_zombie:"
    },
    {
        "id": "🧟‍♀",
        "name": ":woman_zombie:"
    },
    {
        "id": "💆‍♂️",
        "name": ":man_getting_massage:"
    },
    {
        "id": "💆🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💆🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💆🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💆🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💆🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💆‍♀️",
        "name": ":woman_getting_massage:"
    },
    {
        "id": "💆‍♀",
        "name": ":woman_getting_massage:"
    },
    {
        "id": "💆🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💆🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💆🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💆🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💆🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💆🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💆🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💆🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💆🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💆🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💇‍♂️",
        "name": ":man_getting_haircut:"
    },
    {
        "id": "💇🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💇🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💇🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💇🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💇🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💇‍♀️",
        "name": ":woman_getting_haircut:"
    },
    {
        "id": "💇‍♀",
        "name": ":woman_getting_haircut:"
    },
    {
        "id": "💇🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💇🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "💇🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💇🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "💇🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💇🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "💇🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💇🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "💇🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "💇🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚶‍♂️",
        "name": ":man_walking:"
    },
    {
        "id": "🚶‍♂",
        "name": ":man_walking:"
    },
    {
        "id": "🚶🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚶🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚶🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚶🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚶🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚶🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚶🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚶🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚶🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚶🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚶‍♀️",
        "name": ":woman_walking:"
    },
    {
        "id": "🚶🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚶🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚶🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚶🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚶🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧍",
        "name": ":person_standing:"
    },
    {
        "id": "🧍🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧍🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧍🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧍🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧍🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧍‍♂️",
        "name": ":man_standing:"
    },
    {
        "id": "🧍‍♂",
        "name": ":man_standing:"
    },
    {
        "id": "🧍🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧍🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧍🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧍🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧍🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧍🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧍🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧍🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧍🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧍🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧍‍♀️",
        "name": ":woman_standing:"
    },
    {
        "id": "🧍‍♀",
        "name": ":woman_standing:"
    },
    {
        "id": "🧍🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧍🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧍🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧍🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧍🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧍🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧍🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧍🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧍🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧍🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧎",
        "name": ":person_kneeling:"
    },
    {
        "id": "🧎🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧎🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧎🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧎🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧎🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧎‍♂️",
        "name": ":man_kneeling:"
    },
    {
        "id": "🧎‍♂",
        "name": ":man_kneeling:"
    },
    {
        "id": "🧎🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧎🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧎🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧎🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧎🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧎🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧎🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧎🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧎🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧎🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧎‍♀️",
        "name": ":woman_kneeling:"
    },
    {
        "id": "🧎‍♀",
        "name": ":woman_kneeling:"
    },
    {
        "id": "🧎🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧎🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧎🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧎🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧎🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧎🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧎🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧎🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧎🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧎🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🦯",
        "name": ":person_with_probing_cane:"
    },
    {
        "id": "🧑🏻‍🦯",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🦯",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🦯",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🦯",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🦯",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🦯",
        "name": ":man_with_probing_cane:"
    },
    {
        "id": "👨🏻‍🦯",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🦯",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🦯",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🦯",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🦯",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🦯",
        "name": ":woman_with_probing_cane:"
    },
    {
        "id": "👩🏻‍🦯",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🦯",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🦯",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🦯",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🦯",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🦼",
        "name": ":person_in_motorized_wheelchair:"
    },
    {
        "id": "🧑🏻‍🦼",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🦼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🦼",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🦼",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🦼",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🦼",
        "name": ":man_in_motorized_wheelchair:"
    },
    {
        "id": "👨🏻‍🦼",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🦼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🦼",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🦼",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🦼",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🦼",
        "name": ":woman_in_motorized_wheelchair:"
    },
    {
        "id": "👩🏻‍🦼",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🦼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🦼",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🦼",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🦼",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🦽",
        "name": ":person_in_manual_wheelchair:"
    },
    {
        "id": "🧑🏻‍🦽",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🦽",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🦽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🦽",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🦽",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👨‍🦽",
        "name": ":man_in_manual_wheelchair:"
    },
    {
        "id": "👨🏻‍🦽",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏼‍🦽",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏽‍🦽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏾‍🦽",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏿‍🦽",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍🦽",
        "name": ":woman_in_manual_wheelchair:"
    },
    {
        "id": "👩🏻‍🦽",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏼‍🦽",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏽‍🦽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏾‍🦽",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏿‍🦽",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏃‍♂️",
        "name": ":man_running:"
    },
    {
        "id": "🏃‍♂",
        "name": ":man_running:"
    },
    {
        "id": "🏃🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏃🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏃🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏃🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏃🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏃🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏃🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏃🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏃🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏃🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏃‍♀️",
        "name": ":woman_running:"
    },
    {
        "id": "🏃🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏃🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏃🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏃🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏃🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🕺",
        "name": ":man_dancing:"
    },
    {
        "id": "🕺🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🕺🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🕺🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🕺🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🕺🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🕴️",
        "name": ":man_in_suit_levitating:"
    },
    {
        "id": "🕴🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🕴🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🕴🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🕴🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🕴🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👯‍♂️",
        "name": ":men_with_bunny_ears:"
    },
    {
        "id": "👯‍♀️",
        "name": ":women_with_bunny_ears:"
    },
    {
        "id": "👯‍♀",
        "name": ":women_with_bunny_ears:"
    },
    {
        "id": "🧖",
        "name": ":person_in_steamy_room:"
    },
    {
        "id": "🧖🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧖🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧖🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧖🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧖🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧖‍♂️",
        "name": ":man_in_steamy_room:"
    },
    {
        "id": "🧖‍♂",
        "name": ":man_in_steamy_room:"
    },
    {
        "id": "🧖🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧖🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧖🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧖🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧖🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧖🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧖🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧖🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧖🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧖🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧖‍♀️",
        "name": ":woman_in_steamy_room:"
    },
    {
        "id": "🧖‍♀",
        "name": ":woman_in_steamy_room:"
    },
    {
        "id": "🧖🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧖🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧖🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧖🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧖🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧖🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧖🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧖🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧖🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧖🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧗",
        "name": ":person_climbing:"
    },
    {
        "id": "🧗🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧗🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧗🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧗🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧗🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧗‍♂️",
        "name": ":man_climbing:"
    },
    {
        "id": "🧗‍♂",
        "name": ":man_climbing:"
    },
    {
        "id": "🧗🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧗🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧗🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧗🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧗🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧗🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧗🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧗🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧗🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧗🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧗‍♀️",
        "name": ":woman_climbing:"
    },
    {
        "id": "🧗‍♀",
        "name": ":woman_climbing:"
    },
    {
        "id": "🧗🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧗🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧗🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧗🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧗🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧗🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧗🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧗🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧗🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧗🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤺",
        "name": ":person_fencing:"
    },
    {
        "id": "🏇🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏇🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏇🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏇🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏇🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "⛷️",
        "name": ":skier:"
    },
    {
        "id": "🏂🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏂🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏂🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏂🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏂🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏌️",
        "name": ":person_golfing:"
    },
    {
        "id": "🏌🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏌🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏌🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏌🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏌🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏌️‍♂️",
        "name": ":man_golfing:"
    },
    {
        "id": "🏌‍♂️",
        "name": ":man_golfing:"
    },
    {
        "id": "🏌️‍♂",
        "name": ":man_golfing:"
    },
    {
        "id": "🏌‍♂",
        "name": ":man_golfing:"
    },
    {
        "id": "🏌🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏌🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏌🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏌🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏌🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏌🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏌🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏌🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏌🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏌🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏌️‍♀️",
        "name": ":woman_golfing:"
    },
    {
        "id": "🏌‍♀️",
        "name": ":woman_golfing:"
    },
    {
        "id": "🏌️‍♀",
        "name": ":woman_golfing:"
    },
    {
        "id": "🏌🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏌🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏌🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏌🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏌🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏌🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏌🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏌🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏌🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏌🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏄‍♂️",
        "name": ":man_surfing:"
    },
    {
        "id": "🏄‍♂",
        "name": ":man_surfing:"
    },
    {
        "id": "🏄🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏄🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏄🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏄🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏄🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏄🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏄🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏄🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏄🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏄🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏄‍♀️",
        "name": ":woman_surfing:"
    },
    {
        "id": "🏄🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏄🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏄🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏄🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏄🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚣‍♂️",
        "name": ":man_rowing_boat:"
    },
    {
        "id": "🚣‍♂",
        "name": ":man_rowing_boat:"
    },
    {
        "id": "🚣🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚣🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚣🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚣🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚣🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚣🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚣🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚣🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚣🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚣🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚣‍♀️",
        "name": ":woman_rowing_boat:"
    },
    {
        "id": "🚣🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚣🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚣🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚣🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚣🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏊‍♂️",
        "name": ":man_swimming:"
    },
    {
        "id": "🏊‍♂",
        "name": ":man_swimming:"
    },
    {
        "id": "🏊🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏊🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏊🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏊🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏊🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏊🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏊🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏊🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏊🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏊🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏊‍♀️",
        "name": ":woman_swimming:"
    },
    {
        "id": "🏊🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏊🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏊🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏊🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏊🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "⛹️",
        "name": ":person_bouncing_ball:"
    },
    {
        "id": "⛹️‍♂️",
        "name": ":man_bouncing_ball:"
    },
    {
        "id": "⛹‍♂️",
        "name": ":man_bouncing_ball:"
    },
    {
        "id": "⛹️‍♂",
        "name": ":man_bouncing_ball:"
    },
    {
        "id": "⛹‍♂",
        "name": ":man_bouncing_ball:"
    },
    {
        "id": "⛹🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "⛹🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "⛹🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "⛹🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "⛹🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "⛹🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "⛹🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "⛹🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "⛹🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "⛹🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "⛹️‍♀️",
        "name": ":woman_bouncing_ball:"
    },
    {
        "id": "⛹‍♀️",
        "name": ":woman_bouncing_ball:"
    },
    {
        "id": "⛹️‍♀",
        "name": ":woman_bouncing_ball:"
    },
    {
        "id": "⛹🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "⛹🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "⛹🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "⛹🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "⛹🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏋️",
        "name": ":person_lifting_weights:"
    },
    {
        "id": "🏋️‍♂️",
        "name": ":man_lifting_weights:"
    },
    {
        "id": "🏋‍♂️",
        "name": ":man_lifting_weights:"
    },
    {
        "id": "🏋️‍♂",
        "name": ":man_lifting_weights:"
    },
    {
        "id": "🏋‍♂",
        "name": ":man_lifting_weights:"
    },
    {
        "id": "🏋🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏋🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏋🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏋🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏋🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏋🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏋🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏋🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏋🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏋🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🏋️‍♀️",
        "name": ":woman_lifting_weights:"
    },
    {
        "id": "🏋‍♀️",
        "name": ":woman_lifting_weights:"
    },
    {
        "id": "🏋️‍♀",
        "name": ":woman_lifting_weights:"
    },
    {
        "id": "🏋🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏋🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏋🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏋🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏋🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚴‍♂️",
        "name": ":man_biking:"
    },
    {
        "id": "🚴‍♂",
        "name": ":man_biking:"
    },
    {
        "id": "🚴🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚴🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚴🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚴🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚴🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚴🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚴🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚴🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚴🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚴🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚴‍♀️",
        "name": ":woman_biking:"
    },
    {
        "id": "🚴🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚴🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚴🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚴🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚴🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚵‍♂️",
        "name": ":man_mountain_biking:"
    },
    {
        "id": "🚵‍♂",
        "name": ":man_mountain_biking:"
    },
    {
        "id": "🚵🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚵🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚵🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚵🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚵🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚵🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚵🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚵🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚵🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚵🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🚵‍♀️",
        "name": ":woman_mountain_biking:"
    },
    {
        "id": "🚵🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🚵🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🚵🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🚵🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🚵🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤸",
        "name": ":person_cartwheeling:"
    },
    {
        "id": "🤸🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤸🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤸🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤸🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤸🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤸‍♂️",
        "name": ":man_cartwheeling:"
    },
    {
        "id": "🤸‍♂",
        "name": ":man_cartwheeling:"
    },
    {
        "id": "🤸🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤸🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤸🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤸🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤸🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤸🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤸🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤸🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤸🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤸🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤸‍♀️",
        "name": ":woman_cartwheeling:"
    },
    {
        "id": "🤸‍♀",
        "name": ":woman_cartwheeling:"
    },
    {
        "id": "🤸🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤸🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤸🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤸🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤸🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤸🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤸🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤸🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤸🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤸🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤼",
        "name": ":people_wrestling:"
    },
    {
        "id": "🤼‍♂️",
        "name": ":men_wrestling:"
    },
    {
        "id": "🤼‍♂",
        "name": ":men_wrestling:"
    },
    {
        "id": "🤼‍♀️",
        "name": ":women_wrestling:"
    },
    {
        "id": "🤼‍♀",
        "name": ":women_wrestling:"
    },
    {
        "id": "🤽",
        "name": ":person_playing_water_polo:"
    },
    {
        "id": "🤽🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤽🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤽🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤽🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤽🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤽‍♂️",
        "name": ":man_playing_water_polo:"
    },
    {
        "id": "🤽‍♂",
        "name": ":man_playing_water_polo:"
    },
    {
        "id": "🤽🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤽🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤽🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤽🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤽🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤽🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤽🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤽🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤽🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤽🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤽‍♀️",
        "name": ":woman_playing_water_polo:"
    },
    {
        "id": "🤽‍♀",
        "name": ":woman_playing_water_polo:"
    },
    {
        "id": "🤽🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤽🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤽🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤽🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤽🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤽🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤽🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤽🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤽🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤽🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤾",
        "name": ":person_playing_handball:"
    },
    {
        "id": "🤾🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤾🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤾🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤾🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤾🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤾‍♂️",
        "name": ":man_playing_handball:"
    },
    {
        "id": "🤾‍♂",
        "name": ":man_playing_handball:"
    },
    {
        "id": "🤾🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤾🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤾🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤾🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤾🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤾🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤾🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤾🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤾🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤾🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤾‍♀️",
        "name": ":woman_playing_handball:"
    },
    {
        "id": "🤾‍♀",
        "name": ":woman_playing_handball:"
    },
    {
        "id": "🤾🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤾🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤾🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤾🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤾🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤾🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤾🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤾🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤾🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤾🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤹",
        "name": ":person_juggling:"
    },
    {
        "id": "🤹🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤹🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤹🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤹🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤹🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤹‍♂️",
        "name": ":man_juggling:"
    },
    {
        "id": "🤹‍♂",
        "name": ":man_juggling:"
    },
    {
        "id": "🤹🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤹🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤹🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤹🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤹🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤹🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤹🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤹🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤹🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤹🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤹‍♀️",
        "name": ":woman_juggling:"
    },
    {
        "id": "🤹‍♀",
        "name": ":woman_juggling:"
    },
    {
        "id": "🤹🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤹🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🤹🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤹🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🤹🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤹🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🤹🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤹🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🤹🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🤹🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧘",
        "name": ":person_in_lotus_position:"
    },
    {
        "id": "🧘🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧘🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧘🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧘🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧘🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧘‍♂️",
        "name": ":man_in_lotus_position:"
    },
    {
        "id": "🧘‍♂",
        "name": ":man_in_lotus_position:"
    },
    {
        "id": "🧘🏻‍♂️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧘🏻‍♂",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧘🏼‍♂️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧘🏼‍♂",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧘🏽‍♂️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧘🏽‍♂",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧘🏾‍♂️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧘🏾‍♂",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧘🏿‍♂️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧘🏿‍♂",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧘‍♀️",
        "name": ":woman_in_lotus_position:"
    },
    {
        "id": "🧘‍♀",
        "name": ":woman_in_lotus_position:"
    },
    {
        "id": "🧘🏻‍♀️",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧘🏻‍♀",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧘🏼‍♀️",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧘🏼‍♀",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧘🏽‍♀️",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧘🏽‍♀",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧘🏾‍♀️",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧘🏾‍♀",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧘🏿‍♀️",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧘🏿‍♀",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🛌🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🛌🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🛌🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🛌🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🛌🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🧑‍🤝‍🧑",
        "name": ":people_holding_hands:"
    },
    {
        "id": "🧑🏻‍🤝‍🧑🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🧑🏻‍🤝‍🧑🏼",
        "name": ":light_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏻‍🤝‍🧑🏽",
        "name": ":light_skin_tone_medium_skin_tone:"
    },
    {
        "id": "🧑🏻‍🤝‍🧑🏾",
        "name": ":light_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏻‍🤝‍🧑🏿",
        "name": ":light_skin_tone_dark_skin_tone:"
    },
    {
        "id": "🧑🏼‍🤝‍🧑🏻",
        "name": ":mediumlight_skin_tone_light_skin_tone:"
    },
    {
        "id": "🧑🏼‍🤝‍🧑🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏼‍🤝‍🧑🏽",
        "name": ":mediumlight_skin_tone_medium_skin_tone:"
    },
    {
        "id": "🧑🏼‍🤝‍🧑🏾",
        "name": ":mediumlight_skin_tone_medium-dark_skin_tone:"
    },
    {
        "id": "🧑🏼‍🤝‍🧑🏿",
        "name": ":mediumlight_skin_tone_dark_skin_tone:"
    },
    {
        "id": "🧑🏽‍🤝‍🧑🏻",
        "name": ":medium_skin_tone_light_skin_tone:"
    },
    {
        "id": "🧑🏽‍🤝‍🧑🏼",
        "name": ":medium_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏽‍🤝‍🧑🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🧑🏽‍🤝‍🧑🏾",
        "name": ":medium_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏽‍🤝‍🧑🏿",
        "name": ":medium_skin_tone_dark_skin_tone:"
    },
    {
        "id": "🧑🏾‍🤝‍🧑🏻",
        "name": ":mediumdark_skin_tone_light_skin_tone:"
    },
    {
        "id": "🧑🏾‍🤝‍🧑🏼",
        "name": ":mediumdark_skin_tone_medium-light_skin_tone:"
    },
    {
        "id": "🧑🏾‍🤝‍🧑🏽",
        "name": ":mediumdark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "🧑🏾‍🤝‍🧑🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏾‍🤝‍🧑🏿",
        "name": ":mediumdark_skin_tone_dark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🤝‍🧑🏻",
        "name": ":dark_skin_tone_light_skin_tone:"
    },
    {
        "id": "🧑🏿‍🤝‍🧑🏼",
        "name": ":dark_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "🧑🏿‍🤝‍🧑🏽",
        "name": ":dark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "🧑🏿‍🤝‍🧑🏾",
        "name": ":dark_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "🧑🏿‍🤝‍🧑🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👭🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👩🏼",
        "name": ":light_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👩🏽",
        "name": ":light_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👩🏾",
        "name": ":light_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👩🏿",
        "name": ":light_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👩🏻",
        "name": ":mediumlight_skin_tone_light_skin_tone:"
    },
    {
        "id": "👭🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👩🏽",
        "name": ":mediumlight_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👩🏾",
        "name": ":mediumlight_skin_tone_medium-dark_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👩🏿",
        "name": ":mediumlight_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👩🏻",
        "name": ":medium_skin_tone_light_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👩🏼",
        "name": ":medium_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👭🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👩🏾",
        "name": ":medium_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👩🏿",
        "name": ":medium_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👩🏻",
        "name": ":mediumdark_skin_tone_light_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👩🏼",
        "name": ":mediumdark_skin_tone_medium-light_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👩🏽",
        "name": ":mediumdark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👭🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👩🏿",
        "name": ":mediumdark_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👩🏻",
        "name": ":dark_skin_tone_light_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👩🏼",
        "name": ":dark_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👩🏽",
        "name": ":dark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👩🏾",
        "name": ":dark_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👭🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👫🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👨🏼",
        "name": ":light_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👨🏽",
        "name": ":light_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👨🏾",
        "name": ":light_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👩🏻‍🤝‍👨🏿",
        "name": ":light_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👨🏻",
        "name": ":mediumlight_skin_tone_light_skin_tone:"
    },
    {
        "id": "👫🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👨🏽",
        "name": ":mediumlight_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👨🏾",
        "name": ":mediumlight_skin_tone_medium-dark_skin_tone:"
    },
    {
        "id": "👩🏼‍🤝‍👨🏿",
        "name": ":mediumlight_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👨🏻",
        "name": ":medium_skin_tone_light_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👨🏼",
        "name": ":medium_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👫🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👨🏾",
        "name": ":medium_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👩🏽‍🤝‍👨🏿",
        "name": ":medium_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👨🏻",
        "name": ":mediumdark_skin_tone_light_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👨🏼",
        "name": ":mediumdark_skin_tone_medium-light_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👨🏽",
        "name": ":mediumdark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👫🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👩🏾‍🤝‍👨🏿",
        "name": ":mediumdark_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👨🏻",
        "name": ":dark_skin_tone_light_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👨🏼",
        "name": ":dark_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👨🏽",
        "name": ":dark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👩🏿‍🤝‍👨🏾",
        "name": ":dark_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👫🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👬🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "👨🏻‍🤝‍👨🏼",
        "name": ":light_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👨🏻‍🤝‍👨🏽",
        "name": ":light_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👨🏻‍🤝‍👨🏾",
        "name": ":light_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👨🏻‍🤝‍👨🏿",
        "name": ":light_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👨🏼‍🤝‍👨🏻",
        "name": ":mediumlight_skin_tone_light_skin_tone:"
    },
    {
        "id": "👬🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "👨🏼‍🤝‍👨🏽",
        "name": ":mediumlight_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👨🏼‍🤝‍👨🏾",
        "name": ":mediumlight_skin_tone_medium-dark_skin_tone:"
    },
    {
        "id": "👨🏼‍🤝‍👨🏿",
        "name": ":mediumlight_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👨🏽‍🤝‍👨🏻",
        "name": ":medium_skin_tone_light_skin_tone:"
    },
    {
        "id": "👨🏽‍🤝‍👨🏼",
        "name": ":medium_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👬🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "👨🏽‍🤝‍👨🏾",
        "name": ":medium_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👨🏽‍🤝‍👨🏿",
        "name": ":medium_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👨🏾‍🤝‍👨🏻",
        "name": ":mediumdark_skin_tone_light_skin_tone:"
    },
    {
        "id": "👨🏾‍🤝‍👨🏼",
        "name": ":mediumdark_skin_tone_medium-light_skin_tone:"
    },
    {
        "id": "👨🏾‍🤝‍👨🏽",
        "name": ":mediumdark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👬🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "👨🏾‍🤝‍👨🏿",
        "name": ":mediumdark_skin_tone_dark_skin_tone:"
    },
    {
        "id": "👨🏿‍🤝‍👨🏻",
        "name": ":dark_skin_tone_light_skin_tone:"
    },
    {
        "id": "👨🏿‍🤝‍👨🏼",
        "name": ":dark_skin_tone_mediumlight_skin_tone:"
    },
    {
        "id": "👨🏿‍🤝‍👨🏽",
        "name": ":dark_skin_tone_medium_skin_tone:"
    },
    {
        "id": "👨🏿‍🤝‍👨🏾",
        "name": ":dark_skin_tone_mediumdark_skin_tone:"
    },
    {
        "id": "👬🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "👩‍❤️‍💋‍👨",
        "name": ":woman_man:"
    },
    {
        "id": "👩‍❤‍💋‍👨",
        "name": ":woman_man:"
    },
    {
        "id": "👨‍❤‍💋‍👨",
        "name": ":man_man:"
    },
    {
        "id": "👩‍❤‍💋‍👩",
        "name": ":woman_woman:"
    },
    {
        "id": "👩‍❤️‍👨",
        "name": ":woman_man:"
    },
    {
        "id": "👩‍❤‍👨",
        "name": ":woman_man:"
    },
    {
        "id": "👨‍❤‍👨",
        "name": ":man_man:"
    },
    {
        "id": "👩‍❤‍👩",
        "name": ":woman_woman:"
    },
    {
        "id": "👨‍👩‍👦",
        "name": ":man_woman_boy:"
    },
    {
        "id": "🗣️",
        "name": ":speaking_head:"
    },
    {
        "id": "🏻",
        "name": ":light_skin_tone:"
    },
    {
        "id": "🏼",
        "name": ":mediumlight_skin_tone:"
    },
    {
        "id": "🏽",
        "name": ":medium_skin_tone:"
    },
    {
        "id": "🏾",
        "name": ":mediumdark_skin_tone:"
    },
    {
        "id": "🏿",
        "name": ":dark_skin_tone:"
    },
    {
        "id": "🦰",
        "name": ":red_hair:"
    },
    {
        "id": "🦱",
        "name": ":curly_hair:"
    },
    {
        "id": "🦳",
        "name": ":white_hair:"
    },
    {
        "id": "🦲",
        "name": ":bald:"
    },
    {
        "id": "🦍",
        "name": ":gorilla:"
    },
    {
        "id": "🦧",
        "name": ":orangutan:"
    },
    {
        "id": "🦮",
        "name": ":guide_dog:"
    },
    {
        "id": "🐕‍🦺",
        "name": ":service_dog:"
    },
    {
        "id": "🦊",
        "name": ":fox:"
    },
    {
        "id": "🦝",
        "name": ":raccoon:"
    },
    {
        "id": "🦓",
        "name": ":zebra:"
    },
    {
        "id": "🦌",
        "name": ":deer:"
    },
    {
        "id": "🦙",
        "name": ":llama:"
    },
    {
        "id": "🦒",
        "name": ":giraffe:"
    },
    {
        "id": "🦏",
        "name": ":rhinoceros:"
    },
    {
        "id": "🦛",
        "name": ":hippopotamus:"
    },
    {
        "id": "🐿️",
        "name": ":chipmunk:"
    },
    {
        "id": "🦔",
        "name": ":hedgehog:"
    },
    {
        "id": "🦇",
        "name": ":bat:"
    },
    {
        "id": "🦥",
        "name": ":sloth:"
    },
    {
        "id": "🦦",
        "name": ":otter:"
    },
    {
        "id": "🦨",
        "name": ":skunk:"
    },
    {
        "id": "🦘",
        "name": ":kangaroo:"
    },
    {
        "id": "🦡",
        "name": ":badger:"
    },
    {
        "id": "🕊️",
        "name": ":dove:"
    },
    {
        "id": "🦅",
        "name": ":eagle:"
    },
    {
        "id": "🦆",
        "name": ":duck:"
    },
    {
        "id": "🦢",
        "name": ":swan:"
    },
    {
        "id": "🦉",
        "name": ":owl:"
    },
    {
        "id": "🦩",
        "name": ":flamingo:"
    },
    {
        "id": "🦚",
        "name": ":peacock:"
    },
    {
        "id": "🦜",
        "name": ":parrot:"
    },
    {
        "id": "🦎",
        "name": ":lizard:"
    },
    {
        "id": "🦕",
        "name": ":sauropod:"
    },
    {
        "id": "🦖",
        "name": ":TRex:"
    },
    {
        "id": "🦈",
        "name": ":shark:"
    },
    {
        "id": "🦋",
        "name": ":butterfly:"
    },
    {
        "id": "🦗",
        "name": ":cricket:"
    },
    {
        "id": "🕷️",
        "name": ":spider:"
    },
    {
        "id": "🕸️",
        "name": ":spider_web:"
    },
    {
        "id": "🦟",
        "name": ":mosquito:"
    },
    {
        "id": "🦠",
        "name": ":microbe:"
    },
    {
        "id": "🏵️",
        "name": ":rosette:"
    },
    {
        "id": "🥀",
        "name": ":wilted_flower:"
    },
    {
        "id": "☘️",
        "name": ":shamrock:"
    },
    {
        "id": "🥭",
        "name": ":mango:"
    },
    {
        "id": "🥝",
        "name": ":kiwi_fruit:"
    },
    {
        "id": "🥥",
        "name": ":coconut:"
    },
    {
        "id": "🥑",
        "name": ":avocado:"
    },
    {
        "id": "🥔",
        "name": ":potato:"
    },
    {
        "id": "🥕",
        "name": ":carrot:"
    },
    {
        "id": "🌶️",
        "name": ":hot_pepper:"
    },
    {
        "id": "🥒",
        "name": ":cucumber:"
    },
    {
        "id": "🥬",
        "name": ":leafy_green:"
    },
    {
        "id": "🥦",
        "name": ":broccoli:"
    },
    {
        "id": "🧄",
        "name": ":garlic:"
    },
    {
        "id": "🧅",
        "name": ":onion:"
    },
    {
        "id": "🥜",
        "name": ":peanuts:"
    },
    {
        "id": "🥐",
        "name": ":croissant:"
    },
    {
        "id": "🥖",
        "name": ":baguette_bread:"
    },
    {
        "id": "🥨",
        "name": ":pretzel:"
    },
    {
        "id": "🥯",
        "name": ":bagel:"
    },
    {
        "id": "🥞",
        "name": ":pancakes:"
    },
    {
        "id": "🧇",
        "name": ":waffle:"
    },
    {
        "id": "🥩",
        "name": ":cut_of_meat:"
    },
    {
        "id": "🥓",
        "name": ":bacon:"
    },
    {
        "id": "🥪",
        "name": ":sandwich:"
    },
    {
        "id": "🥙",
        "name": ":stuffed_flatbread:"
    },
    {
        "id": "🧆",
        "name": ":falafel:"
    },
    {
        "id": "🥘",
        "name": ":shallow_pan_of_food:"
    },
    {
        "id": "🥣",
        "name": ":bowl_with_spoon:"
    },
    {
        "id": "🥗",
        "name": ":green_salad:"
    },
    {
        "id": "🧈",
        "name": ":butter:"
    },
    {
        "id": "🧂",
        "name": ":salt:"
    },
    {
        "id": "🥫",
        "name": ":canned_food:"
    },
    {
        "id": "🥮",
        "name": ":moon_cake:"
    },
    {
        "id": "🥟",
        "name": ":dumpling:"
    },
    {
        "id": "🥠",
        "name": ":fortune_cookie:"
    },
    {
        "id": "🥡",
        "name": ":takeout_box:"
    },
    {
        "id": "🦞",
        "name": ":lobster:"
    },
    {
        "id": "🦐",
        "name": ":shrimp:"
    },
    {
        "id": "🦑",
        "name": ":squid:"
    },
    {
        "id": "🦪",
        "name": ":oyster:"
    },
    {
        "id": "🧁",
        "name": ":cupcake:"
    },
    {
        "id": "🥧",
        "name": ":pie:"
    },
    {
        "id": "🥛",
        "name": ":glass_of_milk:"
    },
    {
        "id": "🥂",
        "name": ":clinking_glasses:"
    },
    {
        "id": "🥃",
        "name": ":tumbler_glass:"
    },
    {
        "id": "🥤",
        "name": ":cup_with_straw:"
    },
    {
        "id": "🧃",
        "name": ":beverage_box:"
    },
    {
        "id": "🧉",
        "name": ":mate:"
    },
    {
        "id": "🧊",
        "name": ":ice:"
    },
    {
        "id": "🥢",
        "name": ":chopsticks:"
    },
    {
        "id": "🍽️",
        "name": ":fork_and_knife_with_plate:"
    },
    {
        "id": "🥄",
        "name": ":spoon:"
    },
    {
        "id": "🗺️",
        "name": ":world_map:"
    },
    {
        "id": "🧭",
        "name": ":compass:"
    },
    {
        "id": "🏔️",
        "name": ":snowcapped_mountain:"
    },
    {
        "id": "⛰️",
        "name": ":mountain:"
    },
    {
        "id": "🏕️",
        "name": ":camping:"
    },
    {
        "id": "🏖️",
        "name": ":beach_with_umbrella:"
    },
    {
        "id": "🏜️",
        "name": ":desert:"
    },
    {
        "id": "🏝️",
        "name": ":desert_island:"
    },
    {
        "id": "🏞️",
        "name": ":national_park:"
    },
    {
        "id": "🏟️",
        "name": ":stadium:"
    },
    {
        "id": "🏛️",
        "name": ":classical_building:"
    },
    {
        "id": "🏗️",
        "name": ":building_construction:"
    },
    {
        "id": "🧱",
        "name": ":brick:"
    },
    {
        "id": "🏘️",
        "name": ":houses:"
    },
    {
        "id": "🏚️",
        "name": ":derelict_house:"
    },
    {
        "id": "🛕",
        "name": ":hindu_temple:"
    },
    {
        "id": "⛩️",
        "name": ":shinto_shrine:"
    },
    {
        "id": "🏙️",
        "name": ":cityscape:"
    },
    {
        "id": "♨",
        "name": ":hot_springs:"
    },
    {
        "id": "🏎️",
        "name": ":racing_car:"
    },
    {
        "id": "🏍️",
        "name": ":motorcycle:"
    },
    {
        "id": "🛵",
        "name": ":motor_scooter:"
    },
    {
        "id": "🦽",
        "name": ":manual_wheelchair:"
    },
    {
        "id": "🦼",
        "name": ":motorized_wheelchair:"
    },
    {
        "id": "🛺",
        "name": ":auto_rickshaw:"
    },
    {
        "id": "🛴",
        "name": ":kick_scooter:"
    },
    {
        "id": "🛹",
        "name": ":skateboard:"
    },
    {
        "id": "🛣️",
        "name": ":motorway:"
    },
    {
        "id": "🛤️",
        "name": ":railway_track:"
    },
    {
        "id": "🛢️",
        "name": ":oil_drum:"
    },
    {
        "id": "🛶",
        "name": ":canoe:"
    },
    {
        "id": "🛳️",
        "name": ":passenger_ship:"
    },
    {
        "id": "⛴️",
        "name": ":ferry:"
    },
    {
        "id": "🛥️",
        "name": ":motor_boat:"
    },
    {
        "id": "✈",
        "name": ":airplane:"
    },
    {
        "id": "🛩️",
        "name": ":small_airplane:"
    },
    {
        "id": "🪂",
        "name": ":parachute:"
    },
    {
        "id": "🛰️",
        "name": ":satellite:"
    },
    {
        "id": "🛸",
        "name": ":flying_saucer:"
    },
    {
        "id": "🛎️",
        "name": ":bellhop_bell:"
    },
    {
        "id": "🧳",
        "name": ":luggage:"
    },
    {
        "id": "⏱️",
        "name": ":stopwatch:"
    },
    {
        "id": "⏲️",
        "name": ":timer_clock:"
    },
    {
        "id": "🕰️",
        "name": ":mantelpiece_clock:"
    },
    {
        "id": "🌡️",
        "name": ":thermometer:"
    },
    {
        "id": "☀",
        "name": ":sun:"
    },
    {
        "id": "🪐",
        "name": ":ringed_planet:"
    },
    {
        "id": "☁️",
        "name": ":cloud:"
    },
    {
        "id": "⛈️",
        "name": ":cloud_with_lightning_and_rain:"
    },
    {
        "id": "🌤️",
        "name": ":sun_behind_small_cloud:"
    },
    {
        "id": "🌥️",
        "name": ":sun_behind_large_cloud:"
    },
    {
        "id": "🌦️",
        "name": ":sun_behind_rain_cloud:"
    },
    {
        "id": "🌧️",
        "name": ":cloud_with_rain:"
    },
    {
        "id": "🌨️",
        "name": ":cloud_with_snow:"
    },
    {
        "id": "🌩️",
        "name": ":cloud_with_lightning:"
    },
    {
        "id": "🌪️",
        "name": ":tornado:"
    },
    {
        "id": "🌫️",
        "name": ":fog:"
    },
    {
        "id": "🌬️",
        "name": ":wind_face:"
    },
    {
        "id": "☂",
        "name": ":umbrella:"
    },
    {
        "id": "⛱️",
        "name": ":umbrella_on_ground:"
    },
    {
        "id": "❄",
        "name": ":snowflake:"
    },
    {
        "id": "☃",
        "name": ":snowman:"
    },
    {
        "id": "☄️",
        "name": ":comet:"
    },
    {
        "id": "🧨",
        "name": ":firecracker:"
    },
    {
        "id": "🧧",
        "name": ":red_envelope:"
    },
    {
        "id": "🎗️",
        "name": ":reminder_ribbon:"
    },
    {
        "id": "🎟️",
        "name": ":admission_tickets:"
    },
    {
        "id": "🎖️",
        "name": ":military_medal:"
    },
    {
        "id": "🥇",
        "name": ":1st_place_medal:"
    },
    {
        "id": "🥈",
        "name": ":2nd_place_medal:"
    },
    {
        "id": "🥉",
        "name": ":3rd_place_medal:"
    },
    {
        "id": "⚾",
        "name": ":baseball:"
    },
    {
        "id": "🥎",
        "name": ":softball:"
    },
    {
        "id": "🥏",
        "name": ":flying_disc:"
    },
    {
        "id": "🥍",
        "name": ":lacrosse:"
    },
    {
        "id": "🥊",
        "name": ":boxing_glove:"
    },
    {
        "id": "🥋",
        "name": ":martial_arts_uniform:"
    },
    {
        "id": "🥅",
        "name": ":goal_net:"
    },
    {
        "id": "⛸️",
        "name": ":ice_skate:"
    },
    {
        "id": "🤿",
        "name": ":diving_mask:"
    },
    {
        "id": "🛷",
        "name": ":sled:"
    },
    {
        "id": "🥌",
        "name": ":curling_stone:"
    },
    {
        "id": "🪀",
        "name": ":yoyo:"
    },
    {
        "id": "🪁",
        "name": ":kite:"
    },
    {
        "id": "🧿",
        "name": ":nazar_amulet:"
    },
    {
        "id": "🕹️",
        "name": ":joystick:"
    },
    {
        "id": "🧩",
        "name": ":puzzle_piece:"
    },
    {
        "id": "🧸",
        "name": ":teddy_bear:"
    },
    {
        "id": "♟️",
        "name": ":chess_pawn:"
    },
    {
        "id": "♟",
        "name": ":chess_pawn:"
    },
    {
        "id": "🖼️",
        "name": ":framed_picture:"
    },
    {
        "id": "🧵",
        "name": ":thread:"
    },
    {
        "id": "🧶",
        "name": ":yarn:"
    },
    {
        "id": "🕶️",
        "name": ":sunglasses:"
    },
    {
        "id": "🥽",
        "name": ":goggles:"
    },
    {
        "id": "🥼",
        "name": ":lab_coat:"
    },
    {
        "id": "🦺",
        "name": ":safety_vest:"
    },
    {
        "id": "🧣",
        "name": ":scarf:"
    },
    {
        "id": "🧤",
        "name": ":gloves:"
    },
    {
        "id": "🧥",
        "name": ":coat:"
    },
    {
        "id": "🧦",
        "name": ":socks:"
    },
    {
        "id": "🥻",
        "name": ":sari:"
    },
    {
        "id": "🩱",
        "name": ":onepiece_swimsuit:"
    },
    {
        "id": "🩲",
        "name": ":briefs:"
    },
    {
        "id": "🩳",
        "name": ":shorts:"
    },
    {
        "id": "🛍️",
        "name": ":shopping_bags:"
    },
    {
        "id": "🥾",
        "name": ":hiking_boot:"
    },
    {
        "id": "🥿",
        "name": ":flat_shoe:"
    },
    {
        "id": "🩰",
        "name": ":ballet_shoes:"
    },
    {
        "id": "🧢",
        "name": ":billed_cap:"
    },
    {
        "id": "⛑️",
        "name": ":rescue_worker’s_helmet:"
    },
    {
        "id": "🎙️",
        "name": ":studio_microphone:"
    },
    {
        "id": "🎚️",
        "name": ":level_slider:"
    },
    {
        "id": "🎛️",
        "name": ":control_knobs:"
    },
    {
        "id": "🪕",
        "name": ":banjo:"
    },
    {
        "id": "☎",
        "name": ":telephone:"
    },
    {
        "id": "🖥️",
        "name": ":desktop_computer:"
    },
    {
        "id": "🖨️",
        "name": ":printer:"
    },
    {
        "id": "⌨️",
        "name": ":keyboard:"
    },
    {
        "id": "🖱️",
        "name": ":computer_mouse:"
    },
    {
        "id": "🖲️",
        "name": ":trackball:"
    },
    {
        "id": "🧮",
        "name": ":abacus:"
    },
    {
        "id": "🎞️",
        "name": ":film_frames:"
    },
    {
        "id": "📽️",
        "name": ":film_projector:"
    },
    {
        "id": "🕯️",
        "name": ":candle:"
    },
    {
        "id": "🪔",
        "name": ":diya_lamp:"
    },
    {
        "id": "🗞️",
        "name": ":rolledup_newspaper:"
    },
    {
        "id": "🏷️",
        "name": ":label:"
    },
    {
        "id": "🧾",
        "name": ":receipt:"
    },
    {
        "id": "✉",
        "name": ":envelope:"
    },
    {
        "id": "🗳️",
        "name": ":ballot_box_with_ballot:"
    },
    {
        "id": "✏️",
        "name": ":pencil:"
    },
    {
        "id": "✒️",
        "name": ":black_nib:"
    },
    {
        "id": "🖋️",
        "name": ":fountain_pen:"
    },
    {
        "id": "🖊️",
        "name": ":pen:"
    },
    {
        "id": "🖌️",
        "name": ":paintbrush:"
    },
    {
        "id": "🖍️",
        "name": ":crayon:"
    },
    {
        "id": "🗂️",
        "name": ":card_index_dividers:"
    },
    {
        "id": "🗒️",
        "name": ":spiral_notepad:"
    },
    {
        "id": "🗓️",
        "name": ":spiral_calendar:"
    },
    {
        "id": "🖇️",
        "name": ":linked_paperclips:"
    },
    {
        "id": "✂",
        "name": ":scissors:"
    },
    {
        "id": "🗃️",
        "name": ":card_file_box:"
    },
    {
        "id": "🗄️",
        "name": ":file_cabinet:"
    },
    {
        "id": "🗑️",
        "name": ":wastebasket:"
    },
    {
        "id": "🗝️",
        "name": ":old_key:"
    },
    {
        "id": "🪓",
        "name": ":axe:"
    },
    {
        "id": "⛏️",
        "name": ":pick:"
    },
    {
        "id": "⚒️",
        "name": ":hammer_and_pick:"
    },
    {
        "id": "🛠️",
        "name": ":hammer_and_wrench:"
    },
    {
        "id": "🗡️",
        "name": ":dagger:"
    },
    {
        "id": "⚔️",
        "name": ":crossed_swords:"
    },
    {
        "id": "🛡️",
        "name": ":shield:"
    },
    {
        "id": "⚙️",
        "name": ":gear:"
    },
    {
        "id": "🗜️",
        "name": ":clamp:"
    },
    {
        "id": "⚖️",
        "name": ":balance_scale:"
    },
    {
        "id": "🦯",
        "name": ":probing_cane:"
    },
    {
        "id": "⛓️",
        "name": ":chains:"
    },
    {
        "id": "🧰",
        "name": ":toolbox:"
    },
    {
        "id": "🧲",
        "name": ":magnet:"
    },
    {
        "id": "⚗️",
        "name": ":alembic:"
    },
    {
        "id": "🧪",
        "name": ":test_tube:"
    },
    {
        "id": "🧫",
        "name": ":petri_dish:"
    },
    {
        "id": "🧬",
        "name": ":dna:"
    },
    {
        "id": "🩸",
        "name": ":drop_of_blood:"
    },
    {
        "id": "🩹",
        "name": ":adhesive_bandage:"
    },
    {
        "id": "🩺",
        "name": ":stethoscope:"
    },
    {
        "id": "🛏️",
        "name": ":bed:"
    },
    {
        "id": "🛋️",
        "name": ":couch_and_lamp:"
    },
    {
        "id": "🪑",
        "name": ":chair:"
    },
    {
        "id": "🪒",
        "name": ":razor:"
    },
    {
        "id": "🧴",
        "name": ":lotion_bottle:"
    },
    {
        "id": "🧷",
        "name": ":safety_pin:"
    },
    {
        "id": "🧹",
        "name": ":broom:"
    },
    {
        "id": "🧺",
        "name": ":basket:"
    },
    {
        "id": "🧻",
        "name": ":roll_of_paper:"
    },
    {
        "id": "🧼",
        "name": ":soap:"
    },
    {
        "id": "🧽",
        "name": ":sponge:"
    },
    {
        "id": "🧯",
        "name": ":fire_extinguisher:"
    },
    {
        "id": "🛒",
        "name": ":shopping_cart:"
    },
    {
        "id": "⚰️",
        "name": ":coffin:"
    },
    {
        "id": "⚱️",
        "name": ":funeral_urn:"
    },
    {
        "id": "⚠",
        "name": ":warning:"
    },
    {
        "id": "☢️",
        "name": ":radioactive:"
    },
    {
        "id": "☣️",
        "name": ":biohazard:"
    },
    {
        "id": "⬆",
        "name": ":up_arrow:"
    },
    {
        "id": "↗",
        "name": ":upright_arrow:"
    },
    {
        "id": "➡️",
        "name": ":right_arrow:"
    },
    {
        "id": "↘",
        "name": ":downright_arrow:"
    },
    {
        "id": "⬇",
        "name": ":down_arrow:"
    },
    {
        "id": "↙",
        "name": ":downleft_arrow:"
    },
    {
        "id": "⬅",
        "name": ":left_arrow:"
    },
    {
        "id": "↖️",
        "name": ":upleft_arrow:"
    },
    {
        "id": "↕️",
        "name": ":updown_arrow:"
    },
    {
        "id": "↩",
        "name": ":right_arrow_curving_left:"
    },
    {
        "id": "↪",
        "name": ":left_arrow_curving_right:"
    },
    {
        "id": "⤴",
        "name": ":right_arrow_curving_up:"
    },
    {
        "id": "⤵",
        "name": ":right_arrow_curving_down:"
    },
    {
        "id": "⚛️",
        "name": ":atom_symbol:"
    },
    {
        "id": "🕉️",
        "name": ":om:"
    },
    {
        "id": "✡",
        "name": ":star_of_David:"
    },
    {
        "id": "☸️",
        "name": ":wheel_of_dharma:"
    },
    {
        "id": "☯️",
        "name": ":yin_yang:"
    },
    {
        "id": "✝",
        "name": ":latin_cross:"
    },
    {
        "id": "☦️",
        "name": ":orthodox_cross:"
    },
    {
        "id": "☪️",
        "name": ":star_and_crescent:"
    },
    {
        "id": "☮️",
        "name": ":peace_symbol:"
    },
    {
        "id": "▶",
        "name": ":play_button:"
    },
    {
        "id": "⏭️",
        "name": ":next_track_button:"
    },
    {
        "id": "⏯️",
        "name": ":play_or_pause_button:"
    },
    {
        "id": "◀",
        "name": ":reverse_button:"
    },
    {
        "id": "⏮️",
        "name": ":last_track_button:"
    },
    {
        "id": "⏸️",
        "name": ":pause_button:"
    },
    {
        "id": "⏹️",
        "name": ":stop_button:"
    },
    {
        "id": "⏺️",
        "name": ":record_button:"
    },
    {
        "id": "⏏️",
        "name": ":eject_button:"
    },
    {
        "id": "⏏",
        "name": ":eject_button:"
    },
    {
        "id": "♀️",
        "name": ":female_sign:"
    },
    {
        "id": "♀",
        "name": ":female_sign:"
    },
    {
        "id": "♂️",
        "name": ":male_sign:"
    },
    {
        "id": "♂",
        "name": ":male_sign:"
    },
    {
        "id": "⚕️",
        "name": ":medical_symbol:"
    },
    {
        "id": "⚕",
        "name": ":medical_symbol:"
    },
    {
        "id": "♾️",
        "name": ":infinity:"
    },
    {
        "id": "♾",
        "name": ":infinity:"
    },
    {
        "id": "♻️",
        "name": ":recycling_symbol:"
    },
    {
        "id": "⚜️",
        "name": ":fleurde-lis:"
    },
    {
        "id": "☑",
        "name": ":check_box_with_check:"
    },
    {
        "id": "✔",
        "name": ":check_mark:"
    },
    {
        "id": "✖",
        "name": ":multiplication_sign:"
    },
    {
        "id": "〽",
        "name": ":part_alternation_mark:"
    },
    {
        "id": "✳",
        "name": ":eightspoked_asterisk:"
    },
    {
        "id": "✴",
        "name": ":eightpointed_star:"
    },
    {
        "id": "❇",
        "name": ":sparkle:"
    },
    {
        "id": "‼",
        "name": ":double_exclamation_mark:"
    },
    {
        "id": "⁉",
        "name": ":exclamation_question_mark:"
    },
    {
        "id": "〰️",
        "name": ":wavy_dash:"
    },
    {
        "id": "#️⃣",
        "name": ":#:"
    },
    {
        "id": "🅰",
        "name": ":A_button_blood_type:"
    },
    {
        "id": "🅱",
        "name": ":B_button_blood_type:"
    },
    {
        "id": "ℹ️",
        "name": ":information:"
    },
    {
        "id": "Ⓜ",
        "name": ":circled_M:"
    },
    {
        "id": "🅾",
        "name": ":O_button_blood_type:"
    },
    {
        "id": "🅿",
        "name": ":P_button:"
    },
    {
        "id": "🈂",
        "name": ":Japanese_service_charge_button:"
    },
    {
        "id": "🈷️",
        "name": ":Japanese_monthly_amount_button:"
    },
    {
        "id": "🈯",
        "name": ":Japanese_reserved_button:"
    },
    {
        "id": "🈚",
        "name": ":Japanese_free_of_charge_button:"
    },
    {
        "id": "㊗️",
        "name": ":Japanese_congratulations_button:"
    },
    {
        "id": "㊙️",
        "name": ":Japanese_secret_button:"
    },
    {
        "id": "🟠",
        "name": ":orange_circle:"
    },
    {
        "id": "🟡",
        "name": ":yellow_circle:"
    },
    {
        "id": "🟢",
        "name": ":green_circle:"
    },
    {
        "id": "🟣",
        "name": ":purple_circle:"
    },
    {
        "id": "🟤",
        "name": ":brown_circle:"
    },
    {
        "id": "🟥",
        "name": ":red_square:"
    },
    {
        "id": "🟧",
        "name": ":orange_square:"
    },
    {
        "id": "🟨",
        "name": ":yellow_square:"
    },
    {
        "id": "🟩",
        "name": ":green_square:"
    },
    {
        "id": "🟦",
        "name": ":blue_square:"
    },
    {
        "id": "▪",
        "name": ":black_small_square:"
    },
    {
        "id": "▫️",
        "name": ":white_small_square:"
    },
    {
        "id": "🏳️",
        "name": ":white_flag:"
    },
    {
        "id": "🏳️‍🌈",
        "name": ":rainbow_flag:"
    },
    {
        "id": "🏴‍☠️",
        "name": ":pirate_flag:"
    },
    {
        "id": "🏴‍☠",
        "name": ":pirate_flag:"
    },
    {
        "id": "🇺🇳",
        "name": ":United_Nations:"
    },
    {
        "id": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
        "name": ":England:"
    },
    {
        "id": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
        "name": ":Scotland:"
    },
    {
        "id": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
        "name": ":wales:"
    }
];

export default emoji_list;
import { Component } from "react";
import { MEDIA_BASE, RawApiResponse, RawApiSearch, UserData } from "../helpers/Constants";
import request from "../helpers/Request";
import GetPath from "../helpers/GetPath";

interface SearchState {

    open: boolean,
    query: string,
    results: UserData[],
    loaded: boolean,
    timer: NodeJS.Timeout | null

}

export default class Search extends Component<any, SearchState> {

    constructor(opts: any) {

        super(opts);
        this.state = {
            open: false,
            query: '',
            results: [],
            loaded: true,
            timer: null,
        }

    }

    componentDidMount() {
        document.body.addEventListener('click', this.close);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.close);
    }

    close = (e: any) => {

        let from_menu = GetPath(e.target).find(e => e.className === "search open");
        if (from_menu) return;

        this.setState({ open: false });

    }

    on_change = (event: any) => {

        this.setState({ open: event.target.value.length > 0, query: event.target.value });

        if (this.state.timer) clearTimeout(this.state.timer);
        if (event.target.value.length === 0) return;

        this.setState({ loaded: false, timer: setTimeout(this.do_search, 750) });

    }

    do_search = () => {

        request('/search', { query: this.state.query })
            .then((response: RawApiResponse) => {
                this.setState({ loaded: true, results: (response as RawApiSearch).users })
            })
            .catch();

    }

    format_results = () => {

        if (this.state.results.length === 0 && this.state.loaded) {

            return (
                <div className="result none">
                    <div className="content">
                        <h2>No results found.</h2>
                    </div>
                </div>
            )

        }

        return this.state.results.map((user, index) => {

            return (

                <div className="result" onClick={() => window.location.href = `/user/${user.username}`} key={index}>
                    <img src={MEDIA_BASE + '/' + (user.meta.avatar || 'defaults/profile.png')} alt={`Avatar of ${user.username}`} />
                    <div className="content">
                        <h2>{user.display_name}</h2>
                        <h3>@{user.username}</h3>
                    </div>
                </div>

            )

        })

    }

    render() {

        // Search box
        return (
            <div className={"search" + ((this.state.open && this.state.results.length > 0) ? ' open' : '')}>
                <input type="search" placeholder="Search" onChange={this.on_change} value={this.state.query} autoComplete={'off'} spellCheck={false} />
                <div className="results">
                    {this.format_results()}
                </div>
            </div>
        );

    }

}
import { Component, FormEvent } from "react";
import { RawApiResponse, RawApiToken } from "../helpers/Constants";
import request from "../helpers/Request";
import Session from "../helpers/UserSession";

interface LoginFormState {
    login: boolean,
    username: string,
    password: string,
    email: string,
    invite: string,
    error: string,
    on_login?: () => void
}

interface LoginFormOpts {
    on_login?: () => void
}

export default class LoginForm extends Component<LoginFormOpts, LoginFormState> {

    constructor(opts: LoginFormOpts) {
        super(opts);

        this.state = {
            login: true,
            username: '',
            password: '',
            email: '',
            invite: '',
            error: '',
            on_login: opts.on_login
        };

    }

    on_submit = (e: FormEvent) => {

        e.preventDefault();

        request(this.state.login ? '/login' : '/register', this.state)
            .then((response: RawApiResponse) => {
                this.setState({ error: '' });
                Session.token = (response as RawApiToken).token;

                if (!this.state.on_login) window.location.pathname = '/dash';
                else this.state.on_login();
                
            })
            .catch((response: RawApiResponse) => {
                this.setState({ error: response.message ?? 'Error contacting server' });
            });

        return false;

    }

    type = (value: 'email' | 'username' | 'password' | 'invite', event: any) => {

        let state: LoginFormState = this.state;
        state[value] = event.target.value;

        this.setState(state);

    }

    flip_type = (e: FormEvent) => {

        e.preventDefault();
        this.setState({ login: !this.state.login });
        return false;

    }

    render() {

        return (
            <form className="login" onSubmit={this.on_submit}>

                <h2 className="danger">{this.state.error}</h2>

                <label htmlFor="username">Username<sup>*</sup></label>
                <input type="text" id="username" autoComplete="off" required={true} value={this.state.username} onChange={(e) => this.type('username', e)} />

                {
                    !this.state.login ? (
                        <>
                            <label htmlFor="email">Email Address<sup>*</sup></label>
                            <input type="email" id="email" required={true} value={this.state.email} onChange={(e) => this.type('email', e)} />

                            <label htmlFor="invite_code">Invite Code<sup>*</sup></label>
                            <input type="text" id="invite_code" required={true} value={this.state.invite} onChange={(e) => this.type('invite', e)} />
                        </>
                    ) : <></>
                }

                <label htmlFor="password">Password<sup>*</sup></label>
                <input type="password" id="password" required={true} value={this.state.password} onChange={(e) => this.type('password', e)} />

                <div className="shared">
                    <button className="main" type="submit">{this.state.login ? "Login" : "Register"}</button>
                    <button onClick={this.flip_type}>{!this.state.login ? "Login" : "Register"}</button>
                </div>

            </form>
        )

    }

}
import { debug, RawApiResponse, RawApiUser, UserData, VERBOSITY } from "./Constants";
import request from "./Request";

class UserSession {
    private _token: string;
    private _user: UserData | null;

    constructor() {
        this._token = window.sessionStorage.getItem('user_token') ?? '';
        this._user = null;

        let root_style = getComputedStyle(document.querySelector(':root') || document.body);

        console.log(`%cHey there!`, `font-family: ${root_style.getPropertyValue('--default-font')};color: ${root_style.getPropertyValue('--primary')};font-size: 1.5rem;font-weight: 800;background: ${root_style.getPropertyValue('--background')};padding-top: 2rem;`);
        console.log(`%cIf you're looking for API or debugging support, head over to our developer reference, /debug. Your current verbosity level is ${VERBOSITY}/2.`, `font-family: ${root_style.getPropertyValue('--default-font')};color: ${root_style.getPropertyValue('--foreground')};font-size: 0.95rem;background: ${root_style.getPropertyValue('--background')};padding-bottom: 2rem;`);
    }

    public login(token: string) {
        this.token = token;
        window.location.pathname = '/dash';
    }

    public logout() {
        this.token = '';
        window.location.pathname = '/';
    }

    public set token(value: string) {
        this._token = value;
        window.sessionStorage.setItem('user_token', this._token);
        debug(`[$] Token updated: ${this._token}`)
    }

    public get token() {
        debug(`[$] Token retrieved`);
        return this._token;
    }

    public set user(user: UserData | null) {
        this._user = user;
        debug(`[$] User set:\n${JSON.stringify(this._user)}`);
    }

    public get user() {
        return this._user;
    }

    public logged_in(): Promise<boolean> {

        return new Promise((resolve) => {

            if (this.token === '') {
                debug("[v] Not logged in: No token stored");
                return resolve(false);
            }

            if (this.user !== null) {
                debug("[^] Logged in: User present");
                return resolve(true);
            }

            request('/self', {})
                .then((response: RawApiResponse) => {
                    this.user = (response as RawApiUser).user;
                    debug('[^] Logged in')
                    return resolve(true);
                })
                .catch(() => {
                    this.token = '';
                    this.user = null;
                    debug('[v] Not logged in')
                    return resolve(false);
                })
        })

    }

    public get has_token(): boolean {
        return this._token !== '';
    }

    public get is_moderator(): boolean {
        return (this.user && this.user.meta.badges.indexOf('MODERATOR') > -1) ?? false;
    }

}

const Session = new UserSession();
Object.seal(Session);

export default Session;
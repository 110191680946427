import { Component } from "react";
import UserMenu from "../components/UserMenu";
import Session from "../helpers/UserSession";
import Search from "../components/Search";
import ImageUpload from "../components/ImageUpload";
import { MEDIA_BASE, RawApiResponse, RawApiUpload } from "../helpers/Constants";

import "../style/upload.css";
import { upload_files } from "../helpers/Request";


interface UploadState {
    working: boolean,
    logged_in: boolean,
    file: any,
    error: string,
    media_url: string
}

export default class Upload extends Component<any, UploadState> {

    constructor(opts: any) {
        super(opts);
        this.state = {
            working: false,
            logged_in: false,
            file: null,
            error: '',
            media_url: '',
        }
    }

    componentDidMount = async () => {
        this.setState({ working: true });
        if (await Session.logged_in()) this.setState({ logged_in: true });
        this.setState({ working: false });
    }

    upload = () => {

        if (!this.state.file) return this.setState({ error: 'No file selected' });
        this.setState({ error: '', media_url: '' });

        upload_files('/upload', [this.state.file])
            .then((response: RawApiResponse) => {
                this.setState({ media_url: MEDIA_BASE + '/' + (response as RawApiUpload).ids[0] });
            })
            .catch((response: RawApiResponse) => {
                this.setState({ error: response.message ?? 'Unable to reach server' });
            })

    }

    form = () => {

        if (!this.state.logged_in) return (
            <div className="panel display">
                <span className="title">No Permission</span>
                <h1>You can't upload media</h1>
                <p>
                    Only logged in users are able to upload media. If you have an account, please <a href="/login">log in</a>.
                </p>
            </div>
        )

        return (
            <div className="upload">
                <h3 className="error">{this.state.error}</h3>
                <ImageUpload file={MEDIA_BASE + '/defaults/banner.png'} on_upload={(f) => this.setState({ file: f })} />
                <div className="controls panel">
                    <button className="button" onClick={() => this.upload()}><i className="fas fa-upload"></i> Upload</button>
                    {this.state.media_url ? <span>View uploaded media: <a href={this.state.media_url + '/low'}>Low quality</a>, <a href={this.state.media_url + '/high'}>High quality</a></span> : <></>}
                </div>
            </div>
        )
    }

    render() {

        if (this.state.working) return <></>;

        return (
            <>
                <header>
                    <div className="layout f-row">
                        <div className="layout sidebar">
                            <h2 className="branding">
                                <img src="/logo.png" alt="crick.yt logo" />
                                <a href="/">Crick.yt</a>
                            </h2>
                        </div>
                        <div className="layout main">
                            <Search />
                        </div>
                        <div className="layout actionbar">
                            <UserMenu />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="layout full-height f-row">
                        <div className="layout sidebar"></div>
                        <div className="layout main full-height left">
                            {this.form()}
                        </div>
                        <div className="layout actionbar"></div>
                    </div>
                </main>
            </>
        )

    }

}
import { API_BASE, debug, RawApiResponse, RawApiResponseError } from "./Constants";
import Session from "./UserSession";

export default function request(endpoint: string, data: Object): Promise<RawApiResponse|RawApiResponseError> {

    return new Promise(async (resolve, reject) => {

        const xhr = new XMLHttpRequest();

        xhr.open("POST", API_BASE + endpoint, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', `Bearer ${Session.token}`);

        xhr.send(JSON.stringify({ ...data }));

        debug(`[>] ${API_BASE + endpoint} ::\n${JSON.stringify(data)}`);
        xhr.onload = (data: any) => {

            let response;

            try { response = JSON.parse(data.target.response); }
            catch (e) { response = data.target.response; }

            debug(`[<] ${API_BASE + endpoint} :: ${data.target.status} ::\n${JSON.stringify(response)}`);

            if (data.target.status >= 200 && data.target.status < 300) resolve(response);
            else reject(typeof(response) === 'object' ? { ...response, code: data.target.status } : response);


        };


    })

}

export function upload_files(endpoint: string, files: any[]): Promise<RawApiResponse|RawApiResponseError> {

    return new Promise(async (resolve, reject) => {

        const form = new FormData();
        files.forEach((file, i) => form.append(i.toString(), file));

        const xhr = new XMLHttpRequest();
        xhr.open("POST", API_BASE + endpoint, true);
        xhr.setRequestHeader('Authorization', `Bearer ${Session.token}`);
        xhr.send(form);

        debug(`[>] ${API_BASE + endpoint} ::\n${JSON.stringify(form)}`);
        xhr.onload = (data: any) => {

            let response;

            try { response = JSON.parse(data.target.response); }
            catch (e) { response = data.target.response; }

            debug(`[<] ${API_BASE + endpoint} :: ${data.target.status} ::\n${JSON.stringify(response)}`);

            if (data.target.status >= 200 && data.target.status < 300) resolve(response);
            else reject(typeof(response) === 'object' ? { ...response, code: data.target.status } : response);


        };


    })

}
import { Helmet } from "react-helmet";

type Metadata = {
    title?: string,
    description?: string,
    image?: string,
}

export default function construct_meta({ title, description, image }: Metadata) {

    title = (title ?? window.location.pathname.slice(1)) + ' | crick.yt';
    description = description ?? 'something something something.... at crick.yt';

    return (
        <Helmet>

            {/* <!-- HTML Meta Tags --> */}
            <title>{title}</title>
            <meta name="description" content={description} />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemProp="name" content={title} />
            <meta itemProp="description" content={description} />
            <meta itemProp="image" content={image} />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={'https://crick.yt' + window.location.pathname} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            {/* <!-- Twitter Meta Tags --> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />

        </Helmet>
    )

}
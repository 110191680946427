import { Component } from "react";
import { Mention, MentionsInput } from 'react-mentions';
import request from "../helpers/Request";
import { RawApiResponse, RawApiSearch } from "../helpers/Constants";
import Emoji from "../helpers/Emoji";
import Replace from "react-string-replace";

interface RichInputState {
    options: { id: string, display: string, username: string | null }[];
    queries: string[];
    value: string;
}

interface RichInputOpts {
    text?: string,
    readonly?: boolean,
    onChange?: (e: any) => void
}

export default class RichInput extends Component<RichInputOpts, RichInputState> {

    constructor(opts: RichInputOpts) {
        super(opts);
        this.state = {
            options: [],
            queries: [],
            value: opts.text ?? ''
        }
    }

    static getDerivedStateFromProps(props: RichInputOpts, state: RichInputState): RichInputState {
        return {
            queries: state.queries,
            options: state.options,
            value: props.text ?? state.value
        }
    }

    fetch_users = (query: string, callback: Function) => {

        if (this.state.queries.indexOf(query) > -1) return callback(this.state.options);
        this.setState({ queries: this.state.queries.concat(query) });
        const pattern = new RegExp(query, "gi");

        request('/search', { query })
            .then((response: RawApiResponse) => {
                let users = (response as RawApiSearch).users.map(user => { return { display: user.display_name, username: user.username, id: user.id } });
                this.setState({ options: this.state.options.concat(users).filter((v, i, a) => i === a.findIndex((v2) => v2.id === v.id)) });
                callback(this.state.options.filter(v => pattern.test(v.display) || pattern.test(v.username ?? '')).slice(0, 5));
            })
            .catch(() => {
                callback([]);
            });
    }

    emoji = (query: string, callback: Function) => {

        if (query.length === 0) return;
        const matches = Emoji
            .filter((e) => e.name.slice(1, -1).toLowerCase().includes(query.toLowerCase()))
            .slice(0, 5);

        return matches

    }

    handle_change = (e: any) => {
        if (this.props.readonly) return;
        this.setState({ value: e.target.value });
        if (this.props.onChange) this.props.onChange(e);
    }

    format_text() {
        return Replace(this.state.value, /<@([0-9]+)>/g, (id) => {
            const user = this.state.options.find(op => op.id === id);
            if (!user) this.fetch_users(id, () => { });
            return <span className="mention" key={id}>{user ? (<a href={`/user/${user.username}`}>{user.display}</a>) : id}</span>
        })
    }

    render() {

        if (!this.props.readonly) return (
            <>

                <MentionsInput
                    className="richtext_field"
                    value={this.state.value}
                    onChange={this.handle_change}
                >

                    <Mention
                        className="mention"
                        trigger="@"
                        data={this.fetch_users}
                        markup="<@__id__>"
                        displayTransform={(id) => {
                            let user = this.state.options.find(op => op.id === id);
                            if (!user) this.fetch_users(id, () => { });
                            return `  ${this.state.options.find(op => op.id === id)?.display ?? 'Unknown User'}  `;
                        }}
                    />

                    <Mention
                        className="emoji"
                        trigger=":"
                        markup="__id__"
                        regex={/($a)/}
                        data={this.emoji}
                    />

                </MentionsInput>

            </>
        )

        return (
            <p className="still_richtext_field">
                {this.format_text()}
            </p>
        )
    }

}
import { Component } from "react";
import Search from "../components/Search";
import UserMenu from "../components/UserMenu";
import Session from "../helpers/UserSession";
import DefaultActions from "../components/DefaultActions";

export default class Dash extends Component {

    componentDidMount = async () => {
        if (!(await Session.logged_in())) return window.location.pathname = '/';
    }

    render() {

        return (
            <>
                <header>
                    <div className="layout f-row">
                        <div className="layout sidebar">
                            <h2 className="branding">
                                <img src="/logo.png" alt="crick.yt logo" />
                                <a href="/">Crick.yt</a>
                            </h2>
                        </div>
                        <div className="layout main">
                            <Search />
                        </div>
                        <div className="layout actionbar">
                            <UserMenu />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="layout full-height f-row">
                        <div className="layout full-height"></div>
                    <div className="layout main"></div>
                        <div className="layout actionbar">
                            <DefaultActions />
                        </div>
                    </div>
                </main>
            </>
        )

    }

}
import { Component } from "react";
import LoginForm from "../components/LoginForm";
import Session from "../helpers/UserSession";
import "../style/forms.css";

export default class Login extends Component {

    componentDidMount = async () => {
        if (await Session.logged_in()) return window.location.pathname = '/dash';
    }

    render() {

        return (
            <>

                <header>
                    <div className="layout f-row">
                        <div className="layout sidebar">
                            <h2 className="branding">
                                <img src="/logo.png" alt="crick.yt logo" />
                                <a href="/">Crick.yt</a>
                            </h2>
                        </div>
                        <div className="layout main"></div>
                        <div className="layout actionbar">
                        </div>
                    </div>
                </header>
                <main>
                    <div className="layout full-height f-row">
                        <div className="layout sidebar"></div>
                        <div className="layout main full-height">

                            <LoginForm />

                        </div>
                        <div className="layout actionbar"></div>
                    </div>
                </main>
            </>
        )

    }

}
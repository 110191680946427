import { Component, FormEvent } from "react";
import { MEDIA_BASE, RawApiResponse, RawApiResponseError, RawApiUser, UserBadge, UserData, UserMeta } from "../helpers/Constants";
import request, { upload_files } from "../helpers/Request";
import Session from "../helpers/UserSession";

import "../style/profile.css"
import "../style/forms.css"
import PopupLogin from "./PopupLogin";
import RichInput from "./RichInput";
import ImageUpload from "./ImageUpload";
import GetPath from "../helpers/GetPath";
import construct_meta from "../helpers/Meta"

interface UserProfileState {
    done: boolean,
    working: boolean,
    user: UserData | null,
    target: string,
    show_login: boolean,
    uploaded_avatar: any | null,
    uploaded_banner: any | null,
    preview_banner: string | null,
    edit: boolean,
    edit_message: string,
    edit_error: boolean,
    edit_badges: UserMeta["badges"],
    edit_fields: {
        avatar: string
        display_name: string,
        username: string,
        bio: string,
    }
}

interface UserProfileOpts {
    target: string
}

export default class UserProfile extends Component<UserProfileOpts, UserProfileState> {

    constructor(opts: UserProfileOpts) {
        super(opts);
        this.state = {
            target: opts.target,
            working: false,
            done: false,
            user: null,
            show_login: false,
            uploaded_avatar: null,
            uploaded_banner: null,
            preview_banner: null,
            edit: false,
            edit_message: '',
            edit_error: false,
            edit_badges: [],
            edit_fields: {
                avatar: '',
                display_name: '',
                username: '',
                bio: '',
            }
        };
    }

    async componentDidMount() {

        document.body.addEventListener('click', this.edit_close);

        if (this.state.done || this.state.working) return;
        this.setState({ working: true });

        await Session.logged_in();
        this.fetch_user();

    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.edit_close);
    }

    fetch_user = () => {

        request('/profile', { identifier: this.state.target })
            .then((response: RawApiResponse) => {

                const user = (response as RawApiUser).user;

                this.setState({
                    user,
                    working: false,
                    done: true,
                    edit_badges: user.meta.badges,
                    edit_fields: {
                        avatar: user.meta.avatar,
                        username: user.username,
                        display_name: user.display_name,
                        bio: (response as RawApiUser).user.meta.bio
                    }
                });

            })
            .catch(() => {
                window.location.pathname = '/404';
            });

    }

    badges = () => {

        return this.state.user?.meta.badges.map(badge => {

            switch (badge) {

                case 'BETA-TESTER':
                    return <div className="badge" key={badge} title="Beta Tester">
                        <i className="fas fa-lightbulb"></i>
                        {/* <span>Beta Tester</span> */}
                    </div>

                case 'DEVELOPER':
                    return <div className="badge" key={badge} title="Developer">
                        <i className="fas fa-hammer"></i>
                        {/* <span>Developer</span> */}
                    </div>

                case 'MODERATOR':
                    return <div className="badge" key={badge} title="Moderator">
                        <i className="fas fa-user-shield"></i>
                        {/* <span>Moderator</span> */}
                    </div>

                default:
                    return <></>

            }

        })

    }

    buttons = () => {

        if (!this.state.user) return <></>;
        const following = Session.user && this.state.user.followers.indexOf(Session.user.id) > -1;
        if (this.state.user.id === Session.user?.id) return (
            <>
                <button className="edit_button" onClick={() => this.setState({ edit: true })}><i className="fas fa-edit"></i> Edit Profile</button>
            </>
        )

        return (
            <>
                {Session.is_moderator ?
                    <button className="edit_button" onClick={() => this.setState({ edit: true })}><i className="fas fa-edit"></i> Edit Profile</button> :
                    <></>
                }
                <button onClick={() => this.follow(!following)} className={following ? 'danger' : ''}><i className={`fas fa-user-${following ? 'minus' : 'plus'}`}></i> {following ? 'Unfollow' : 'Follow'}</button>
                <button className="warning"><i className="far fa-flag"></i> Report</button>
            </>
        )

    }

    handle_change = (field: keyof UserProfileState['edit_fields'], event: any) => {

        let value = event.target.value ?? ''

        if (field === 'bio') {
            while (value.split('\n').length > 5 || value.length > 90) {
                value = value.slice(0, -1);
            };
        }

        let state = this.state.edit_fields;
        state[field] = value;
        this.setState({ edit_fields: state });

    }

    submit_changes = (e: FormEvent) => {

        e.preventDefault();

        (this.state.uploaded_avatar ? upload_files('/upload_avatar', [this.state.uploaded_avatar]) : Promise.resolve())
            .then(() => {
                request('/update_profile', { ...this.state.edit_fields, target: this.state.user?.id, badges: this.state.edit_badges })
                    .then(() => {
                        this.fetch_user();
                        this.setState({ edit_error: false, edit_message: "Updated user!" });
                    })
                    .catch((response: RawApiResponse) => {
                        this.setState({ edit_error: true, edit_message: response.message ?? 'Error contacting server' });
                    })
            })
            .catch((response: RawApiResponse) => {
                this.setState({ edit_error: true, edit_message: response.message ?? 'Error contacting server' });
            })




        return false;

    }

    handle_badge_change = (badge: UserBadge, event: any) => {

        let badges = [...this.state.edit_badges, badge].filter((b, i, a) => a.indexOf(b) === i);
        if (!event.target.checked) {
            badges = badges.filter(b => b !== badge);
        }

        this.setState({ edit_badges: badges });
    }

    edit_badges = () => {

        if (!Session.is_moderator) return;

        return (
            <div className="checkboxes">

                <input type="checkbox" id="beta_tester" checked={this.state.edit_badges.indexOf('BETA-TESTER') > -1} onChange={(e) => this.handle_badge_change('BETA-TESTER', e)} />
                <label htmlFor="beta_tester">Beta Tester</label>

                <input type="checkbox" id="developer" checked={this.state.edit_badges.indexOf('DEVELOPER') > -1} onChange={(e) => this.handle_badge_change('DEVELOPER', e)} />
                <label htmlFor="developer">Developer</label>

                <input type="checkbox" id="moderator" checked={this.state.edit_badges.indexOf('MODERATOR') > -1} onChange={(e) => this.handle_badge_change('MODERATOR', e)} />
                <label htmlFor="moderator">Moderator</label>

            </div>
        );

    }

    edit = () => {

        const user = this.state.user;
        if (!user || !this.state.edit) return <></>;

        return (

            <div className="panel edit_profile">

                <span className="title">
                    Edit Profile
                </span>

                <form onSubmit={this.submit_changes}>

                    {(user.id === Session.user?.id) ? <ImageUpload file={`${MEDIA_BASE}/${user.meta.avatar}`} on_upload={(e) => this.setState({ uploaded_avatar: e })} /> : <></>}

                    <h2 className={this.state.edit_error ? "danger" : "success"}>{this.state.edit_message}</h2>

                    <label htmlFor="display_name">Display Name<sup>*</sup></label>
                    <input type="text" id="display_name" autoComplete="off" required={true} value={this.state.edit_fields.display_name} onChange={(e) => this.handle_change('display_name', e)} />

                    <label htmlFor="username">Username<sup>*</sup></label>
                    <input type="text" id="username" autoComplete="off" required={true} value={this.state.edit_fields.username} onChange={(e) => this.handle_change('username', e)} />

                    <label htmlFor="bio">Bio<sup>*</sup></label>
                    <div className="bio">
                        <RichInput text={this.state.edit_fields.bio} onChange={(e: any) => this.handle_change('bio', e)} />
                    </div>


                    {this.edit_badges()}

                    <br />
                    <button className="main" type="submit">Update</button>

                </form>

            </div>

        )

    }

    edit_close = (e: any) => {

        let from_menu = GetPath(e.target).find(e => e.className === "panel edit_profile" || e.className === "edit_button" || e.className === "richtext_field__suggestions");
        if (from_menu) return;

        this.setState({ edit: false });
    }

    follow = (follow: boolean) => {

        request(`/${follow ? 'follow' : 'unfollow'}`, { target: this.state.user?.id })
            .then(() => this.fetch_user())
            .catch((response: RawApiResponseError) => {
                if (response.code === 401) this.setState({ show_login: true });
            });
    }

    change_banner = (f: any) => {
        this.setState({ uploaded_banner: f, preview_banner: URL.createObjectURL(f) });
        upload_files(`/upload_banner`, [f])
            .then(() => this.fetch_user())
            .catch(() => this.setState({ preview_banner: null, uploaded_banner: null }));
    }

    render() {

        const user = this.state.user;
        if (!this.state.done || !user) return <></>;

        return (
            <>


                {
                    construct_meta({
                        title: user.display_name,
                        description: user.meta.bio ?? `${user.display_name} is on crick.yt!`,
                        // image: 'https://api.crick.yt/card/' + user.username
                    })
                }

                <PopupLogin
                    open={this.state.show_login}
                    user_closed={() => this.setState({ show_login: false })}
                    logged_in={() => window.location.reload()}
                />

                {this.edit()}
                <div className="user_profile">
                    <div className="banner" style={{
                        backgroundImage: `url("${this.state.uploaded_banner ? this.state.preview_banner : (MEDIA_BASE + '/' + (this.state.user?.meta.banner ?? 'defaults/banner.png'))}")`
                    }}>
                        {(user.id === Session.user?.id) ? <ImageUpload on_upload={this.change_banner} /> : <></>}
                        <div className="badges">
                            {
                                user.meta.badges.length !== 0 ?
                                    this.badges() :
                                    <></>
                            }
                        </div>
                    </div>
                    <div className="content">
                        <a href={`/user/${user.username}`}>
                            <img src={`${MEDIA_BASE}/${user.meta.avatar}`} alt={`Avatar of ${user.username}`} />
                        </a>
                        <div className="name">
                            <h2>{user.display_name}</h2>
                            <h3>@{user.username}</h3>
                        </div>

                        <RichInput text={user.meta.bio} readonly={true} />

                        <div className="buttons">{this.buttons()}</div>
                        <ul className="counts">
                            <li>
                                <strong>{user.followers.length}</strong> Followers
                            </li>
                            <li>
                                Following <strong>{user.following.length}</strong>
                            </li>
                            <li>
                                <a href={`/user/${user.username}/media`}><strong>{user.media.length}</strong> Images Uploaded</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }

}

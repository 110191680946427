import { Component } from "react";
import GetPath from "../helpers/GetPath";
import LoginForm from "./LoginForm";

interface PopupLoginState {
    open: boolean;
    user_closed: () => void;
    logged_in: () => void;
}

interface PopupLoginOpts {
    open?: boolean;
    user_closed?: () => void;
    logged_in?: () => void;
}

export default class PopupLogin extends Component<PopupLoginOpts, PopupLoginState> {

    constructor(opts: PopupLoginOpts) {

        super(opts);
        this.state = {
            open: opts.open ?? false,
            user_closed: opts.user_closed ?? (() => { }),
            logged_in: opts.logged_in ?? (() => { })
        }

    }

    componentDidMount() {
        document.body.addEventListener('click', this.close);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.close);
    }

    static getDerivedStateFromProps(props: PopupLoginOpts, state: PopupLoginState): PopupLoginState {
        return {
            open: props.open ?? state.open,
            logged_in: state.logged_in,
            user_closed: state.user_closed
        }
    }

    close = (e?: any) => {

        if (e) {
            let from_menu = GetPath(e.target).find(e => e.className === "panel floating_login");
            if (from_menu) return;
        }

        this.state.user_closed();
    }

    render() {

        if (!this.state.open) return <></>;

        return (

            <div className="floating">
                <div className="panel floating_login">
                    <span className="title">Login</span>
                    <LoginForm on_login={() => { this.close(); this.state.logged_in(); }} />
                </div>
            </div>

        )

    }

}
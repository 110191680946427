import { Component, createRef } from "react";

interface ImageUploadState {
    file?: string,
    preview: string | null
}

interface ImageUploadOpts {
    file?: string,
    on_upload: (path: string) => void
}

export default class ImageUpload extends Component<ImageUploadOpts, ImageUploadState> {
    input_ref: any;

    constructor(opts: ImageUploadOpts) {
        super(opts);
        this.state = {
            file: opts.file,
            preview: null
        }

        this.input_ref = createRef();
    }

    static getDerivedStateFromProps(props: ImageUploadOpts, state: ImageUploadState): ImageUploadState {
        return {
            file: props.file,
            preview: (props.file !== state.file) ? null : state.preview
        }
    }

    handle_upload = (e: any) => {
        this.setState({ preview: URL.createObjectURL(e.target.files[0]) });
        this.props.on_upload(e.target.files[0]);
    }

    render() {
        return (
            <div className="image_upload">
                <div className="inner">
                    <div className="overlay" onClick={() => this.input_ref.current?.click()}>
                        <i className="fas fa-camera"></i>
                    </div>
                    <img className={!this.state.file ? 'hidden' : ''} src={this.state.preview ?? this.state.file} alt="File Upload" onClick={() => this.input_ref.current?.click()} />
                    <input ref={this.input_ref} type="file" onChange={this.handle_upload} accept="image/*" />
                </div>
            </div>
        )
    }

}
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Search from "../components/Search";
import UserMenu from "../components/UserMenu";
import UserProfile from "../components/UserProfile";
import DefaultActions from "../components/DefaultActions";

class Profile extends Component<any, any> {

    render() {

        return (
            <>
                <header>
                    <div className="layout f-row">
                        <div className="layout sidebar">
                            <h2 className="branding">
                                <img src="/logo.png" alt="crick.yt logo" />
                                <a href="/">Crick.yt</a>
                            </h2>
                        </div>
                        <div className="layout main">
                            <Search />
                        </div>
                        <div className="layout actionbar">
                            <UserMenu />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="layout full-height f-row">
                        <div className="layout full-height"></div>
                        <div className="layout main">
                            <UserProfile target={this.props.match.params.user} />
                        </div>
                        <div className="layout actionbar">
                            <DefaultActions />
                        </div>
                    </div>
                </main>
            </>
        )

    }

}

export default withRouter(Profile);
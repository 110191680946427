import { Component } from "react";
import { debug } from "../helpers/Constants";
import themer from "../helpers/Theme";

export default class Responsivity extends Component {

    componentDidMount() {
        window.addEventListener('resize', this.handle_resize);
        this.handle_resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handle_resize);
    }

    handle_resize = () => {

        const ratio = window.innerWidth / window.innerHeight;
        let type: 'phone' | 'tablet' | 'desktop' =
            ratio < 1 ? 'phone' :
                ratio < 1.5 ? 'tablet' :
                    'desktop';

        debug(`[x] Resizing to '${type}' (${ratio.toFixed(3)})`);

        document.body.className = `${type} ${themer.theme}`;

        let sidebar = document.querySelector('main .layout .sidebar');
        if (sidebar) {
            if (type === 'tablet') sidebar.classList.add('mini');
            else sidebar.classList.remove('mini');
        }

    }

    apply_sizing(type: 'phone' | 'tablet' | 'desktop', ratio: number) {
        debug(`[x] Resizing to '${type}' (${ratio})`)
    }

    render() {
        return <></>
    }

}
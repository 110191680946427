export const VERBOSITY: number = Math.min(Math.max(parseInt(sessionStorage.getItem('verbosity') ?? '0'), 0), 2);

export const API_BASE = 'https://crickytapi.tascord.xyz';
export const MEDIA_BASE = 'https://crickytapi.tascord.xyz/media';

export type RawApiResponse = {
    success: boolean,
    message?: string
}

export type RawApiResponseError = (RawApiResponse & {
    code: number
})

export type RawApiToken = (RawApiResponse & {
    token: string
})

export type RawApiSearch = (RawApiResponse & {
    users: UserData[]
})

export type RawApiUpload = (RawApiResponse & {
    ids: string[]
})

export type RawApiMedia = (RawApiResponse & { media: ImageData[] })

export type RawApiUser = (RawApiResponse & { user: UserData })

export function debug(...args: any[]) {
    if (VERBOSITY === 1) console.log(...args);
    if (VERBOSITY === 2) console.trace(...args);
}

export type UserBadge = 'MODERATOR' | 'DEVELOPER' | 'BETA-TESTER';
export type UserMeta = {

    avatar: string,
    banner: string,

    bio: string,
    badges: UserBadge[],

}

export type UserData = {    

    id: string;
    username: string;
    display_name: string;

    followers: string[];
    following: string[];

    pages: string[];
    meta: UserMeta;
    media: string[];

}

export type ImageData = {
    id: string,
    name: string,
    type: string,
    owner: string,
    qualities: string[]
}
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import "./style/main.css";
import "./style/themes.css";

import Home from './pages/Home';
import Login from './pages/Login';
import Dash from './pages/Dash';
import Session from './helpers/UserSession';
import Debug from './pages/Debug';
import Profile from './pages/Profile';
import Upload from './pages/Upload';

import Missing from './pages/Missing';
import Responsivity from './components/Responsivity';
import ProfileMedia from './pages/ProfileMedia';

ReactDOM.render(
  <React.StrictMode>

    <Responsivity />

    <BrowserRouter>
      <Switch>

        <Route path="/register" component={() => <Redirect to="/login" />} />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={() => {

          Session.token = '';
          return <Redirect to="/" />

        }} />


        <Route path="/user/:user" exact component={Profile} />
        <Route path="/user/:user/media" component={ProfileMedia} />

        <Route path="/upload" component={Upload} />
        <Route path="/dash" component={Dash} />
        <Route path="/debug" component={Debug} />
        <Route path="/" exact component={Home} />
        <Route path="/" component={Missing} />

      </Switch>
    </BrowserRouter>

  </React.StrictMode>,
  document.body
);

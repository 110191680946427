import { Component } from "react";
import UserMenu from "../components/UserMenu";
import Search from "../components/Search";

export default class Missing extends Component {

    render() {

        return (
            <>
                <header>
                    <div className="layout f-row">
                        <div className="layout sidebar">
                            <h2 className="branding">
                                <img src="/logo.png" alt="crick.yt logo" />
                                <a href="/">Crick.yt</a>
                            </h2>
                        </div>
                        <div className="layout main">
                            <Search />
                        </div>
                        <div className="layout actionbar">
                            <UserMenu />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="layout full-height f-row">
                        <div className="layout sidebar"></div>
                        <div className="layout main full-height left">
                            <div className="panel display">
                                <span className="title">404</span>
                                <h1>Page not found</h1>
                                <p>Something might've been here before, but there's nothing here now. Try searching for what you were looking for above.</p>
                            </div>
                        </div>
                        <div className="layout actionbar"></div>
                    </div>
                </main>
            </>
        )

    }

}
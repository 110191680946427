export default function GetPath(element: Element) {

    let path = [element];

    while (element.parentElement) {
        element = element.parentElement;
        path.push(element);
    }

    return path;

}
import { Component } from 'react';
import { debug, ImageData, MEDIA_BASE, RawApiMedia, RawApiResponse, RawApiUser } from '../helpers/Constants';

import request from '../helpers/Request';
import Session from '../helpers/UserSession';

interface MediaShowcaseOpts {
    target: string;
}

interface MediaShowcaseState {
    media_length: number;
    media: ImageData[];
    worked: boolean;
    page: number;
}

export default class MediaShowcase extends Component<MediaShowcaseOpts, MediaShowcaseState> {

    constructor(opts: MediaShowcaseOpts) {
        super(opts);
        this.state = {
            media_length: 0,
            media: [],
            worked: false,
            page: 0,
        }
    }

    fetch = (page?: number) => {
        request('/profile', { identifier: this.props.target })
            .then((response: RawApiResponse) => {
                this.setState({ media_length: (response as RawApiUser).user.media.length });
            })
            .catch(() => { });


        request('/media', { identifier: this.props.target, page: page ?? this.state.page, limit: 9 })
            .then((response: RawApiResponse) => {
                this.setState({ media: (response as RawApiMedia).media, page: page ?? this.state.page });
            })
            .catch((err) => { })
    }

    delete = (id: string) => {
        request('/delete_media', { id })
            .then(() => this.fetch())
            .catch((err) => { debug(`[-] Unable to delete image: ${err}`) });
    }

    async componentWillMount() {
        if (this.state.worked) return;
        this.setState({ worked: true });
        await Session.logged_in();
        this.fetch()
    }

    pages = () => {
        console.log(this.state.media_length);
        return Array(Math.ceil(this.state.media_length / 9)).fill(null)
            .map((_, i) => {
                return <button onClick={() => this.fetch(i)}>{i + 1}</button>
            })
    }

    render() {

        if (this.state.media.length === 0) return (
            <div className="panel display">
                <span className="title">Uploaded Media</span>
                <h1>No media uploaded</h1>
                <p>This user hasn't uploaded any media yet.</p>
            </div>
        );

        return (
            <div className="panel media_showcase">
                <span className="title">Uploaded Media</span>
                {
                    this.state.media.map(media => {
                        return (
                            <div className="media_showcase_item" key={media.id}>
                                <div className="image">
                                    {
                                        (Session.user?.username !== media.owner && !Session.is_moderator) ? <></> :
                                            (
                                                <button className="danger delete" onClick={() => this.delete(media.id)}>
                                                    <i className="far fa-trash-alt"></i>
                                                </button>
                                            )
                                    }
                                    <a href={`${MEDIA_BASE}/${media.id}`}>
                                        <img src={`${MEDIA_BASE}/${media.id}/low`} alt={media.name} />
                                    </a>
                                </div>

                                <span className="credit">
                                    Owner: <a href={`/user/${media.owner}`}>{media.owner}</a>
                                </span>

                            </div>
                        );
                    })
                }

                <div className="pages">
                    {this.pages()}
                </div>

            </div>
        )
    }

}
import { Component } from "react";
import UserMenu from "../components/UserMenu";
import Session from "../helpers/UserSession";
import Search from "../components/Search";
interface HomeState {
    working: boolean
}

export default class Home extends Component<any, HomeState> {

    constructor(opts: any) {
        super(opts);
        this.state = {
            working: false
        }
    }

    componentDidMount = async () => {
        this.setState({ working: true });
        if (await Session.logged_in()) return window.location.pathname = '/dash';
        this.setState({ working: false });
    }

    render() {

        if (this.state.working) return <></>;

        return (
            <>
                <header>
                    <div className="layout f-row">
                        <div className="layout sidebar">
                            <h2 className="branding">
                                <img src="/logo.png" alt="crick.yt logo" />
                                <a href="/">Crick.yt</a>
                            </h2>
                        </div>
                        <div className="layout main">
                            <Search />
                        </div>
                        <div className="layout actionbar">
                            <UserMenu />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="layout full-height f-row">
                        <div className="layout sidebar"></div>
                        <div className="layout main full-height left">

                            <h1>Welcome to <span className="branding">crick.yt</span></h1>
                            <p>
                                We'll be unavailable to the public whilst we set up shop. <br />
                                If you're part of our beta tester program, you can use your invite code over on the <a href="/register">register page</a>. <br />
                                See you soon!
                            </p>

                        </div>
                        <div className="layout actionbar"></div>
                    </div>
                </main>
            </>
        )

    }

}
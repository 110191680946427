import { Component } from 'react';
import Session from '../helpers/UserSession';

interface DefaultActionsState {
    user: boolean;
}

export default class DefaultActions extends Component<any, DefaultActionsState> {

    constructor(opts: any) {
        super(opts);
        this.state = {
            user: false,
        };
    }

    async componentDidMount() {
        Session.logged_in()
            .then((v) => this.setState({ user: v }));
    }

    render() {

        if (!this.state.user) return <></>;

        return (
            <div className="default-actions panel">
                <span className="title">Actions</span>
                <a href="/upload" className="button"><i className="fa fa-upload"></i> Upload Image</a>
            </div>
        );

    }

}
export const Themes = ['dark', 'light', 'future'] as const;
type Theme = (typeof Themes)[number];

class Themer {

    private _theme: Theme;

    constructor() {
        this._theme = (window.localStorage.getItem('theme') as Theme) || 'light';
    }

    get theme(): Theme {
        return this._theme;
    }

    set theme(theme: Theme) {
        this._theme = theme;
        window.localStorage.setItem('theme', theme);
        document.body.className = document.body.className.split(' ').slice(0, -1) + ' ' + theme;
    }

}

const themer = new Themer();
export default themer;
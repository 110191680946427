import { Component } from "react";
import Search from "../components/Search";
import UserMenu from "../components/UserMenu";

import "../style/debug.css";
export default class Debug extends Component {

    render() {

        return (
            <>
                <header>
                    <div className="layout f-row">
                        <div className="layout sidebar">
                            <h2 className="branding">
                                <img src="/logo.png" alt="crick.yt logo" />
                                <a href="/">Crick.yt</a>
                            </h2>
                        </div>
                        <div className="layout main">
                            <Search />
                        </div>
                        <div className="layout actionbar">
                            <UserMenu />
                        </div>
                    </div>
                </header>
                <main>
                    <div className="layout full-height f-row">
                        <div className="layout center sidebar">

                            <div className="panel navigation">

                                <span className="title">Navigation</span>

                                <ul>
                                    <li>
                                        <a href="#Debugging_And_Verbosity">Debugging & Verbosity</a>
                                        <ul>
                                            <li>
                                                <a href="#Verbosity_Level">Verbosity Level</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                            </div>

                        </div>
                        <div className="layout main left">

                            <h1><span className="branding">crick.yt</span> — debug & dev reference</h1>

                            <article>

                                <div className="refbox warning">
                                    This page is in very, <strong>very</strong> early development, and will probably end up being hosted on GitPages or something similar. For more information and updates read the <a href="https://trello.com/c/SDVsygEN/4-development-debug-guide">Trello card</a>.
                                </div>

                                <h2 id="Debugging_And_Verbosity">Debugging & Verbosity</h2>
                                <p>
                                    By default, no messages should appear in your DevTools (<kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>I</kbd>).
                                    However, if you're getting errors or unexpected behaviour, or are just wanting to prod around, you can increase the debug verbosity.
                                    <br /> <br />

                                    To do this, open up your DevTools:
                                    <br />

                                    <img src="/images/guides/debug/Step-One_Open-DevTools.png" alt="Open DevTools" />
                                    <br />

                                    Open up the 'Application' tab:
                                    <br />

                                    <img src="/images/guides/debug/Step-Two_Open_Application_Tab.png" alt="Open the 'Application' tab" />
                                    <br />

                                    Open the 'Session Storage' &gt; 'http://crick.yt' tab:
                                    <br />

                                    <img src="/images/guides/debug/Step-Three_Open_Session_Storage.png" alt="Open the 'Session Storage' > 'crick.yt' tab" />
                                    <br />

                                    Add your 'verbosity' value (see below)
                                    <br />

                                    <img src="/images/guides/debug/Step_Four_Add_Verbosity_Level.png" alt="Open 'verbosity' value" />
                                    <br />

                                    Done!
                                    <br />

                                    <img src="/images/guides/debug/Result.png" alt="Result" />
                                    <br />

                                </p>

                                <h3 id="Verbosity_Level">Verbosity Level</h3>
                                <p>
                                    The verbosity level, the 'verbosity' key in SessionStorage, is a numeriical value between zero and two, which denotes how verbose the debug logs will be.
                                </p>

                                <ul>
                                    <li>
                                        0: No debug value <br /> Acts as if there is no verbosity key specified and nothing is logged.
                                    </li>
                                    <li>
                                        1: Default Logs <br /> Logs all debug messages to the DevTools console through console.log.
                                    </li>
                                    <li>
                                        2: Trace Logs <br /> Logs all debug messages to the DevTools console through console.trace.
                                    </li>
                                </ul>

                            </article>

                        </div>
                        <div className="layout actionbar"></div>
                    </div>
                </main>
            </>
        )

    }

}